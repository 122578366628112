import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from '@material-ui/core/Radio';
import Typography from "@material-ui/core/Typography";


export default function DescriptorStep2(props) {

    const onChange = props.onChange

    const [selected, setSelected] = useState("none");

    const descriptions= {
        PointBasedDescriptor:"Let the user select a point on a drawing. Could be used only on drawing or spots.",
        OptionBasedDescriptor: "Let the user select an option from a list.",
        RangeBasedDescriptor: "Let the user select a value between a given range.",
        SpotBasedDescriptor: "Let the user select a spot on a drawing.",
        none:""
    }

    const handleChange = (event) => {
        setSelected(event.target.value);
        onChange(event.target.value)
    };

    return (
        <Grid container>
            <Grid item xs={4}>
                <RadioGroup style={{marginLeft:16}} onChange={handleChange}>
                    <FormControlLabel value="PointBasedDescriptor" control={<Radio />} label="Point based" />
                    <FormControlLabel value="OptionBasedDescriptor" control={<Radio />} label="Option based" />
                    <FormControlLabel value="RangeBasedDescriptor" control={<Radio />} label="Range based" />
                    <FormControlLabel value="SpotBasedDescriptor" control={<Radio />} label="Spot based" />
                </RadioGroup>
            </Grid>
            <Grid item xs={8}>
                <Typography variant="body2" gutterBottom>
                    {descriptions[selected]}
                </Typography>
            </Grid>
        </Grid>


    )
}