import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {AppContext} from "../../../App";
import {fetchAllServicesPage, putNewService} from "../../../utils/apiCaller";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import {useHistory} from "react-router-dom";
import NewServiceDialog from "../../../dialogs/NewServiceDialog";
import PaginatedTable from "../../../components/PaginatedTable";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const tableColumns = [
    {key: "name", name: "Name"},
    {key: "description", name: "Description"},
    {key: "serviceUrl", name: "Service URL"},
    {key: "disabled", name: "Disabled", boolean: true},
]

function ServiceListScreen() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext);

    const jwt = state.user.jwt.token

    const history = useHistory()

    const [mustRefresh, setMustRefresh] = useState(false);
    const [nameFilter, setNameFilter] = useState("");
    const [open, setOpen] = useState(false);

    const [pageArgs, setPageArgs] = useState()

    useEffect(() =>{
        if(state)
            setPageArgs({
                jwt: state.user.jwt.token,
                search: nameFilter
            })
    },[state, nameFilter])

    const handlePutNewService = (payload) => {
        putNewService(jwt, payload)
            .then(() => {
                    setMustRefresh(true)
                    setOpen(false)
                    dispatch({type: "SHOW_ALERT", payload: {message: "New service created"}})
                }
            )
            .catch((error) => dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}}))
    }

    return (

        <Grid container>
            <Grid item xs={4} className={classes.banner}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 60,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16
                     }}>
                    Analysis services
                </Box>
            </Grid>
            <Grid item xs={8} className={classes.banner}>

                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{height: 68, paddingLeft: 16, paddingRight: 16, paddingTop: 5}}
                    spacing={1}
                >
                    <Grid item>
                        <TextField
                            onChange={(e) => setNameFilter(e.target.value)}
                            size={"small"}
                            value={nameFilter}
                            variant={"outlined"}
                            placeholder={"Filter by name"}
                            fullWidth
                            InputProps={{
                                startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <IconButton onClick={() => setOpen(true)} color={"secondary"}>
                            <AddIcon/>
                        </IconButton>
                    </Grid>
                </Grid>


            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                {pageArgs && <PaginatedTable
                    fields={tableColumns}
                    onSelected={(e) => history.push("/supervisor/service/" + e.id)}
                    mustRefresh={mustRefresh}
                    requestArgs={pageArgs}
                    onRefreshed={() => setMustRefresh(false)}
                    fetchingPageFunction={fetchAllServicesPage}
                />}
                <NewServiceDialog onSave={handlePutNewService} open={open} onClose={() => setOpen(false)}/>
            </Grid>
        </Grid>

    )
}

export default ServiceListScreen;