import React, {useContext, useEffect, useState} from "react";
import {fetchProjectsPage, rebasePatients, updateProject} from "../../../utils/apiCaller";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import {useHistory} from "react-router-dom";
import {AppContext} from "../../../App";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import PaginatedTable from "../../../components/PaginatedTable";
import SyncIcon from '@material-ui/icons/Sync';
import makeStyles from "@material-ui/core/styles/makeStyles";
import EditIcon from '@material-ui/icons/Edit';
import ProjectDialog from "../../../dialogs/ProjectDialog";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const tableColumns = [
    {key: "name", name: "Name"},
    {key: "description", name: "Description"},
    {key: "creationDateTimeFormatted", name: "Creation date", sort:"creationDateTime"},
    {key: "creationUser", name: "Created by", sorting:false},
    {key: "disabled", name: "Archived", boolean: true}
]

function ProjectsScreen() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext)

    const [nameFilter, setNameFilter] = useState("");
    const [mustRefresh, setMustRefresh] = useState(false);
    const [showDisabled, setShowDisabled] = useState(false);
    const [showProjectEdit, setShowProjectEdit] = useState(false);
    const history = useHistory();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const uMenuOpen = Boolean(anchorEl && anchorEl.el);

    const handleMenu = (event, elem) => {
        setAnchorEl({el: event.currentTarget, elem: elem});
    };

    // stato che contiene la lista di acquisitions
    const [open, setOpen] = useState(false);

    const [pageArgs, setPageArgs] = useState()

    useEffect(() => {
        if (state)
            setPageArgs({
                jwt: state.user.jwt.token,
                search: nameFilter,
                disabled: showDisabled,
            })
    }, [state, nameFilter, showDisabled])


    const toggleProjectDisabled = () => {
        let payload = {
            id: anchorEl.elem.id,
            disabled: !anchorEl.elem.disabled
        }
        if (window.confirm("Sure?"))
            updateProject(state.user.jwt.token, payload)
                .then(() => {
                    setMustRefresh(true)
                    dispatch({
                        type: "SHOW_ALERT",
                        payload: {message: "Project " + (payload.disabled ? "disabled" : "enabled")}
                    })
                })
                .catch((error) => {
                    alert(error.message)
                })

        setAnchorEl({...anchorEl, el: null})
    }

    const handlePatientsSync = () => {

        rebasePatients(state.user.jwt.token, anchorEl.elem.id)
            .then(() =>
                dispatch({
                    type: "SHOW_ALERT",
                    payload: {message: "Project patients synchronized successfully"}
                })
            )
            .catch((error) => {
                alert(error.message)
            })

        setAnchorEl({...anchorEl, el: null})
    }

    const handleProjectSelected = (project) => {
        if (window.confirm("You will be redirected to the project console, continue?")) {
            dispatch({
                type: "PROJECT_CHOSEN",
                payload: project.id
            })
            // redireziono l'utente alla parina principale, fuori dalla supervisor console
            history.push(`/${project.id}`)
        }
    }

    const handleProjectChange = () => {
        setMustRefresh(true)
        setOpen(false)
        setShowProjectEdit(false)
    };

    return (
        <Grid container>
            <Grid item xs={4} className={classes.banner}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 60,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16
                     }}>
                    Projects
                </Box>
            </Grid>
            <Grid item xs={8} className={classes.banner}>

                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{height: 68, paddingLeft: 16, paddingRight: 16, paddingTop: 5}}
                    spacing={1}
                >
                    <Grid item>
                        <FormControlLabel
                            value="end"
                            control={<Switch value={showDisabled}
                                             onChange={(event, checked) => setShowDisabled(checked)} color="primary"/>}
                            label="Show archived"
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            onChange={(e) => setNameFilter(e.target.value)}
                            size={"small"}
                            value={nameFilter}
                            variant={"outlined"}
                            placeholder={"Filter by name"}
                            fullWidth
                            InputProps={{
                                startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <IconButton onClick={() => setOpen(true)} color={"secondary"}>
                            <AddIcon/>
                        </IconButton>
                    </Grid>

                </Grid>


            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                {pageArgs && <PaginatedTable
                    fields={tableColumns}
                    //defaultSort={"creationDateTimeFormatted"}
                    mustRefresh={mustRefresh}
                    onMenuSelected={handleMenu}
                    onRefreshed={() => setMustRefresh(false)}
                    fetchingPageFunction={fetchProjectsPage}
                    requestArgs={pageArgs}
                    onSelected={handleProjectSelected}

                />}

                <Popover
                    open={uMenuOpen}
                    anchorEl={anchorEl ? anchorEl.el : null}
                    onClose={() => setAnchorEl({...anchorEl, el: null})}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List style={{marginTop: "auto", cursor: "pointer"}}>
                        <ListItem button onClick={toggleProjectDisabled}>
                            <ListItemIcon>
                                {anchorEl && anchorEl.elem.disabled ? <UnarchiveIcon/> : <ArchiveIcon/>}
                            </ListItemIcon>
                            <ListItemText primary={anchorEl && anchorEl.elem.disabled ? "Un-archive" : "Archive"}/>
                        </ListItem>
                        <ListItem button onClick={handlePatientsSync}>
                            <ListItemIcon>
                                <SyncIcon/>
                            </ListItemIcon>
                            <ListItemText primary={"Sync patients"}/>
                        </ListItem>
                        <ListItem button onClick={() => {
                            setAnchorEl({...anchorEl, el: null})
                            setShowProjectEdit(true)
                        }}>
                            <ListItemIcon>
                                <EditIcon/>
                            </ListItemIcon>
                            <ListItemText primary={"Edit information"}/>
                        </ListItem>
                    </List>
                </Popover>


                <ProjectDialog
                    open={open || showProjectEdit}
                    onClose={() => {
                        setOpen(false)
                        setShowProjectEdit(false)
                    }}
                    project={anchorEl ? anchorEl.elem : null}
                    onPerformed={handleProjectChange}
                />
            </Grid>
        </Grid>

    )
}

export default ProjectsScreen;