import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {useHistory, useParams} from "react-router-dom";
import {fetchAllProjectPatients, fetchProjectAdditionalInfoList} from "../../../utils/apiCaller";
import Box from "@material-ui/core/Box";
import PaginatedTable from "../../../components/PaginatedTable";
import {useQueryParams} from "../../../hooks/useQueryParams";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const renderFunction = (projectId, additionalId) => (row) => {
    if (row.additionalInfoPerProject[projectId])
        return row.additionalInfoPerProject[projectId][additionalId]
    return ""
}

const tableColumns = [
    {key: "id", name: "Id", sorting: false},
    {key: "gender", name: "Gender"},
    {key: "birth", name: "Birth"},
    {key: "height", name: "Height"},
    {key: "weight", name: "Weight"}
]

function ListScreen() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext);
    const {projectId} = useParams()
    const history = useHistory()

    const [pageArgs, setPageArgs] = useState()
    const [additionals, setAdditionals] = useState([])

    useEffect(() => {
        if (projectId && state) {
            fetchProjectAdditionalInfoList(state.user.jwt.token, projectId)
                .then(result => setAdditionals(result))
                .catch((error) => {
                    dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
                })
            setPageArgs({
                projectId: projectId,
                jwt: state.user.jwt.token
            })
        }
    }, [projectId, state, dispatch])

    const tableColumnsAdditional = [...tableColumns, ...additionals.filter(a => !a.disabled).map(a => {
        return {
            key: a.id,
            name: a.name,
            renderingFunc: renderFunction(projectId, a.id),
            sort: `additionalInfoPerProject.${projectId}.${a.id}`
        }
    })]

    const queryParams =  useQueryParams()

    let destinationParams = `?page=${queryParams.qp.page || ""}&size=${queryParams.qp.size || ""}&sort=${queryParams.qp.sort || ""}&dir=${queryParams.qp.dir || ""}&hide=${queryParams.qp.hide || ""}`


    return (
        <Grid container>
            <Grid item xs={12} className={classes.banner}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 60,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16
                     }}>
                    Patients
                </Box>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                {pageArgs && <PaginatedTable
                    hideEnabled
                    jwt={state.user.jwt.token}
                    fields={tableColumnsAdditional}
                    requestArgs={pageArgs}
                    fetchingPageFunction={fetchAllProjectPatients}
                    onSelected={(e) => history.push("/" + projectId + "/patient/" + e.id +"?" + destinationParams)}
                />}

            </Grid>
        </Grid>

    )
}

export default ListScreen;