import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";

import {AppContext} from "../../App";
import Button from "@material-ui/core/Button";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import {fetchProjectAdditionalInfoList, putNewPatient} from "../../utils/apiCaller";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


const fieldsValidation = {
    gender: (value) => {
        if (!value)
            return {error: true, text: "Please, select a gender"}
        return {error: false, text: ""}
    }
}

export default function PatientCreation({onSaved, sexConstraint, female, projectId}) {
    const [state, dispatch] = useContext(AppContext);

    const [birth, setBirth] = useState({error:false,text:""});
    const [gender, setGender] = useState({
        error: false,
        text: "",
        value: !sexConstraint ? "" : female ? "Female" : "Male"
    });
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [additionals, setAdditionals] = useState([]);
    const [additionalValues, setAdditionalValues] = useState({});


    useEffect(() => {
        fetchProjectAdditionalInfoList(state.user.jwt.token, projectId)
            .then(result => {
                setAdditionals(result)
                const values = {}
                result.forEach(a => {
                    if (a.defaultValue) {
                        if (a.type === "Boolean")
                            values[a.id] = a.defaultValue.toLowerCase() === "true";
                        else
                            values[a.id] = a.defaultValue
                    } else if (a.type === "Boolean")
                        values[a.id] = false
                })
                setAdditionalValues(values)
            })
            .catch((error) => {
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            })

    }, [projectId, state.user.jwt, dispatch])

    const [loading, setLoading] = useState(false);

    const handleSave = () => {
        const genderCheck = fieldsValidation.gender(gender.value)

        const hasError = genderCheck.error

        const additionalInfoPerProject = {}
        additionalInfoPerProject[projectId] = additionalValues

        if (!hasError) {
            setLoading(true)
            const payload = {
                birthTimestamp: birth.value ? birth.value.getTime() / 1000 : null,
                gender: gender.value,
                height: height,
                weight: weight,
                additionalInfoPerProject: additionalInfoPerProject
            }

            setGender({error: false, text: "", value: gender.value})

            putNewPatient(state.user.jwt.token, payload)
                .then((p) => onSaved(p))
                .catch(e => alert(e.message))
                .then(() => setLoading(false))
        } else {
            setGender({error: genderCheck.error, text: genderCheck.text, value: gender.value})
        }

    }

    const handleAddiValueChange = (addi, text) => {
        if (addi.type === "Number")
            text = parseFloat(text)

        additionalValues[addi.id] = text
        setAdditionalValues({...additionalValues})
    }

    const handleDateChange = (date) => {
        setBirth({error: birth.error, text: birth.text, value: date})
    }

    return (
        <Grid container spacing={3} style={{marginBottom: 16}}>
            <Grid item xs={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        disabled={loading}
                        disableFuture
                        inputVariant="outlined"
                        label={"Birth"}
                        ampm={false}
                        value={birth.value}
                        onChange={(e) => handleDateChange(e)}
                        fullWidth format="dd/MM/yyyy"
                    />
                </MuiPickersUtilsProvider>

            </Grid>
            <Grid item xs={6}>
                <TextField
                    disabled={loading || sexConstraint}
                    select
                    variant={"outlined"}
                    error={gender.error}
                    helperText={gender.text}
                    label="Gender"
                    fullWidth
                    value={gender.value}
                    onChange={(e) => setGender({error: gender.error, text: gender.text, value: e.target.value})}
                >
                    <MenuItem value={"Male"}>Man</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                    <MenuItem value={"ND"}>Other</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    disabled={loading}
                    variant={"outlined"}
                    value={height}
                    fullWidth
                    label="Height"
                    type="number"
                    InputProps={{
                        endAdornment: "cm"
                    }}
                    onChange={(e) => setHeight(e.target.value)}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    disabled={loading}
                    variant={"outlined"}
                    fullWidth
                    value={weight}
                    InputProps={{
                        endAdornment: "Kg"
                    }}
                    label="Weight"
                    type="number"
                    onChange={(e) => setWeight(e.target.value)}
                />
            </Grid>

            {additionals.map(addi =>
                <>{!addi.disabled &&
                <Grid item xs={6} key={addi.id}>
                    {addi.type === "Boolean"?
                        <FormControlLabel
                            label={addi.name}
                            labelPlacement="end"
                            control={
                                <Checkbox
                                    checked={additionalValues[addi.id] || false}
                                    onChange={(e) => handleAddiValueChange(addi, e.target.checked)}
                                    color="primary"
                                />
                            }
                        /> :
                        <TextField
                            disabled={loading}
                            variant={"outlined"}
                            fullWidth
                            value={additionalValues[addi.id] || ""}
                            label={addi.name}
                            type={addi.type === "Number" ? "number" : "text"}
                            onChange={(e) => handleAddiValueChange(addi, e.target.value)}
                        />
                    }
                </Grid>
                }</>
            )}


            <Grid item xs={12} style={{textAlign: "right"}}>
                <Button
                    disabled={loading}
                    color={"secondary"}
                    variant={"outlined"}
                    onClick={handleSave}>
                    {loading && <CircularProgress size={25}/>}
                    Save and select
                </Button>
            </Grid>
        </Grid>
    )
}
