import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {useHistory, useParams} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import {AppContext} from "../../../App";
import {
    fetchBlob,
    fetchPatientDetail,
    fetchPatientProjectAcquisitions,
    fetchProjectAdditionalInfoList,
    fetchProjectDescriptors,
    generateManualPatientSheet,
    updatePatient
} from "../../../utils/apiCaller";
import {EasyBreadcrumbs} from "../../../components/Breadcumbs";
import BasicInfos from "../../../components/PatientBasicInfo";
import PaginatedTable from "../../../components/PaginatedTable";
import TextEntry from "../../../components/entries/TextEntry";
import SwitchEntry from "../../../components/entries/SwitchEntry";
import {useQueryParams} from "../../../hooks/useQueryParams";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const renderFunction = (descriptorId) => (row) => {
    const value = row.values[descriptorId]

    if (value) {
        const displayValue = value.value
        if (typeof displayValue === "boolean")
            return displayValue ? "Yes" : "No"
        else return displayValue
    } else
        return ""
}

const tableColumns = [
    {key: "date", name: "Date"},
    {key: "operator", name: "Operator"},
]

function PatientDetail() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext)
    const jwt = state.user.jwt
    const [patient, setPatient] = useState({painAcquisitions: []});
    const {patientId, projectId} = useParams();
    const [pdfGenerating, setPdfGenerating] = useState(false);

    const history = useHistory();

    const [pageArgs, setPageArgs] = useState()
    const [additionals, setAdditionals] = useState([])
    const [editEnabled, setEditEnabled] = useState(false)
    const [descriptors, setDescriptors] = useState([]);


    useEffect(() => {
        if (projectId && state)
            setPageArgs({
                projectId: projectId,
                jwt: state.user.jwt.token,
                patientId: patientId
            })
    }, [projectId, state, patientId])

    useEffect(() => {
        fetchPatientDetail(jwt.token, patientId)
            .then(result => setPatient(result))
            .catch(error => alert(error))

        fetchProjectDescriptors(state.user.jwt.token, projectId)
            .then(result => {
                const acqDesc = result.acquisitionDescriptors
                acqDesc.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                setDescriptors(acqDesc)
            })
            .catch(error => alert(error.message))

        if (projectId)
            fetchProjectAdditionalInfoList(state.user.jwt.token, projectId)
                .then(result => setAdditionals(result))
                .catch((error) => {
                    dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
                })


    }, [patientId, jwt.token, dispatch, projectId, state.user.jwt.token])

    const handleManualGeneration = () => {
        if (pdfGenerating)
            return;

        setPdfGenerating(true)
        generateManualPatientSheet(jwt.token, patientId, projectId)
            .then(result => {
                fetchBlob(jwt.token, "/" + result.pdfUrl)
                    .then(blob => {
                        // const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = blob;
                        // the filename you want
                        a.download = 'patient-' + patientId + '-sheet.pdf';
                        document.body.appendChild(a);
                        a.click();
                        // window.URL.revokeObjectURL(url);
                        setPdfGenerating(false)
                    })
                    .catch(error => alert(error))
            })
            .catch(error => alert(error))

    }

    const getAdditionalValue = (patient, projectId, id) => {
        if (patient.additionalInfoPerProject[projectId]) {
            return patient.additionalInfoPerProject[projectId][id]
        } else
            return ""
    }

    const handleOnValueChange = (attr, value) => {
        patient[attr] = value
        setPatient({...patient})
    }

    const handleOnAdditionalValueChange = (additional, value) => {
        if (additional.type === "Number")
            value = parseFloat(value)


        if (!patient.additionalInfoPerProject[projectId])
            patient.additionalInfoPerProject[projectId] = {}

        patient.additionalInfoPerProject[projectId][additional.id] = value
        setPatient({...patient})
    }

    const handleSave = () => {
        updatePatient(state.user.jwt.token, patient, projectId)
            .then(result => {
                setEditEnabled(false)
                setPatient(result)
                dispatch({type: "SHOW_ALERT", payload: {message: "Patient info updated"}})
            })
            .catch(() => {
                dispatch({type: "SHOW_ALERT", payload: {message: "error updating the patient", severity: "error"}})
            })
    }

    const queryParams = useQueryParams()

    let destinationParams = `?page=${queryParams.qp.p || ""}&size=${queryParams.qp.s || ""}&sort=${queryParams.qp.so || ""}&dir=${queryParams.qp.d || ""}&hide=${queryParams.qp.hide || ""}`

    const tableColumnsAdditional = [...tableColumns, ...descriptors.map(d => {
        return {
            key: d.id,
            name: d.name,
            renderingFunc: renderFunction(d.id),
            sort: `painValues.${d.id}.value`
        }
    })]

    return (
        <Grid container>
            <Grid item xs={6} className={classes.banner} style={{height: 60}}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                         style={{
                             height: 55,
                             paddingTop: 16,
                             verticalAlign: "middle",
                             display: "table-cell",
                             paddingLeft: 16
                         }}>
                        <EasyBreadcrumbs breadcrumbs={[{
                            destination: "/" + projectId + "/patient" + destinationParams,
                            label: "Patients"
                        }]}
                                         current={"Patient"}/>
                    </Box>
                </Typography>

            </Grid>

            <Grid item xs={6} className={classes.banner} style={{textAlign: "right"}}>
                {editEnabled && <Button variant="outlined" color="secondary" style={{marginRight: 16, marginTop: 16}}
                                        startIcon={<SaveIcon/>}
                                        onClick={handleSave}>
                    Save
                </Button>}
                {!editEnabled && <Button variant="outlined" color="primary" style={{marginRight: 16, marginTop: 16}}
                                         startIcon={<EditIcon/>}
                                         onClick={() => setEditEnabled(true)}>
                    Edit
                </Button>}
                <Button variant="outlined" color="primary" style={{marginRight: 16, marginTop: 16}}
                        startIcon={pdfGenerating ? <CircularProgress size={24}/> : <ImportContactsIcon/>}
                        disabled={pdfGenerating}
                        onClick={handleManualGeneration}>
                    Manual sheet
                </Button>
            </Grid>

            <Grid item xs={12} style={{marginBottom: 16}}><Divider/></Grid>

            <Grid item xs={4} style={{padding: 16}}>
                <BasicInfos
                    patient={patient}
                    onChange={handleOnValueChange}
                    edit={editEnabled}/>
                <Box mt={2}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography component="div">
                                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                                    Additional
                                </Box>
                            </Typography>
                        </Grid>
                        {additionals.filter(a => !a.disabled).map(additional =>
                            <React.Fragment key={additional.id}>
                                {additional.type === "Boolean" ?
                                    <SwitchEntry
                                        editable={editEnabled}
                                        label={additional.name}
                                        onEdit={(value) => handleOnAdditionalValueChange(additional, value)}
                                        value={getAdditionalValue(patient, projectId, additional.id) === "" ? undefined : getAdditionalValue(patient, projectId, additional.id).toString() === "true"}
                                        divider={false}
                                    /> :
                                    <TextEntry
                                        editable={editEnabled}
                                        label={additional.name}
                                        onEdit={(value) => handleOnAdditionalValueChange(additional, value)}
                                        inputMode={additional.type === "Number" ? "number" : "text"}
                                        value={getAdditionalValue(patient, projectId, additional.id)}
                                        divider={false}
                                    />}
                            </React.Fragment>
                        )}
                    </Grid>
                </Box>
            </Grid>
            <Grid item xs={8} style={{padding: 16}}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                        Acquisitions
                    </Box>
                </Typography>
                {pageArgs && <PaginatedTable
                    fields={tableColumnsAdditional}
                    requestArgs={pageArgs}
                    paramsPrefix={"p_"}
                    fetchingPageFunction={fetchPatientProjectAcquisitions}
                    onSelected={(e) => history.push("/" + projectId + "/acquisition/" + e.id)}
                />}
            </Grid>


        </Grid>


    )
}


export default PatientDetail;