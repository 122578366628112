import React from 'react';
import GenericPreview from "../../components/descriptor/preview/GenericPreview";


export default function DescriptorStep4({descriptor, kind}) {

    if(descriptor === undefined)
        return <></>

    descriptor.kind = kind

    switch (kind) {
        case 'OptionBasedDescriptor':
            return <GenericPreview variant={"outlined"} descriptor={descriptor} defaultValue={""}/>;
        case 'RangeBasedDescriptor':
        case 'PointBasedDescriptor':
        case 'SpotBasedDescriptor':
            return <GenericPreview variant={"outlined"} descriptor={descriptor} defaultValue={0}/>;
        default:
            return <></>
    }

}
