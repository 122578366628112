import React from "react";
import RangeBasedPreview from "./RangeBasedPreview";
import OptionBasedPreview from "./OptionBasedPreview";
import Box from "@material-ui/core/Box";


function GenericPreview({descriptor, defaultValue, variant}){

    switch (descriptor.kind) {
        case "RangeBasedDescriptor":
            return <RangeBasedPreview variant={variant}
                                      defaultValue={defaultValue} descriptor={descriptor}/>
        case "OptionBasedDescriptor":
            return <OptionBasedPreview variant={variant}
                                       defaultValue={defaultValue} descriptor={descriptor}/>
        default:
            return <Box>No preview available</Box>
    }
}

export default GenericPreview