import React from "react";
import RangeBasedEdit from "./RangeBasedEdit";
import OptionBasedEdit from "./OptionBasedEdit";
import SpotBasedEdit from "./SpotBasedEdit";


function GenericEdit({descriptor, onSave}) {

    switch (descriptor.kind) {
        case "RangeBasedDescriptor":
            return <RangeBasedEdit onSave={onSave} descriptor={descriptor}/>
        case "OptionBasedDescriptor":
            return <OptionBasedEdit onSave={onSave} descriptor={descriptor}/>
        case "SpotBasedDescriptor":
        case "PointBasedDescriptor":
            return <SpotBasedEdit onSave={onSave} descriptor={descriptor}/>
        default:
            return null;
    }
}

export default GenericEdit