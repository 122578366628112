import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {useParams} from "react-router-dom";
import {AppContext} from "../../../App";
import {fetchServiceDetail} from "../../../utils/apiCaller";
import {EasyBreadcrumbs} from "../../../components/Breadcumbs";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

function ServiceDetailScreen() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext);

    const jwt = state.user.jwt

    // stato che contiene la lista di descriptors globali
    const [
        // service
        , setService] = useState({});
    // const [snackOpen, setSnackOpen] = useState(false);

    const {serviceId} = useParams();

    useEffect(() => {
        fetchServiceDetail(jwt.token, serviceId)
            .then(result => setService(result))
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

    }, [serviceId, jwt, dispatch])

    // const handleServiceUpdate = (payload) => {
    //     postServiceUpdate(jwt.token, payload)
    //         .then(result => {
    //             setService(result)
    //             setSnackOpen(true)
    //         })
    //         .catch(result => alert(result))
    // }


    return (

        <Grid container>
            <Grid item xs={12} style={{height: 60}} className={classes.banner}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                         style={{
                             height: 55,
                             paddingTop: 16,
                             verticalAlign: "middle",
                             display: "table-cell",
                             paddingLeft: 16
                         }}>
                        <EasyBreadcrumbs breadcrumbs={[{destination: "/supervisor/service", label: "Services"}]}
                                         current={"Service"}/>
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 16}}><Divider/></Grid>

        </Grid>


    )
}

export default ServiceDetailScreen;