import React from "react";
import {Card} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";


function OptionBasedPreview({descriptor, defaultValue, variant}){

    return(
        <Card variant={variant}
            //style={{backgroundColor:backgroundColor}}
        >
            <CardHeader
                title={descriptor.name || descriptor.key}
            />
            <CardContent>
                <Autocomplete
                    options={descriptor.options}
                    getOptionLabel={option => option}
                    defaultValue={defaultValue}
                    onChange={() => {}}
                    renderInput={params => <TextField fullWidth{...params} label="Value"/>}
                />

            </CardContent>
        </Card>
    )
}

export default OptionBasedPreview