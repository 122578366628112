import React, {useContext, useEffect, useState} from "react";
import {fetchChartDetail, fetchPrimaryLocale, updateBcInfo} from "../../../../utils/apiCaller";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import {useParams} from "react-router-dom";
import {AppContext} from "../../../../App";
import Typography from "@material-ui/core/Typography";
import {EasyBreadcrumbs} from "../../../../components/Breadcumbs";
import AuthImg from "../../../../components/AuthImg";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import BodyChartSelectionDialog from "../../../../dialogs/bodychart/selection/BodyChartSelectionDialog";
import BodyChartChangeDialog from "../../../../dialogs/bodychart/change/BodyChartChangeDialog";
import {Autocomplete} from "@material-ui/lab";
import './Style.css'
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));
const sexObj = {sex: "F"}

export default function BodychartDetailScreen() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext)
    const {bcId} = useParams()

    const [chart, setChart] = useState(null);
    const [translations, setTranslations] = useState();
    const [selectionOpen, setSelectionOpen] = useState({kind: "DIG", open: false});
    const [femaleSelectionOpen, setFemaleSelectionOpen] = useState(false);

    const [isFemale, setIsFemale] = useState(false);
    const [name, setName] = useState("");
    const [leftLabel, setLeftLabel] = useState({text:"", code:""});
    const [rightLabel, setRightLabel] = useState({text:"", code:""});
    const [topLabel, setTopLabel] = useState({text:"", code:""});


    useEffect(() => {
        fetchChartDetail(state.user.jwt.token, bcId)
            .then(setChart)
            .catch((error) => dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}}))

        fetchPrimaryLocale(state.user.jwt.token)
            .then(res => setTranslations(res.translations))
            .catch((error) => dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}}))

    }, [state.user.jwt, bcId, dispatch])

    useEffect(() => {
        if (chart && translations) {
            setName(chart.name)
            setIsFemale(chart.isFemale)

            if (chart.leftLabelCode)
                setLeftLabel(translations.find(x => x.code === chart.leftLabelCode))
            else
                setLeftLabel(null)

            if (chart.topLabelCode)
                setTopLabel(translations.find(x => x.code === chart.topLabelCode))
            else
                setTopLabel(null)

            if (chart.rightLabelCode)
                setRightLabel(translations.find(x => x.code === chart.rightLabelCode))
            else
                setRightLabel(null)
        }
    }, [chart, translations])

    const handleInfoUpdate = () => {

        let payload = {
            id: bcId
        }

        payload.name = name
        payload.isFemale = isFemale;
        payload.leftLabelCode = leftLabel ? leftLabel.code : ""
        payload.rightLabelCode = rightLabel ? rightLabel.code : ""
        payload.topLabelCode = topLabel ? topLabel.code : ""

        updateBcInfo(state.user.jwt.token, payload)
            .then((result) => {
                setChart(result)
                dispatch({type: "SHOW_ALERT", payload: {message: "Info updated"}})
            })
            .catch((error) => dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}}))

    }


    const handleFemaleVersionSelected = (femaleBc) => {
        let payload = {}
        payload.id = bcId
        payload.femaleId = femaleBc.id

        updateBcInfo(state.user.jwt.token, payload)
            .then((result) => {
                setChart(result)
                dispatch({type: "SHOW_ALERT", payload: {message: "Female version updated"}})
                setFemaleSelectionOpen(false)
            })
            .catch((error) => dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}}))

    }

    const handleBcUpload = ({image, mapDescriptions}) => {
        let payload = {}
        payload.id = bcId

        if (selectionOpen.kind === "DIG")
            payload.digitalFile = image
        else if (selectionOpen.kind === "PAP")
            payload.paperFile = image
        else if (selectionOpen.kind === "MAS")
            payload.maskFile = image
        else if (selectionOpen.kind === "MAP") {
            payload.mapFile = image
            payload.mapDescriptionFile = mapDescriptions
        }

        updateBcInfo(state.user.jwt.token, payload)
            .then((result) => {
                setChart(result)
                dispatch({type: "SHOW_ALERT", payload: {message: "Image updated (reload page)"}})
                setSelectionOpen({kind: selectionOpen.kind, open: false})
            })
            .catch((error) => dispatch({type: "SHOW_ALERT", payload: {message: error.message || "Error while saving, check the file.", severity: "error"}}))
    }

    const pictures = chart ? [
        {src: chart.digitalThumbnailUrl, title: "Digital", isMap: false, kind: "DIG"},
        {src: chart.paperThumbnailUrl, title: "Paper", isMap: false, kind: "PAP"},
        {src: chart.maskUrl, title: "Mask", isMap: false, kind: "MAS"},
        {src: chart.mapUrl, title: "Map", isMap: true, kind: "MAP"}
    ] : []

    return (

        <Grid container>
            <Grid item xs={12} style={{height: 60}} className={classes.banner}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                         style={{
                             height: 55,
                             paddingTop: 16,
                             verticalAlign: "middle",
                             display: "table-cell",
                             paddingLeft: 16
                         }}>
                        <EasyBreadcrumbs breadcrumbs={[
                            {destination: "/supervisor/chart", label: "Body charts"}
                        ]}
                                         current={"Bodychart"}/>
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12} style={{margin: 16}}>

                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography component="div">
                            <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                                Info
                            </Box>
                        </Typography>
                    </Grid>
                    {chart && <Grid item xs={12}>
                        <Grid container spacing={2} style={{padding: 16}}>
                            <Grid item xs={4}>
                                <TextField
                                    id="name"
                                    label="Name"
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                    // error={touched.name && !!errors.name}
                                    // helperText={touched.name && errors.name}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={<Switch
                                        onChange={(event, checked) => setIsFemale(checked)}
                                        checked={isFemale}
                                        color="primary"/>}
                                    label="Female"
                                    labelPlacement="start"
                                />
                            </Grid>
                            <Grid item xs={4}/>
                            <Grid item xs={3}>
                                <Autocomplete

                                    options={translations || []}
                                    getOptionLabel={(option) => option.code + " (" + option.text + ")"}
                                    onChange={((event, value) => setLeftLabel(value))}
                                    value={leftLabel}
                                    renderInput={params => <TextField
                                        label={"Left label"} variant={"outlined"} fullWidth{...params}/>}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    options={translations || []}
                                    getOptionLabel={(option) => option.code + " (" + option.text + ")"}
                                    onChange={((event, value) => setTopLabel(value))}
                                    value={topLabel}
                                    renderInput={params => <TextField
                                        label={"Top label"} variant={"outlined"} fullWidth{...params}/>}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    options={translations || []}
                                    getOptionLabel={(option) => option.code + " (" + option.text + ")"}
                                    onChange={((event, value) => setRightLabel(value))}
                                    value={rightLabel}
                                    renderInput={params => <TextField
                                        label={"Right label"}
                                        variant={"outlined"} fullWidth{...params}/>}
                                />
                            </Grid>


                            <Grid item xs={12} style={{textAlign: "right"}}>
                                <Button
                                    color={"secondary"}
                                    variant={"outlined"}
                                    onClick={handleInfoUpdate}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>}

                    <Grid item xs={12}>
                        <Typography component="div">
                            <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                                Pictures
                            </Box>
                        </Typography>
                    </Grid>

                    {pictures.map(t =>
                        <Grid item key={t.src} xs={3}>
                            <Typography component="div">
                                <Box fontWeight="fontWeightLight" fontSize={"h6.fontSize"}>
                                    {t.title}
                                </Box>
                            </Typography>
                        </Grid>)
                    }
                    {pictures.map(p =>
                        <Grid item xs={3} key={p.src}>
                            {p.src !== "x" && <AuthImg
                                cache={false}
                                className={'transparency-bg'}
                                render={selectionOpen.open}
                                style={{width: "100%", border: "1px solid #DDD"}}
                                src={p.src}/>}
                        </Grid>)
                    }
                    {pictures.map(p =>
                        <Grid item xs={3} key={p.src}>
                            <Button color={"primary"} fullWidth variant={"outlined"}
                                    onClick={() => setSelectionOpen({open: true, kind: p.kind})}>
                                Change
                            </Button>
                        </Grid>)
                    }


                    <BodyChartChangeDialog onSave={handleBcUpload}
                                           onClose={() => setSelectionOpen({kind: selectionOpen.kind, open: false})}
                                           open={selectionOpen.open}
                                           isMap={selectionOpen.kind === "MAP"}/>

                    {chart && !chart.isFemale &&
                    <Grid item xs={12}>
                        <Typography component="div">
                            <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                                Female relation
                            </Box>
                        </Typography>
                    </Grid>
                    }

                    {chart && !chart.isFemale &&
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <AuthImg
                                    style={{width: "100%", border: "1px solid #DDD"}}
                                    src={chart.femaleDigitalUrl}/>
                            </Grid>
                            <Grid item xs={12}/>
                            <Grid item xs={2}>
                                <Button
                                    onClick={() => setFemaleSelectionOpen(true)}
                                    color={"primary"} fullWidth
                                    variant={"outlined"}>{chart.femaleId ? "Change" : "Select"}</Button>
                            </Grid>
                        </Grid>

                        <BodyChartSelectionDialog
                            selectedId={chart.femaleId}
                            open={femaleSelectionOpen}
                            onSelected={handleFemaleVersionSelected}
                            onClose={() => setFemaleSelectionOpen(false)}
                            sex={sexObj}
                        />
                    </Grid>
                    }

                </Grid>


            </Grid>
        </Grid>
    )
}
