import React, {useContext, useEffect, useState} from "react";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {CardActions, CardHeader} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import {fetchBlob} from "../../../../../utils/apiCaller";
import {AppContext} from "../../../../../App";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function ImageFile({output}) {

    const [state, dispatch] = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    const [blob, setBlob] = useState();

    const handleDownload = () => {
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blob;
        // the filename you want
        a.download = output.title.toLowerCase() + '.' + output.attachmentExtension;
        document.body.appendChild(a);
        a.click();

    }

    useEffect(() => {
        setLoading(true)
        fetchBlob(state.user.jwt.token, "/data/attachment/" + output.attachmentFile)
            .then(blob => {
                setBlob(blob)
            })
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: "No file available", severity: "error"}})
            )
            .finally(() => setLoading(false))
    }, [state.user.jwt, dispatch, output])

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card style={{height: "100%"}}>
                    <CardHeader title={output.title}/>
                    <CardContent>
                        <Grid container>
                            <img alt={output.title} src={blob} style={{width:"100%"}}/>
                        < /Grid>
                    </CardContent>
                    <CardActions>
                        <Button onClick={handleDownload} color={"secondary"} variant={"text"}>
                            {loading && <CircularProgress size={25} style={{marginRight:12, color:"white"}}/>} Download
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    )
}
