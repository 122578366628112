import React, {useContext, useEffect, useState} from "react";

import {AppContext} from "../../../../App";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {useHistory, useParams} from "react-router-dom";
import {fetchServiceRequestFull} from "../../../../utils/apiCaller";
import GenericFile from "./outputs/GenericFile";
import ImageFile from "./outputs/ImageFile";
import Matrix from "./outputs/Matrix";
import Values from "./outputs/Values";
import Typography from "@material-ui/core/Typography";
import {EasyBreadcrumbs} from "../../../../components/Breadcumbs";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Alert} from "@material-ui/lab";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Paper from "@material-ui/core/Paper";
import {describeOperator} from "../ServiceScreen";
import Button from "@material-ui/core/Button";
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const imagesExt = ["png", "jpg", "jpeg", "bmp"]

export default function ServiceResultScreen() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext);

    const history = useHistory()

    const [request, setRequest] = useState();

    const [genericFiles, setGenericFiles] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const [matrices, setMatrices] = useState([]);
    const [values, setValues] = useState([]);

    const {requestId, projectId} = useParams()

    const requestTimedOut = request && request.elapsedSeconds > 120

    const waitingResult = request && !requestTimedOut && !request.fulfilled

    const handleCopyConfiguration = () => {
        history.push("/" + projectId + "/service?model=" + requestId)
    }

    console.log(request)

    useEffect(() => {
        const fetch = () => {
            fetchServiceRequestFull(state.user.jwt.token, requestId)
                .then(result => {
                    setRequest(result)
                })
                .catch(error =>
                    dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
                )
        }

        fetch()

        const interval = setInterval(() => {
            if (waitingResult)
                fetch()
        }, 5000);

        return () => clearInterval(interval)

    }, [requestId, state.user.jwt, dispatch, waitingResult])


    useEffect(() => {
        if (!request)
            return

        // ora faccio la suddivisione nella 4 categorie
        let genericFilesTemp = []
        let imageFilesTemp = []
        let matricesTemp = []
        let valuesTemp = []

        if (request.outputs) {
            request.outputs.forEach(output => {
                if (output.attachmentId && imagesExt.includes(output.attachmentExtension))
                    imageFilesTemp.push(output)
                else if (output.attachmentId)
                    genericFilesTemp.push(output)
                else if (output.matrix)
                    matricesTemp.push(output)
                else if (output.values)
                    valuesTemp.push(output)
            });
            setGenericFiles(genericFilesTemp)
            setImageFiles(imageFilesTemp)
            setMatrices(matricesTemp)
            setValues(valuesTemp)
        }


    }, [request])


    // useWhatChanged([genericFiles,
    //     imageFiles,
    //     matrices,
    //     values, requestId, projectId, request])

    return (
        <Grid container>
            <Grid item xs={6} className={classes.banner} style={{height: 60}}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                         style={{
                             height: 55,
                             paddingTop: 16,
                             verticalAlign: "middle",
                             display: "table-cell",
                             paddingLeft: 16
                         }}>
                        <EasyBreadcrumbs
                            breadcrumbs={[{destination: "/" + projectId + "/service/jobs", label: "Request jobs"}]}
                            current={"Request"}/>
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={6} className={classes.banner} style={{textAlign: "right"}}>

                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{height: 68, paddingLeft: 16, paddingRight: 16, paddingTop: 5}}
                    spacing={1}
                >
                    <Grid item>
                        <Button variant="outlined" color="primary"
                                startIcon={<FileCopyIcon/>} onClick={handleCopyConfiguration}>
                            Use as model
                        </Button>

                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={12}><Divider/></Grid>

            {waitingResult && <Grid item xs={12}>
                <Box m={3}>
                    <Grid container spacing={3} direction={"column"} justify={"center"} alignItems={"center"}>
                        <Grid item><CircularProgress/></Grid>
                        <Grid item>Waiting analysis results</Grid>
                    </Grid>
                </Box>
            </Grid>}

            {requestTimedOut && !request.fulfilled && !request.error && <Grid item xs={12}>
                <Box m={3}>
                    <Alert severity="warning">Request timed out, got no answer!</Alert>
                </Box>
            </Grid>}

            {request && !request.fulfilled && request.error && <Grid item xs={12}>
                <Box m={3}>
                    <Alert severity="error">{request.errorMessage}</Alert>
                </Box>
            </Grid>}

            <Grid item xs={12}>
                <Grid container alignItems={"stretch"} style={{padding: 16}} spacing={2}>

                    <Grid item xs={8}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Filters
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Parameters
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider/>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container spacing={2}>
                            {request && request.filters.length > 0 && request.filters.map(filter =>
                                <Grid item key={filter.field}>
                                    <Paper>
                                        <Box p={1}>
                                            <Typography variant={"button"}>
                                                {filter.fieldName && filter.fieldName.toLowerCase() + " "}
                                                <u>{describeOperator(filter.operator)}</u>
                                                {" " + (filter.stringValue || filter.numericValue)}
                                            </Typography>
                                        </Box>
                                    </Paper>
                                </Grid>
                            )}
                            {request && request.filters.length === 0 &&
                                <Grid item>
                                    <Typography variant={"caption"}>
                                        No filters
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={4} container>
                        {request && Object.keys(request.parameters).map(key =>
                            <Grid item container key={key}>
                                <Grid item xs={4}>{key}</Grid>
                                <Grid item xs={8}>{request.parameters[key]}</Grid>
                            </Grid>
                        )}
                        {request && Object.keys(request.parameters).length === 0 &&
                            <Grid item>
                                <Typography variant={"caption"}>
                                    No parameters
                                </Typography>
                            </Grid>
                        }
                    </Grid>


                    {genericFiles.length > 0 && <Grid item xs={12}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Generic files
                        </Box>
                        <Divider/>
                    </Grid>
                    }

                    {genericFiles.map(o =>
                        <Grid item xs={6} md={4} lg={3} xl={2} key={o.attachmentId || o.title}>
                            <GenericFile output={o}/>
                        </Grid>
                    )}

                    {imageFiles.length > 0 && <Grid item xs={12}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Images
                        </Box>
                        <Divider/>
                    </Grid>
                    }

                    {imageFiles.map(o =>
                        <Grid item xs={6} md={6} lg={3} xl={3} key={o.attachmentId || o.title}>
                            <ImageFile output={o}/>
                        </Grid>
                    )}

                    {matrices.length > 0 && <Grid item xs={12}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Generic files
                        </Box>
                        <Divider/>
                    </Grid>
                    }

                    {matrices.map(o =>
                        <Grid item xs={6} md={4} lg={3} xl={2} key={o.title}>
                            <Matrix output={o}/>
                        </Grid>
                    )}

                    {values.length > 0 && <Grid item xs={12}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Values
                        </Box>
                        <Divider/>
                    </Grid>
                    }

                    {values.map(o =>
                        <Grid item xs={6} md={4} lg={3} xl={2} key={o.title}>
                            <Values output={o}/>
                        </Grid>
                    )}
                </Grid>
            </Grid>


        </Grid>
    )
}
