import React from "react";
import ImageMapper from "./image-mapper/ImageMapper";


function DrawingImage({
                          imageBlob,
                          spots = [],
                          points,
                          cursor = 'default',
                          spotsStrokes = {},
                          onClick = () => {
                          }
                      }) {


    const handleAreaClicked = (coords, area) => {
        // mi assicuro che sia stata cliccata un area
        if (area)
            onClick(area.id)
    }
    const spotAreas = spots.map(spot => {
        const coords = spot.spotContours.map(point => point.x + "," + point.y).join(",").split(",")
        const holes = spot.spotHolesContours ? spot.spotHolesContours.map(
            hole => hole.map(point => point.x + "," + point.y).reverse().join(",").split(",")
        ) : []

        // se completo metto il colore verde
        const fill = spot.complete ? "rgba(0, 255, 0, 0.5)" : "rgba(255, 0, 0, 0.5)"


        return {id: spot.id,
            coords: coords,
            fill: fill,
            strokeColor:spotsStrokes[spot.id],
            holes: holes}
    })


    return (
        <ImageMapper
            cursor={cursor}
            src={imageBlob}
            areas={spotAreas}
            infoPoints={points}
            referenceSize={{
                x: 1536,
                y: 2048
            }}
            onClick={handleAreaClicked}
        />
    )
}

export default DrawingImage;