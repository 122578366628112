import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {useHistory, useParams} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {AppContext} from "../../../App";
import {fetchPatientDetail, fetchPatientProjectAcquisitions} from "../../../utils/apiCaller";
import {EasyBreadcrumbs} from "../../../components/Breadcumbs";
import BasicInfos from "../../../components/PatientBasicInfo";
import PaginatedTable from "../../../components/PaginatedTable";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const tableColumns = [
    {key: "project_name", name: "Project", sortingEnabled: false},
    {key: "date", name: "Date"},
    {key: "operator", name: "Operator"}
]

function PatientDetail() {
    const classes = useStyles();

    const [state] = useContext(AppContext)
    const jwt = state.user.jwt

    const [patient, setPatient] = useState({painAcquisitions: []});
    const {patientId} = useParams();

    const history = useHistory();

    useEffect(() => {
        fetchPatientDetail(jwt.token, patientId)
            .then(result => setPatient(result)
            )
            .catch(error => alert(error))
    }, [patientId, jwt.token])

    const [pageArgs, setPageArgs] = useState()

    useEffect(() => {
        if (state)
            setPageArgs({
                jwt: state.user.jwt.token,
                patientId: patientId,
                projectId:""
            })
    }, [state, patientId])

    return (
        <Grid container>
            <Grid item xs={12} style={{height: 60}} className={classes.banner}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                         style={{
                             height: 55,
                             paddingTop: 16,
                             verticalAlign: "middle",
                             display: "table-cell",
                             paddingLeft: 16
                         }}>
                        <EasyBreadcrumbs breadcrumbs={[{destination: "/supervisor/patient", label: "Patients"}]}
                                         current={"Patient"}/>
                    </Box>
                </Typography>

            </Grid>


            <Grid item xs={12} style={{marginBottom: 16}}><Divider/></Grid>

            <Grid item xs={4} style={{padding:16}}>
                <BasicInfos patient={patient} edit={false}/>
            </Grid>
            <Grid item xs={8} style={{padding:16}}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                        Acquisitions
                    </Box>
                </Typography>
                {pageArgs && <PaginatedTable
                    fields={tableColumns}
                    requestArgs={pageArgs}
                    paramsPrefix={"pt_"}
                    fetchingPageFunction={fetchPatientProjectAcquisitions}
                    onSelected={(e) => history.push("/" + e.projectId + "/acquisition/" + e.id)}
                />}
            </Grid>

        </Grid>


    )
}



export default PatientDetail;