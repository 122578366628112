import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {checkImageDimensions, toBase64} from "../../utils/generics";
import FileInput from "../../components/FileInput";


export default function BodyChartFileUpload({onChange, text}) {

    const [error, setFileError] = useState({error: false, message: ""});
    const [image, setImage] = useState("");

    const handleFileChange = (event) => {

        // estraggo la lista di file
        const fi = event.target

        // controllo se c'è almeno qualcosa
        if (fi.files.length > 0) {
            // se ce qualcosa dovrebbe essere 1 solo file!
            const file = fi.files.item(0)

            checkImageDimensions(file)
                .then(() =>{
                    // la converto in base 64 e lo passo all'onchange
                    toBase64(file)
                        .then(result => {
                            setImage(result) // me lo salvo localmente
                            onChange(result) // lo mando al padre
                        })
                        .catch(error => setFileError({error: true, message: error}))

                    // svuoto gli errori
                    setFileError({error: false, message: ""})
                })
                .catch(() =>{
                    setFileError({error: true, message: "The image dimensions are not correct"})
                })
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="body2" style={{color: "#626262"}} gutterBottom>
                    {text}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <FileInput onChange={handleFileChange} accept={"image/*"}/>
            </Grid>
            {error.error ?
                <Grid item xs={12}>
                    <Typography variant="body2" color={"error"} gutterBottom>
                        {error.message}
                    </Typography>
                </Grid>
                : image ? <Grid item xs={12}>
                    <img style={{width: 200}} src={image} alt={"Selected file"}/>
                </Grid> : ""

            }
        </Grid>
    )
}