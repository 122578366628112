import React from 'react';
import {Route, Switch} from "react-router-dom";
import UserListScreen from "./UsersListScreen";
import UserDetailScreen from "./UserDetailScreen";


export default function AcquisitionRouter() {

    return (
        <Switch>
            <Route exact path="/supervisor/user" component={UserListScreen}/>
            <Route path="/supervisor/user/:userId" component={UserDetailScreen}/>

        </Switch>
    );
}
