import React from 'react';
import {Route, Switch} from "react-router-dom";
import TeamScreen from "./team/TeamScreen";
import PatientRouter from "./patient/PatientRouter";
import AcquisitionRouter from "./acquisition/AcquisitionRouter";
import BodychartRouter from "./bodychart/BodychartRouter";
import DescriptorRouter from "./descriptor/DescriptorRouter";
import SettingsScreen from "./settings/SettingsScreen";
import ServicesRouter from "./services/ServicesRouter";
import DetailsScreen from "./details/DetailsScreen";


export default function ProjectRouter() {

    return (
        <Switch>
            <Route exact path="/:projectId" component={DetailsScreen}/>
            <Route exact path="/:projectId/team" component={TeamScreen}/>
            <Route path="/:projectId/team/:page" component={TeamScreen}/>
            <Route path="/:projectId/patient" component={PatientRouter}/>
            <Route path="/:projectId/acquisition" component={AcquisitionRouter}/>
            <Route path="/:projectId/chart" component={BodychartRouter}/>
            <Route path="/:projectId/descriptor" component={DescriptorRouter}/>
            <Route path="/:projectId/settings" component={SettingsScreen}/>
            <Route path="/:projectId/service" component={ServicesRouter}/>
        </Switch>
    );
}
