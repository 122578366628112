import React from 'react';
import {Route, Switch} from "react-router-dom";
import ServiceListScreen from "./ServiceListScreen";
import ServiceDetailScreen from "./ServiceDetailScreen";


export default function ServiceRouter() {

    return (
        <Switch>
            <Route exact path="/supervisor/service" component={ServiceListScreen}/>
            <Route exact path="/supervisor/service/:serviceId" component={ServiceDetailScreen}/>
        </Switch>
    );
}
