import React, {useContext, useEffect, useState} from "react";
import {fetchUsersPage, putNewUser} from "../../../utils/apiCaller";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useHistory} from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";

import NewUserDialog from "../../../dialogs/NewUserDialog";
import {AppContext} from "../../../App";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import PaginatedTable from "../../../components/PaginatedTable";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));


//name
// surname
// email
// supervisor
// dashboardAccess

const tableColumns = [
    {key: "name", name: "Name"},
    {key: "surname", name: "Surname"},
    {key: "email", name: "Email"},
    {key: "isSupervisor", name: "Supervisor", boolean: true},
    {key: "dashboardAccess", name: "Has dashboard access", boolean: true, sorting: false},
]

function UserListScreen() {
    const classes = useStyles();
    const [state] = useContext(AppContext);
    const jwt = state.user.jwt

    const [nameFilter, setNameFilter] = useState("");
    const [mustRefresh, setMustRefresh] = useState(false);

    const history = useHistory();

    // stato che contiene la lista di acquisitions
    const [open, setOpen] = useState(false);

    const handleNewUser = (payload) => {
        putNewUser(jwt.token, payload)
            .then(() => setMustRefresh(true))
            .catch(result => alert(result))

        setOpen(false)
    }

    const [pageArgs, setPageArgs] = useState()

    useEffect(() =>{
        if(state)
            setPageArgs({
                jwt: state.user.jwt.token,
                search: nameFilter,
            })
    },[state, nameFilter])


    return (
        <Grid container>
            <Grid item xs={4} className={classes.banner}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 60,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16
                     }}>
                    Users
                </Box>
            </Grid>
            <Grid item xs={8} className={classes.banner}>

                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{height: 68, paddingLeft: 16, paddingRight: 16, paddingTop: 5}}
                    spacing={1}
                >
                    <Grid item>
                        <TextField
                            onChange={(e) => setNameFilter(e.target.value)}
                            size={"small"}
                            value={nameFilter}
                            variant={"outlined"}
                            placeholder={"Filter by name or surname"}
                            fullWidth
                            InputProps={{
                                startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
                            }}
                        />
                    </Grid>

                    <Grid item>
                        <IconButton onClick={() => setOpen(true)} color={"secondary"}>
                            <AddIcon/>
                        </IconButton>
                    </Grid>

                </Grid>


            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                {pageArgs && <PaginatedTable
                    jwt={state.user.jwt.token}
                    fields={tableColumns}
                    mustRefresh={mustRefresh}
                    requestArgs={pageArgs}
                    onRefreshed={() => setMustRefresh(false)}
                    fetchingPageFunction={fetchUsersPage}
                    onSelected={(e) => history.push("/supervisor/user/" + e.id)}
                />}
                <NewUserDialog onSave={handleNewUser} open={open} onClose={() => setOpen(false)}/>

            </Grid>
        </Grid>


    )
}

export default UserListScreen;