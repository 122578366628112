import React from 'react';
import {Route, Switch} from "react-router-dom";
import FeedbackListScreen from "./FeedbackListScreen";
import FeedbackDetailScreen from "./FeedbackDetailScreen";


export default function FeedbackRouter() {

    return (
        <Switch>
            <Route exact path="/supervisor/feedback" component={FeedbackListScreen}/>
            <Route path="/supervisor/feedback/:feedbackId" component={FeedbackDetailScreen}/>
        </Switch>
    );
}
