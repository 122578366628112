import React from 'react';
import {Route, Switch} from "react-router-dom";
import LocaleListScreen from "./LocaleListScreen";
import LocaleDetailScreen from "./LocaleDetailScreen";


export default function LocaleRouter() {

    return (
        <Switch>
            <Route exact path="/supervisor/locale" component={LocaleListScreen}/>
            <Route exact path="/supervisor/locale/:localeId" component={LocaleDetailScreen}/>
        </Switch>
    );
}
