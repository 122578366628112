import React from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import OptionBasedPreview from "../../../components/descriptor/preview/OptionBasedPreview";
import RangeBasedPreview from "../../../components/descriptor/preview/RangeBasedPreview";


export default function SelectionStep2({descriptor, onConfirm}) {

    let preview

    //console.log(descriptor)

    switch (descriptor.kind) {
        case 'OptionBasedDescriptor':
            preview = <OptionBasedPreview variant={"outlined"} descriptor={descriptor} defaultValue={""}/>
            break
        case 'RangeBasedDescriptor':
            preview = <RangeBasedPreview variant={"outlined"} descriptor={descriptor} defaultValue={descriptor.min}/>
            break
        default:
            preview =
                <Typography variant="body2" style={{color: "#626262"}} gutterBottom>
                    No preview available for the selected type.
                </Typography>;
    }

    return (
        <Grid container spacing={3}
              style={{
                  margin: 0,
                  width: '100%',
                  padding:32
              }}>
            <Grid item xs={6}>
                Settings
            </Grid>
            <Grid item xs={6}>
                {preview}
            </Grid>
        </Grid>
    )
}