import React from "react";

import Grid from "@material-ui/core/Grid";

export default function Matrix() {

    return (
        <Grid container>
            <Grid item xs={12}>
                Matrix
            </Grid>
        </Grid>
    )
}
