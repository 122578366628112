import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {useHistory} from "react-router-dom";
import {fetchAllPatients} from "../../../utils/apiCaller";
import Box from "@material-ui/core/Box";
import PaginatedTable from "../../../components/PaginatedTable";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const tableColumns = [
    {key: "id", name: "Id", sorting: false},
    {key: "gender", name: "Gender"},
    {key: "birth", name: "Birth"},
    {key: "height", name: "Height"},
    {key: "weight", name: "Weight"},
]

function ListScreen() {
    const classes = useStyles();

    const [state] = useContext(AppContext);
    const history = useHistory()

    const [pageArgs, setPageArgs] = useState()

    useEffect(() => {
        if (state)
            setPageArgs({
                jwt: state.user.jwt.token,
            })
    }, [state])


    return (
        <Grid container>
            <Grid item xs={12} className={classes.banner}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 60,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16
                     }}>
                    Platform patients
                </Box>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                {pageArgs && <PaginatedTable
                    jwt={state.user.jwt.token}
                    fields={tableColumns}
                    requestArgs={pageArgs}
                    fetchingPageFunction={fetchAllPatients}
                    onSelected={(e) => history.push("/supervisor/patient/" + e.id)}
                />}

            </Grid>
        </Grid>

    )
}

export default ListScreen;