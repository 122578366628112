import React from 'react';
import {Route, Switch} from "react-router-dom";
import ListScreen from "./ListScreen";
import AcquisitionDetail from "./AcquisitionDetail";
import DrawingDetail from "./drawing/DrawingDetail";
import SpotDetail from "./drawing/spot/SpotDetail";
import ImportListScreen from "./import/ImportListScreen";
import ImportDetailScreen from "./import/ImportDetailScreen";


export default function AcquisitionRouter() {

    return (
        <Switch>
            <Route exact path="/:projectId/acquisition" component={ListScreen}/>
            <Route exact path="/:projectId/acquisition/import" component={ImportListScreen}/>
            <Route exact path="/:projectId/acquisition/import/:acquisitionId" component={ImportDetailScreen}/>
            <Route exact path="/:projectId/acquisition/:acquisitionId" component={AcquisitionDetail}/>

            <Route exact path="/:projectId/acquisition/:acquisitionId/:drawingId" component={DrawingDetail}/>
            <Route path="/:projectId/acquisition/:acquisitionId/:drawingId/:spotId" component={SpotDetail}/>
            {/*<Route path="/:projectId/acquisition/:acquisitionId" component={TeamScreen}/>*/}
        </Switch>
    );
}
