import React, {useContext} from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from '@material-ui/icons/Settings';
import FeedbackIcon from '@material-ui/icons/Feedback';
import {NavLink, useHistory} from "react-router-dom";
import {AppContext} from "../App";
import ApartmentIcon from '@material-ui/icons/Apartment';


export default function UserEntry({onFeedback}) {
    const [state, dispatch] = useContext(AppContext);
    const history = useHistory();
    const user = state.user;

    const handleLogout = () => {

        // mando l'azione di logout
        dispatch({
            type: "LOGOUT"
        });
        history.push("/login")
    }


    return (
        <List style={{marginTop: "auto", cursor: "pointer"}}>

            <ListItem>
                <ListItemAvatar>
                    <Avatar>
                        {user.name.substr(0, 1).toUpperCase()}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={user.name + ", " + user.surname} secondary={user.emailAddress}/>
            </ListItem>
            {user.isSupervisor && <Divider/>}
            {user.isSupervisor && <ListItem button activeClassName="Mui-selected" to={"/supervisor"} component={NavLink}>
                <ListItemIcon>
                    <ApartmentIcon/>
                </ListItemIcon>
                <ListItemText primary="Supervisor console"/>
            </ListItem>}
            <Divider/>
            <ListItem button to={"/settings"} component={NavLink}>
                <ListItemIcon>
                    <SettingsIcon/>
                </ListItemIcon>
                <ListItemText primary="Settings"/>
            </ListItem>
            <ListItem button onClick={onFeedback}>
                <ListItemIcon>
                    <FeedbackIcon/>
                </ListItemIcon>
                <ListItemText primary="Feedback"/>
            </ListItem>
            <ListItem button onClick={handleLogout}>
                <ListItemIcon>
                    <ExitToAppIcon/>
                </ListItemIcon>
                <ListItemText primary="Logout"/>
            </ListItem>
        </List>


    )
}