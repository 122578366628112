import React, {useCallback, useContext, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import {AppContext} from "../../App";
import {fetchPatientDetail} from "../../utils/apiCaller";
import Button from "@material-ui/core/Button";
import debounce from "lodash/debounce";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function PatientSelection({onSelected, female, sexConstraint}) {

    const [state] = useContext(AppContext);

    const [filterId, setFilterId] = useState("");
    const [patient, setPatient] = useState({});
    const [patientIdError, setPatientIdError] = useState({error: false, text: ""})

    const [loading, setLoading] = useState(false);

    const handleFilterChange = (value) => {
        setFilterId(value)
        if (value !== "")
            debounceFilterChange(value)
        else
            setPatient({})
    }

    // debouncing sull update del filtro
    const debounceFilterChange = useCallback(debounce((value) => {
        setLoading(true)
        fetchPatientDetail(state.user.jwt.token, value)
            .then(patient => {
                setPatient(patient)
                setPatientIdError({error: false, text: ""})
            })
            .catch((error) => {
                setPatient({})
                setPatientIdError({error: true, text: error.message})
            })
            .then(() => setLoading(false))

    }, 200), []);

    const buttonDisabled = !sexConstraint ? false : !((patient.gender === "Female" && female) || (patient.gender === "Male" &&!female))


    return (
        <Grid container style={{marginBottom: 16}}>
            <Grid item xs={12}
                  style={{paddingRight: 20, paddingLeft: 20}}>
                <TextField
                    onChange={(e) => handleFilterChange(e.target.value)}
                    value={filterId}
                    helperText={patientIdError.text}
                    error={patientIdError.error}
                    size={"small"}
                    label="Search patient ID"
                    variant={"outlined"}
                    fullWidth
                    autoFocus
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {loading ? <CircularProgress size={25}/> : <SearchIcon/>}
                            </InputAdornment>),
                    }}
                />
            </Grid>
            <Grid item xs={12} style={{marginTop: 20}}>
                <Box hidden={!patient.id}>
                    <Grid container spacing={3}>
                        <Grid item xs={4} style={{textAlign: "center"}}>
                            <Box><b>Id</b></Box>
                            <Box>{patient.id}</Box>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "center"}}>
                            <Box><b>Birth</b></Box>
                            <Box>{patient.birth}</Box>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "center"}}>
                            <Box><b>Gender</b></Box>
                            <Box>{patient.gender}</Box>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "center"}}>
                            <Box><b>Height</b></Box>
                            <Box>{patient.height} m</Box>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "center"}}>
                            <Box><b>Weight</b></Box>
                            <Box>{patient.weight} Kg</Box>
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "center"}}>
                            <Button color={"secondary"} variant={"outlined"}
                                    disabled={buttonDisabled}
                                    onClick={() => onSelected(patient)}>
                                Select
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}