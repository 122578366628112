import React from "react";

import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import {CardHeader} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";

export default function Values({output}) {

    return (
        <Grid container>
            <Grid item xs={12}>
                <Card style={{height:"100%"}}>
                    <CardHeader title={output.title}/>
                    <CardContent>
                    <Grid container>
                        {Object.keys(output.values).map(key =>
                            <Grid item xs={12} key={key}>
                                <b>{key}:</b> {output.values[key]}
                            </Grid>
                        )}
                    </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    )
}
