import React from 'react';
import {Route, Switch} from "react-router-dom";
import DescriptorsListScreen from "./DescriptorsListScreen";
import DescriptorDetailScreen from "./DescriptorDetailScreen";


export default function DescriptorsRouter() {

    return (
        <Switch>
            <Route exact path="/supervisor/descriptor" component={DescriptorsListScreen}/>
            <Route exact path="/supervisor/descriptor/:descriptorId" component={DescriptorDetailScreen}/>
        </Switch>
    );
}
