import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import SwipeableViews from "react-swipeable-views";
import SelectionStep1 from "./selectionSteps/SelectionStep1";
import SelectionStep2 from "./selectionSteps/SelectionStep2";
import {useHistory, useParams} from "react-router-dom";

export default function DescriptorSelectionDialog({open, onClose, onSelected = () =>{}, disablePointSpot = true}) {

    const [step, setStep] = useState(0);
    const [selectedDescriptor, setSelectedDescriptor] = useState({});
    const history = useHistory();
    const {projectId} = useParams();

    const handleStepChange = (step) => {
        setStep(step);
    };

    const handleNewDescriptor = () => {
        history.push(`/settings/descriptor`)
    }

    const handleClose = () =>{
        setStep(0)
        setSelectedDescriptor({})
        onClose()
    }

    const handleDescriptorSelected = (descriptor) =>{
        // ora l'utente ha selezionato il descriptor
        // passo allo step 2, che è semplicemente una preview con i vari settings in modo che possa visionare
        // bene il descriptor prima di sceglierlo definitivamente
        setSelectedDescriptor(descriptor)
        setStep(1);
    }

    const handleOnConfirm = () =>{
        setStep(0)
        onSelected(selectedDescriptor)
        setSelectedDescriptor({})
    }

    return (
        <Dialog maxWidth={"md"} open={open} onClose={handleClose}>
            <DialogTitle>
                {"Select a descriptor"}
                <Button style={{
                    position: "absolute",
                    top: 16, right: 16,
                }}
                        color="default"
                        onClick={handleNewDescriptor}
                >
                    Create new
                </Button>
            </DialogTitle>


            <DialogContent style={{paddingRight: 0, paddingLeft: 0}}>

                <SwipeableViews
                    axis={'x'}
                    index={step}
                    onChangeIndex={handleStepChange}>

                    <SelectionStep1 onSelected={handleDescriptorSelected} projectId={projectId} disablePointSpot={disablePointSpot}/>
                    <SelectionStep2 descriptor={selectedDescriptor}/>

                </SwipeableViews>

            </DialogContent>


            <DialogActions>
                <Button
                    style={{display:step===1?"inline":"none"}}
                    onClick={() => setStep(0)}
                    color="primary">
                    Back
                </Button>
                <Button
                    style={{display:step===0?"inline":"none"}}
                    onClick={handleClose}
                    color="primary">
                    Close
                </Button>
                <Button
                    style={{display:step===1?"inline":"none"}}
                    onClick={handleOnConfirm}
                    color="primary">
                    Confirm selection
                </Button>
            </DialogActions>
        </Dialog>
    )
}