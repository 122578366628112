import React, {useContext, useEffect, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {TextField} from "@material-ui/core";
import {fetchBlob, fetchProjectDetails, generateMassiveImport} from "../utils/apiCaller";
import CircularProgress from "@material-ui/core/CircularProgress";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import {AppContext} from "../App";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";

export default function MassivePDFDialog({open, onClose, projectId, multiGender}) {
    const [state] = useContext(AppContext)
    const jwt = state.user.jwt
    // carico il progetto attualmente selezionato
    const project = projectId

    const [quantity, setQuantity] = useState(1);
    const [directions, setDirections] = useState(false);

    const [projectDetails, setProjectDetails] = useState({});

    const [generatingMassive, setGeneratingMassive] = useState(false);

    // carico le informazioni del progetto
    useEffect(() => {
        if (projectId && !!state.user)
            fetchProjectDetails(state.user.jwt.token, projectId)
                .then(result => setProjectDetails(result))


    }, [state.user, projectId])

    const handleMassiveGeneration = () => {
        setGeneratingMassive(true)
        generateMassiveImport(jwt.token, project, quantity, directions)
            .then(result => {
                fetchBlob(jwt.token, "/" + result.pdfUrl)
                    .then(blob => {
                        // const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.style.display = 'none';
                        a.href = blob;
                        // the filename you want

                        let prefix = ""
                        if(projectDetails.name)
                            prefix = projectDetails.name.toLowerCase().replaceAll(' ','_')

                        a.download = prefix + '-massive.pdf';
                        document.body.appendChild(a);
                        a.click();
                        // window.URL.revokeObjectURL(url);
                        setGeneratingMassive(false)
                        onClose()
                    })
                    .catch(alert)
            })
            .catch(alert)
    }

    const handleOnQuantityChange = (qta) =>{
        if (qta>0)
            setQuantity(qta)
    }

    return (
        <Dialog maxWidth={"sm"} fullWidth open={open} onClose={onClose} onExited={() => {
        }}>
            <DialogTitle>
                Generate pdf
            </DialogTitle>
            <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField fullWidth value={quantity}
                                       variant={"outlined"}
                                       type={"number"}
                                       size={"small"}
                                       onChange={(event => handleOnQuantityChange(event.target.value))}
                                       placeholder={"Quantity"}/>
                        </Grid>
                        <Grid item xs={12}>
                            Show directions (left and right)
                            <Switch color="primary" value={directions} onChange={(e,v) => setDirections(v)} />
                        </Grid>
                        <Grid item xs={12}>
                            I will generate a PDF
                            with {quantity} {!multiGender ? " sessions" : " male sessions and " + quantity + " female sessions"}
                        </Grid>
                        {multiGender &&
                        <Grid item xs={12}>
                            A total of {quantity * 2} sessions
                        </Grid>
                        }
                        <Grid item xs={12}>
                            <Button
                                startIcon={generatingMassive ? <CircularProgress size={25}/> : <ImportContactsIcon/>}
                                disabled={generatingMassive}
                                variant={"outlined"} onClick={handleMassiveGeneration}>
                                Generate
                            </Button>
                        </Grid>
                    </Grid>
            </DialogContent>
        </Dialog>
    )
}