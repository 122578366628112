import React, {useContext, useState} from 'react';

import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {postAdditionalInfo, putNewAdditionalInfo} from "../utils/apiCaller";
import {AppContext} from "../App";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

export default function AdditionalInfoDialog({open, onClose, onSave, projectId, existing}) {

    const [state, dispatch] = useContext(AppContext)

    const [name, setName] = useState({error: false, text: "", value: existing ? existing.name : ""});
    const [description, setDescription] = useState({error: false, text: "", value: existing ? existing.description : ""});
    const [type, setType] = useState({error: false, text: "", value: existing ? existing.type : ""});
    const [defaultValue, setDefaultValue] = useState({error: false, text: "", value: existing ? existing.defaultValue : ""});

    const [loading, setLoading] = useState(false);

    const handleSave = () => {
        let error = false;

        if (name.value === "" || name.value === undefined) {
            setName({value: name.value, error: true, text: "Please, enter a name"})
            error = true
        } else
            setName({value: name.value, error: false, text: ""})

        if (description.value === "" || description.value === undefined) {
            setDescription({value: name.value, error: true, text: "Please, enter a description"})
            error = true
        } else
            setDescription(Object.assign(description, {error: false, text: ""}))

        if (type.value === "" || type.value === undefined) {
            setType({value: name.value, error: true, text: "Please, select a type"})
            error = true
        } else
            setType(Object.assign(type, {error: false, text: ""}))

        if (!error){
            const payload = {
                name: name.value,
                description: description.value,
                type: type.value,
                defaultValue: defaultValue.value
            }

            if(!existing)
                handleOnSave(payload)
            else{
                payload.id = existing.id
                handleOnUpdate(payload)
            }
        }

    }

    const handleOnSave = (payload) => {
        putNewAdditionalInfo(state.user.jwt.token, projectId, payload)
            .then(result => onSave(result))
            .catch((error) => {
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            })
        setLoading(true)
    }

    const handleOnUpdate = (payload) => {
        postAdditionalInfo(state.user.jwt.token, projectId, payload)
            .then(result => onSave(result))
            .catch((error) => {
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            })
        setLoading(true)
    }

    const handleChange = (state, func) => (event) =>
        func({value: event.target.value, error: state.error, text: state.text})

    const handleChangeBoolean = (state, func) => (event, checked) =>
        func({value: checked, error: state.error, text: state.text})


    const handleClose = () => {
        onClose()
    }

    const handleOnExited = () => {
        setLoading(false)
        setName({error: false, text: ""})
        setDescription({error: false, text: ""})
        setType({error: false, text: ""})
        setDefaultValue({error: false, text: ""})
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={"sm"} onExited={handleOnExited}>
            <DialogTitle>{existing?"Update":"New"} additional info field</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}
                      style={{
                          margin: 0,
                          width: '100%',
                      }}
                >
                    <Grid item xs={12}>
                        <TextField onChange={handleChange(name, setName)} value={name.value} fullWidth
                                   error={name.error} helperText={name.text} label={"Name"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField onChange={handleChange(description, setDescription)} value={description.value} fullWidth
                                   error={description.error} helperText={description.text} label={"Description"}/>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl
                            error={type.error}
                            fullWidth>
                            <InputLabel>Type</InputLabel>
                            <Select
                                // helperText={type.text}
                                value={type.value || ""}
                                onChange={handleChange(type, setType)}
                            >
                                <MenuItem value={"Number"}>Number</MenuItem>
                                <MenuItem value={"String"}>String</MenuItem>
                                <MenuItem value={"Boolean"}>Boolean</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        {type.value === "Boolean" ?
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={defaultValue.value}
                                        onChange={handleChangeBoolean(defaultValue, setDefaultValue)}
                                        name="checkedB"
                                        color="primary"
                                    />
                                }
                                label="Default value"
                                labelPlacement={"start"}
                            />
                            :
                            <TextField onChange={handleChange(defaultValue, setDefaultValue)} fullWidth
                                       value={defaultValue.value}
                                       error={defaultValue.error} helperText={defaultValue.text}
                                       type={type.value === "Number" ? "number" : ""} label={"Default value"}/>
                        }
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary">
                    {loading && <CircularProgress/>} {existing? "Save" : "Create"}
                </Button>
            </DialogActions>
        </Dialog>
    )
}