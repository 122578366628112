import React, {useCallback, useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {
    fetchAcquisitionDetail,
    fetchProjectDescriptors,
    fetchProjectDetails,
    fetchProjectTeam,
    postResetUnapprovedDrawing,
    removeAcquisition,
    updateAcquisition,
    updateAcquisitionValue,
    updateDrawingValue
} from "../../../../utils/apiCaller";
import {EasyBreadcrumbs} from "../../../../components/Breadcumbs";
import {AppContext} from "../../../../App";
import GenericValueBased from "../../../../components/values/GenericValueBased";
import debounce from "lodash/debounce";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import PatientDialog from "../../../../dialogs/patient/PatientDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import DrawingPreview from "../../../../components/DrawingPreview";
import TwoWayDialog from "../../../../dialogs/TwoWayDialog";
import DrawingImportRow from "../../../../components/DrawingImportRow";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const fieldsValidation = {
    date: (value) => {
        if (!value)
            return {error: true, text: "Please, enter an acquisition date", value: value}
        return {error: false, text: "", value: value}
    },
    operator: (value) => {
        if (!value)
            return {error: true, text: "Please, select an operator", value: value}
        return {error: false, text: "", value: value}
    },
    patient: (value) => {
        if (!value || value <= 0)
            return {error: true, text: "Please, choose a patient", value: value}
        return {error: false, text: "", value: value}
    }
}

function ImportDetailScreen() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext)
    const jwt = state.user.jwt

    const {acquisitionId, projectId} = useParams();

    // stato che contiene la lista di acquisitions
    const [acquisition, setAcquisition] = useState({drawings: [], values: []});
    const [projectDescriptors, setProjectDescriptors] = useState({drawingDescriptors: [], acquisitionDescriptors: []});
    const [projectTeam, setProjectTeam] = useState([]);
    const [drawingsIds, setDrawingsIds] = useState([]);
    const [minDate, setMinDate] = useState();

    const [acquisitionDate, setAcquisitionDate] = useState({error: false, text: "", value: null})
    const [patientId, setPatientId] = useState({error: false, text: "", value: ""})
    const [operator, setOperator] = useState({error: false, text: "", value: null})

    const [drawingSelected, setDrawingSelected] = useState()

    const [patientDialog, setPatientDialog] = useState(false)

    const [importLoading, setImportLoading] = useState(false)
    const [importDialog, setImportDialog] = useState(false)
    const [deletionDialog, setDeletionDialog] = useState(false)

    const history = useHistory();


    // carico tutto il necessario
    useEffect(() => {

        dispatch({type: "START_LOADING", payload: {key: "fetchAcquisitionDetail"}})
        fetchAcquisitionDetail(jwt.token, acquisitionId)
            .then(result => {
                setAcquisition(result)
                setDrawingsIds(result.drawingsIds)
                dispatch({type: "LOADED", payload: {key: "fetchAcquisitionDetail"}})
            })
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

        dispatch({type: "START_LOADING", payload: {key: "fetchProjectDescriptors"}})
        fetchProjectDescriptors(jwt.token, projectId)
            .then(result => {
                setProjectDescriptors(result)
                dispatch({type: "LOADED", payload: {key: "fetchProjectDescriptors"}})
            })
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

        fetchProjectDetails(jwt.token, projectId)
            .then(result => {
                const creationDate = result.creationDateTime
                if (creationDate)
                    setMinDate(new Date(creationDate * 1000))
                else
                    setMinDate(undefined)
            })
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

        dispatch({type: "START_LOADING", payload: {key: "fetchProjectTeam"}})
        fetchProjectTeam(jwt.token, projectId)
            .then(result => {
                setProjectTeam(result.leaders.concat(result.operators))
                dispatch({type: "LOADED", payload: {key: "fetchProjectTeam"}})
            })
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

    }, [acquisitionId, jwt, dispatch, projectId])

    // debouncing sull update dei valori
    const handleDrawingValueUpdate = useCallback(debounce((drawing, descriptor, value, color) => {
        updateDrawingValue(jwt.token, acquisitionId, drawing.id, descriptor.id, value, color)
            .then(() => dispatch({type: "SHOW_ALERT", payload: {message: "Drawing value updated successfully"}}))
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

    }, 200), []);


    // debouncing sull update dei valori
    const handleAcquisitionValueUpdate = useCallback(debounce((descriptor, value) => {

        updateAcquisitionValue(jwt.token, acquisitionId, descriptor.id, value)
            .then(() => dispatch({type: "SHOW_ALERT", payload: {message: "Acquisition value updated successfully"}}))
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

    }, 200), []);

    const handleBasicsChange = ({operatorId, dateTimestamp, patient}) => {
        const payload = {
            id: acquisitionId,
            patientId: patient || (patientId.value ? patientId.value : null),
            operatorId: operatorId || (operator.value ? operator.value.id : null),
            dateTimestamp: dateTimestamp || (!!acquisitionDate.value ? acquisitionDate.value.getTime() / 1000 : null)
        }

        updateAcquisition(jwt.token, payload)
            .then(result => setAcquisition(result))
            .then(() => dispatch({type: "SHOW_ALERT", payload: {message: "Basics updated successfully"}}))
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )
    }

    useEffect(() => {
        if (!!acquisition.id && acquisition.approved) {
            alert("This acquisition has been already imported")
            history.push(`/${acquisition.projectId}/acquisition/${acquisition.id}`)
        }

        if (!!acquisition.id && projectTeam.length > 0) {
            // devo cercare l'operatore correntemente selezionato
            const selectedOperator = projectTeam.find(user => user.id === acquisition.operatorId) || null
            setOperator({error: false, text: "", value: selectedOperator})

        }

        if (!!acquisition.id) {

            if (acquisition.dateTimestamp) {
                const selectedDate = new Date(acquisition.dateTimestamp * 1000)
                setAcquisitionDate({error: false, text: "", value: selectedDate})
            }

            if (acquisition.patientId)
                setPatientId({error: false, text: "", value: acquisition.patientId});

        }

    }, [acquisition, projectTeam, history])


    const handleDrawingDiscard = (id) => {
        if (window.confirm("Sure? You will need to re-import the drawing"))
            postResetUnapprovedDrawing(jwt.token, acquisitionId, id)
                .then(() => {
                    const index = drawingsIds.indexOf(id);
                    if (index > -1) {
                        drawingsIds.splice(index, 1);
                    }
                    setDrawingsIds(drawingsIds)

                    if (drawingsIds.length === 0) {
                        dispatch({type: "SHOW_ALERT", payload: {message: "Drawing discarded and acquisition deleted"}})
                        history.push("/" + projectId + "/acquisition/import")
                    } else
                        dispatch({type: "SHOW_ALERT", payload: {message: "Drawing discarded"}})
                })
                .catch(error =>
                    dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
                )
    }

    const handleRemoveAcquisition = () => {
        removeAcquisition(jwt.token, acquisitionId)
            .then(() => {
                dispatch({type: "SHOW_ALERT", payload: {message: "Acquisition removed successfully"}})
                history.push(`/${projectId}/acquisition/import`)
            })
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )
    }

    const handleApproveImport = () => {
        // allora, qui starebbe da controllare i vari valori se sono popolati, in ogni caso un check è da fare su:
        // - data
        // - paziente
        // - operatore

        const dateCheck = fieldsValidation.date(acquisitionDate.value)
        const patientCheck = fieldsValidation.patient(patientId.value)
        const operatorCheck = fieldsValidation.operator(operator.value)

        const hasError = dateCheck.error || patientCheck.error || operatorCheck.error

        if (!hasError) {

            if (drawingsIds.length === 0) {
                alert("Unable to approve, there are no drawings")
                return
            }

            setImportLoading(true)
            const payload = {
                id: acquisitionId,
                approved: true
            }

            updateAcquisition(jwt.token, payload)
                .then(() => {
                    setImportDialog(true)
                })
                .catch(error =>
                    dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
                )
        } else {
            setAcquisitionDate(dateCheck)
            setPatientId(patientCheck)
            setOperator(operatorCheck)
        }
    }

    return (
        <Grid container>
            <Grid item xs={6} style={{height: 60}} className={classes.banner}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                         style={{
                             height: 55,
                             paddingTop: 16,
                             verticalAlign: "middle",
                             display: "table-cell",
                             paddingLeft: 16
                         }}>
                        <EasyBreadcrumbs breadcrumbs={[
                            {destination: "/" + projectId + "/acquisition", label: "Acquisitions"},
                            {destination: "/" + projectId + "/acquisition/import", label: "Importing"}
                        ]}
                                         current={"Acquisition"}/>
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={6} className={classes.banner} style={{textAlign: "right"}}>
                <Button variant="outlined" color="primary" style={{marginRight: 16, marginTop: 16}}
                        onClick={handleApproveImport}>
                    {importLoading && <CircularProgress size={25}/>}
                    Approve import
                </Button>
                <Button variant="outlined" color="secondary" style={{marginRight: 16, marginTop: 16}}
                        onClick={() => setDeletionDialog(true)}>
                    <DeleteIcon/>
                    Delete acquisition
                </Button>
                <TwoWayDialog
                    onRight={handleRemoveAcquisition}
                    onLeft={() => setDeletionDialog(false)}
                    onClose={() => setDeletionDialog(false)}
                    open={deletionDialog}
                    right={"Confirm"}
                    left={"Cancel"}
                    title={"Confirm acquisition deletion"}
                    text={"Are you really sure about deleting the acquisition? This action is not reversible"}
                />

                <TwoWayDialog
                    onRight={() => history.push(`/${projectId}/acquisition/${acquisitionId}`)}
                    onLeft={() => history.push(`/${projectId}/acquisition/import`)}
                    onClose={() => setImportDialog(false)}
                    open={importDialog}
                    right={"open acquisition"}
                    left={"back to import list"}
                    title={"Imported successfully"}
                    text={"The acquisition has been successfully imported."}
                />
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            {!acquisition.isDigital && <Grid item xs={6} style={{textAlign: "center"}}>
                <Box fontWeight="fontWeightLight" fontSize={"h6.fontSize"}>
                    Session identifier
                </Box>
                <Box fontWeight="fontWeight" fontSize={"h5.fontSize"}>
                    {acquisition.id ? acquisition.manualSessionId : "..."
                    }
                </Box>
            </Grid>}
            {!acquisition.isDigital && <Grid item xs={6} style={{textAlign: "center"}}>
                <Box fontWeight="fontWeightLight" fontSize={"h6.fontSize"}>
                    Set number
                </Box>
                <Box fontWeight="fontWeight" fontSize={"h5.fontSize"}>
                    {acquisition.id ? acquisition.manualSessionSet : "..."
                    }
                </Box>
            </Grid>}

            <Grid item xs={12} style={{padding: 16}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Import attributes
                        </Box>
                        <Divider/>
                    </Grid>
                    <Grid item xs={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                disableFuture
                                minDate={minDate}
                                inputVariant="outlined"
                                label={"Acquisition date"}
                                ampm={false}
                                error={acquisitionDate.error}
                                placeholder={"Acquisition date"}
                                helperText={acquisitionDate.text}
                                value={acquisitionDate.value}
                                onChange={(e) => {
                                    handleBasicsChange({dateTimestamp: e.getTime() / 1000})
                                }}
                                fullWidth format="dd/MM/yyyy HH:mm:ss"
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={4}>
                        <PatientDialog
                            open={patientDialog}
                            projectId={projectId}
                            sexConstraint={acquisition.multiGenderProject || true}
                            female={acquisition.femaleAcquisition || false}
                            onSelected={(id) => {
                                handleBasicsChange({patient: id})
                                setPatientId({error: false, text: "", value: id})
                            }}
                            onClose={() => setPatientDialog(false)}
                        />
                        <TextField
                            value={patientId.value}
                            error={patientId.error}
                            helperText={patientId.text}
                            onChange={() => {
                            }}
                            onClick={() => setPatientDialog(true)}
                            label={"Patient ID"}
                            fullWidth variant="outlined"/>
                    </Grid>
                    <Grid item xs={4}>
                        <Autocomplete
                            disableClearable
                            options={projectTeam}
                            onChange={(evt, e) => {
                                handleBasicsChange({operatorId: e.id})
                            }}
                            getOptionLabel={(option) => option.fullName}
                            value={operator.value}
                            renderInput={(params) =>
                                <TextField
                                    error={operator.error}
                                    helperText={operator.text}
                                    placeholder={"Operator"}
                                    {...params}
                                    label={"Operator"}
                                    fullWidth variant="outlined"/>
                            }
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} style={{padding: 16}}>
                {<Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Acquisition values
                        </Box>
                        <Divider/>
                    </Grid>

                    {acquisition.id && projectDescriptors.acquisitionDescriptors.map(descriptor =>
                        <Grid key={descriptor.id} item xs={3}>
                            <GenericValueBased
                                onUpdate={(id, val) => handleAcquisitionValueUpdate(descriptor, val)}
                                descriptor={descriptor}
                                value={acquisition.values[descriptor.id] || null}/>
                        </Grid>
                    )}
                </Grid>}
            </Grid>


            <Grid item xs={12} style={{paddingLeft: 16}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Drawing values
                        </Box>
                        <Divider/>
                    </Grid>

                    <DrawingPreview open={!!drawingSelected}
                                    drawing={drawingSelected}
                                    onClose={() => setDrawingSelected(null)}/>

                    {drawingsIds.map(id =>
                        <DrawingImportRow
                            key={id}
                            acquisitionId={acquisitionId}
                            onDrawingClick={setDrawingSelected}
                            onDrawingDiscard={handleDrawingDiscard}
                            onDrawingValueUpdate={handleDrawingValueUpdate}
                            descriptors={projectDescriptors.drawingDescriptors}
                            drawingId={id}
                        />
                    )}
                </Grid>
            </Grid>

        </Grid>
    )
}


export default ImportDetailScreen;