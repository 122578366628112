import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import DrawingImage from "../../../../../components/DrawingImage";
import PainValues from "../../../../../components/PainValues";
import {fetchBlob, fetchProjectDescriptors, fetchSpot} from "../../../../../utils/apiCaller";
import {AppContext} from "../../../../../App";
import {EasyBreadcrumbs} from "../../../../../components/Breadcumbs";
import CircularProgress from "@material-ui/core/CircularProgress";
import PainExtent from "../../../../../components/PainExtent";
import {useQueryParams} from "../../../../../hooks/useQueryParams";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const AnatomicalArea = ({name, path}) => {
    return (
        <>
            <Grid item xs={4}>
                <Box style={{marginLeft: 10}} fontWeight="fontWeightLight"
                     fontSize={"h6.fontSize"}>
                    {name}
                </Box>
            </Grid>

            <Grid item xs={8}>
                <Box style={{marginLeft: 10}} fontWeight="fontWeightLight"
                     fontSize={"h7.fontSize"}>
                    {path}
                </Box>
            </Grid>
        </>
    )
}

const downloadingImage = (
    <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
            <CircularProgress/>
        </Grid>
        <Grid item>
            Downloading image
        </Grid>
    </Grid>
)

function SpotDetail() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext)
    const jwt = state.user.jwt
    // carico il progetto attualmente selezionato
    const {acquisitionId, drawingId, projectId, spotId} = useParams();
    const [pointBasedPoints, setPointBasedPoints] = useState([]);
    const [spotBasedValues, setSpotBasedValues] = useState({});

    // stato che contiene il drawing
    const [spot, setSpot] = useState({descriptors: [], spotContours: []});
    const [descriptors, setDescriptors] = useState();
    const [values, setValues] = useState([]);
    const [imageBlob, setImageBlob] = useState("");

    const queryParams =  useQueryParams()

    let destinationParams = `?page=${queryParams.qp.page || ""}&size=${queryParams.qp.size || ""}&sort=${queryParams.qp.sort || ""}&dir=${queryParams.qp.dir || ""}`

    const breadcrumbs = [
        {destination: "/" + projectId + "/acquisition" + destinationParams, label: "Acquisitions"},
        {destination: "/" + projectId + "/acquisition/" + acquisitionId + destinationParams, label: "Acquisition"},
        {destination: "/" + projectId + "/acquisition/" + acquisitionId + "/" + drawingId + destinationParams, label: "Drawing"}
    ]

    useEffect(() => {
        dispatch({type: "START_LOADING", payload: {key: "fetchSpot"}})
        dispatch({type: "START_LOADING", payload: {key: "fetchProjectDescriptors"}})
        fetchSpot(jwt.token, acquisitionId, drawingId, spotId)
            .then(result => {
                setSpot(result)
                dispatch({type: "LOADED", payload: {key: "fetchSpot"}})
            })
            .catch(result => alert(result))

        fetchProjectDescriptors(jwt.token, projectId)
            .then(result => {
                setDescriptors(result.spotDescriptors)
                dispatch({type: "LOADED", payload: {key: "fetchProjectDescriptors"}})
            })
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )
    }, [drawingId, acquisitionId, spotId, jwt, projectId, dispatch])

    useEffect(() => {
        if (spot.id === undefined || !descriptors)
            return

        // mappo i descriptors con i valori

        const points = []
        const spots = {}

        const result = descriptors.map(
            d => {
                let value = spot.values[d.id]

                let result = {
                    unitOfMeasure: d.unitOfMeasure,
                    key: d.key,
                    id: d.id,
                    description: d.description
                }
                let color
                if (value)
                    color = value.color

                if (d.kind === "PointBasedDescriptor" && value) {
                    points.push({
                        x: value.x,
                        y: value.y,
                        fill: color
                    })
                    result.value = `(${value.x},${value.y})`
                } else if (d.kind === "SpotBasedDescriptor" && value) {
                    spots[value.value] = color
                    result.value = value.value
                } else if (value) {
                    result.value = value.value
                } else
                    result.value = "unknown"

                result.color = color
                return result
            }
        )

        // scarico l'immagine se non è già stato fatto
        if (imageBlob === "" && spot.chartLowUrl)
            fetchBlob(jwt.token, spot.chartLowUrl)
                // metto l'immagine nello stato
                .then(image => setImageBlob(image))
                .catch(e => alert(e))

        setValues(result)
        setPointBasedPoints(points)
        setSpotBasedValues(spots)
    }, [spot, imageBlob, jwt, descriptors])

    return (
        <Grid container>
            <Grid item xs={12} className={classes.banner} style={{height: 60}}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                         style={{
                             height: 55,
                             paddingTop: 16,
                             verticalAlign: "middle",
                             display: "table-cell",
                             paddingLeft: 16
                         }}>
                        <EasyBreadcrumbs breadcrumbs={breadcrumbs} current={"Spot"}/>
                    </Box>
                </Typography>


            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={8} style={{paddingLeft: 16, paddingTop: 16}}>
                <div style={{width: "50%"}}>
                    {!imageBlob ? downloadingImage :
                        spot.id && <DrawingImage imageBlob={imageBlob}
                                                 spots={[spot]}
                                                 spotsStrokes={spotBasedValues}
                                                 points={pointBasedPoints}/>}
                </div>

            </Grid>

            <Grid item xs={4}>
                <PainValues values={values}/>
                {spot.painExtent !== null &&
                <PainExtent painExtent={spot.painExtent}/>}
                <Grid container spacing={0}
                      alignItems="center">
                    <Grid item xs={12} style={{marginTop: 16}}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Anatomical areas
                        </Box>
                    </Grid>
                    {spot.areas && spot.areas.map((area) =>
                        <AnatomicalArea key={area.colorCode} name={area.name} path={area.path}/>)}

                </Grid>

            </Grid>
        </Grid>
    )
}


export default SpotDetail;