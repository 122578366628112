import React, {useContext, useEffect, useState} from 'react';
import {NavLink, Redirect, Route, Switch, useHistory, useParams} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FeedbackIcon from '@material-ui/icons/Feedback';
import './Style.css'
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import UserEntry from "./UserEntry";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AppsIcon from '@material-ui/icons/Apps';
import GroupIcon from '@material-ui/icons/Group';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import DescriptionIcon from '@material-ui/icons/Description';
import CreateIcon from '@material-ui/icons/Create';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import SettingsIcon from '@material-ui/icons/Settings';
import DetailsIcon from '@material-ui/icons/Details';
import html2canvas from "html2canvas";
import Avatar from "@material-ui/core/Avatar";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import {AppContext} from "../App";
import {fetchProjectDetails} from "../utils/apiCaller";
import checkToken from "../utils/authetnicationChecker";
import BubbleChartIcon from '@material-ui/icons/BubbleChart';

import ImportExportIcon from '@material-ui/icons/ImportExport';
import CloseIcon from '@material-ui/icons/Close';
import SupervisorRouter from "../screens/supervisor/SupervisorRouter";
import SettingsRouter from "../screens/settings/SettingsRouter";
import ProjectRouter from "../screens/project/ProjectRouter";
import ProjectSelectionDialog from "../dialogs/ProjectSelectionDialog";
import FeedBackDialog from "../dialogs/FeedBackDialog";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import LinearProgress from "@material-ui/core/LinearProgress";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import TranslateIcon from '@material-ui/icons/Translate';
import {Collapse, ListItemSecondaryAction, useTheme} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import StarsIcon from '@material-ui/icons/Stars';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import Brightness7Icon from '@material-ui/icons/Brightness7';
import Brightness4Icon from '@material-ui/icons/Brightness4';

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    content: {
        padding: theme.spacing(3),
    }, appBar: {
        zIndex: theme.zIndex.drawer + 1,
        // backgroundImage: window._env_.DEV && "repeating-linear-gradient(to bottom, transparent, transparent 5px, #fff 5px, #fff 10px)," +
        //     " repeating-linear-gradient(to right, #FF6A00, #FF6A00 5px, #fff 5px, #fff 10px)",
        background: theme.palette.appBar,
        color: "rgba(0,0,0,.66)"
    },
    drawer: {
        width: drawerWidth,
        flexGrow: 1,
        background: '#ffffff',
    },
    drawerPaper: {
        width: drawerWidth,
    },
}));

export function AppMainContent({location}) {

    const [state, dispatch] = useContext(AppContext);
    const authUser = state.user

    let drawerToUse = "PJ" // progetto

    const theme = useTheme();

    if (location.pathname.startsWith("/supervisor")) // supervisor
        drawerToUse = "SUP"
    else if (location.pathname.startsWith("/settings")) // user
        drawerToUse = "US"

    // tenere presente che project potrebbe essere nullo
    const {projectId} = useParams();

    const history = useHistory();

    const [projectDetails, setProjectDetails] = useState({});

    const [projectSelectionOpen, setProjectSelectionOpen] = useState(false);

    // stato che identifica se aprire o meno il submenu di test
    const [anchorEl, setAnchorEl] = React.useState(null);
    const uDetailOpen = Boolean(anchorEl);

    // parte per il feedback
    const [feedBackOpen, setFeedbackOpen] = React.useState(false);
    const [feedbackImage, setFeedbackImage] = React.useState("");

    const classes = useStyles();

    // carico le informazioni del progetto
    useEffect(() => {
        if (projectId && !!state.user)
            fetchProjectDetails(state.user.jwt.token, projectId)
                .then(result => setProjectDetails(result))
                .catch(() => {
                    history.push("/")
                })

    }, [state.user, projectId, history])


    const handleMenu = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // se non autenticato allora rimando al login
    if (!state.user || !checkToken(state.user.jwt))
        return <Redirect to={"/login?expired=true"}/>

    const handleFeedback = () => {
        setAnchorEl(null)

        // mando l'azione di logout
        const options = {
            foreignObjectRendering: true,
            allowTaint: true,
            useCORS: true
        }

        html2canvas(document.body, options).then(canvas => {
            const image = canvas.toDataURL('image/png', 0.5)
            setFeedbackImage(image)
            console.log(image)
            setFeedbackOpen(true)
        });
    }


    const currentTs = new Date().getTime()
    const loadingThreshold = 30000

    const contentLoading = state.loading &&
        Object.values(state.loading).filter((v) => v && currentTs - v < loadingThreshold).length > 0

    const handleNewProject = (projectId) => {
        setProjectSelectionOpen(false)
        dispatch({
            type: "PROJECT_CHOSEN",
            payload: projectId.id
        })
        history.push("/" + projectId.id)
    }

    const handleToggleDark = (c) => () => {
        dispatch({
            type: "TOGGLE_THEME",
            payload: {darkTheme: c}
        })
    }

    return (
        <div className={classes.root}>

            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6">
                        {/* imposto il valore della barra in alto */}
                        {drawerToUse === "SUP" ? "Sketch your pain" :
                            drawerToUse === "US" ? authUser.name : projectDetails.name || "Sketch your pain"
                        }
                    </Typography>
                    {drawerToUse === "PJ" &&
                        <Box ml={2}>
                            <Button size={"small"} variant={"outlined"}
                                    style={{color: theme.palette.appBarButton, borderColor: theme.palette.appBarButton}}
                                    onClick={() => setProjectSelectionOpen(true)}><i>
                                {projectDetails.name ? "Change" : "Select project"}
                            </i></Button>
                        </Box>}


                    <Grid container
                          style={{width: "25%", marginLeft: "auto"}}
                          justify="flex-end"
                          spacing={2}
                          alignItems="center">
                        <Grid item>
                            <IconButton
                                onClick={handleToggleDark(!state.darkTheme)}
                                color="inherit"
                                style={{marginLeft: "auto"}}>
                                {state.darkTheme ? <Brightness7Icon/> : <Brightness4Icon/>}
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Avatar onClick={handleMenu} style={{cursor: "pointer"}}>
                                {authUser.name.substr(0, 1).toUpperCase()}
                            </Avatar>
                        </Grid>
                    </Grid>


                </Toolbar>

            </AppBar>
            <Fade
                in={contentLoading}
                timeout={{exit: 1000}}
                style={{
                    top: 0,
                    zIndex: 3000,
                    left: 0,
                    right: 0,
                    position: "absolute",
                    transitionDelay: contentLoading ? '800ms' : '0ms',
                }}
                unmountOnExit
            >
                <LinearProgress variant={"indeterminate"}/>
            </Fade>


            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div style={{height: 60}}/>
                {/* seleziono il drawer corretto per la situazione */}
                {
                    drawerToUse === "SUP" ? <SupervisorDrawer/> :
                        drawerToUse === "US" ? <UserDrawer/> :
                            <ProjectDrawer showSelection={!projectId} location={location}/>
                }
            </Drawer>

            <Popover
                style={{zIndex: 1400 + 2} /*to compensate the height of the AppBar*/}
                open={uDetailOpen}
                anchorEl={anchorEl}
                data-html2canvas-ignore
                onClick={handleClose}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <UserEntry onFeedback={handleFeedback} userDetails={authUser}/>
            </Popover>

            <FeedBackDialog
                location={location}
                onClose={() => {
                    setFeedbackOpen(false)
                    setFeedbackImage("")
                }} open={feedBackOpen} src={feedbackImage}/>

            <Snackbar open={!!state.alert && state.alert.display}
                      autoHideDuration={state.alert ? state.alert.autoHideDuration : 2000}
                      onClose={(e, reason) => {
                          if (reason === 'clickaway') {
                              return;
                          }

                          dispatch({
                              type: "HIDE_ALERT"
                          })
                      }}

                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                      }}>
                {state.alert && <Alert severity={state.alert ? state.alert.severity : ""}>
                    <Grid container>
                        <Grid item xs={state.alert.autoHideDuration === null ? 11 : 12}>
                            {state.alert ?
                                (Array.isArray(state.alert.message) ?
                                    <ul>
                                        {state.alert.message.slice(0, 20).map(txt => <li key={txt}>{txt}</li>)}
                                        {state.alert.message.length > 20 && <li>...</li>}
                                    </ul>
                                    : state.alert.message)
                                : ""}
                        </Grid>
                        {state.alert.autoHideDuration === null &&
                            <Grid item xs={1}>

                                <IconButton size="small"
                                            onClick={() => dispatch({
                                                type: "HIDE_ALERT"
                                            })}>
                                    <CloseIcon fontSize="small"/>
                                </IconButton>

                            </Grid>
                        }
                    </Grid>

                </Alert>}
            </Snackbar>

            <ProjectSelectionDialog onSelected={(pj) => handleNewProject(pj)} open={projectSelectionOpen}
                                    onClose={() => setProjectSelectionOpen(false)}/>

            <div style={{width: "100%"}}>
                <div style={{height: 60}}/>
                <main style={{overflow: "hidden"}}>
                    <Switch>
                        <Route path="/supervisor" component={SupervisorRouter}/>
                        <Route path="/settings" component={SettingsRouter}/>
                        <Route path="/:projectId" component={ProjectRouter}/>
                    </Switch>
                </main>
            </div>
        </div>
    )
}

function ProjectDrawer({showSelection, location}) {

    const {projectId} = useParams()

    const isDefined = !!projectId && !showSelection

    const [acquisitionCollapse, setAcquisitionCollapse] = useState(true)
    const [serviceCollapse, setServiceCollapse] = useState(true)


    useEffect(() => {
        if (location.pathname.endsWith("import"))
            setAcquisitionCollapse(false)
    }, [location])


    return (
        <>
            <Typography component="div">
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{height: 60, verticalAlign: "middle", display: "table-cell", paddingLeft: 16}}>
                    Project
                </Box>
            </Typography>
            <Grid container>
                <Grid item xs={12}>
                    <List style={{padding: 0}}>
                        <Divider/>

                        <ListItem disabled={!isDefined} button exact to={"/" + projectId} component={NavLink}
                                  activeClassName="Mui-selected">
                            <ListItemIcon><DetailsIcon/></ListItemIcon>
                            <ListItemText primary={"Details"}/>
                        </ListItem>
                        <ListItem button to={"/" + projectId + "/team"} component={NavLink}
                                  disabled={!isDefined}
                                  activeClassName={isDefined ? "Mui-selected" : ""}>
                            <ListItemIcon><GroupWorkIcon/></ListItemIcon>
                            <ListItemText primary={"Team"}/>
                        </ListItem>
                        <ListItem button to={"/" + projectId + "/patient"} component={NavLink}
                                  disabled={!isDefined}
                                  activeClassName={isDefined ? "Mui-selected" : ""}>
                            <ListItemIcon><AssignmentIndIcon/></ListItemIcon>
                            <ListItemText primary={"Enrolled patients"}/>
                        </ListItem>
                        <ListItem button exact to={"/" + projectId + "/acquisition"} component={NavLink}
                                  disabled={!isDefined}
                                  activeClassName={isDefined ? "Mui-selected" : ""}>
                            <ListItemIcon><CreateIcon/></ListItemIcon>
                            <ListItemText primary={"Acquisitions"}/>
                            <ListItemSecondaryAction>
                                <IconButton disabled={!isDefined} edge="end"
                                            onClick={() => setAcquisitionCollapse(p => !p)}>
                                    {acquisitionCollapse ? <ExpandMore/> : <ExpandLess/>}
                                </IconButton>
                            </ListItemSecondaryAction>

                        </ListItem>

                        <Collapse in={!acquisitionCollapse} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{marginLeft: 10}}>
                                <ListItem disabled={!isDefined} button to={"/" + projectId + "/acquisition/import"}
                                          component={NavLink}
                                          activeClassName="Mui-selected">
                                    <ListItemIcon><ImportExportIcon/></ListItemIcon>
                                    <ListItemText primary={"Pending import"}/>
                                </ListItem>
                            </List>
                        </Collapse>
                        <ListItem button exact to={"/" + projectId + "/chart"} component={NavLink}
                                  disabled={!isDefined}
                                  activeClassName={isDefined ? "Mui-selected" : ""}>
                            <ListItemIcon><AccessibilityNewIcon/></ListItemIcon>
                            <ListItemText primary={"Body charts"}/>
                        </ListItem>
                        <ListItem button to={"/" + projectId + "/descriptor"} component={NavLink}
                                  disabled={!isDefined}
                                  activeClassName={isDefined ? "Mui-selected" : ""}>
                            <ListItemIcon><DescriptionIcon/></ListItemIcon>
                            <ListItemText primary={"Descriptors"}/>
                        </ListItem>
                        <ListItem button exact to={"/" + projectId + "/service"} component={NavLink}
                                  disabled={!isDefined}
                                  activeClassName={isDefined ? "Mui-selected" : ""}>
                            <ListItemIcon><BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={"Analysis"}/>
                            <ListItemSecondaryAction>
                                <IconButton disabled={!isDefined} edge="end"
                                            onClick={() => setServiceCollapse(p => !p)}>
                                    {serviceCollapse ? <ExpandMore/> : <ExpandLess/>}
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Collapse in={!serviceCollapse} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding style={{marginLeft: 10}}>
                                <ListItem disabled={true} button to={"/" + projectId + "/service/pinned"}
                                          component={NavLink}
                                          activeClassName="Mui-selected">
                                    <ListItemIcon><StarsIcon/></ListItemIcon>
                                    <ListItemText primary={"Pinned analysis"}/>
                                </ListItem>
                                <ListItem disabled={!isDefined} button to={"/" + projectId + "/service/jobs"}
                                          component={NavLink}
                                          activeClassName="Mui-selected">
                                    <ListItemIcon><PlaylistPlayIcon/></ListItemIcon>
                                    <ListItemText primary={"Request jobs"}/>
                                </ListItem>
                            </List>
                        </Collapse>
                        <Divider/>
                        <ListItem button exact to={"/" + projectId + "/settings"} component={NavLink}
                                  disabled={!isDefined}
                                  activeClassName={isDefined ? "Mui-selected" : ""}>
                            <ListItemIcon><SettingsIcon/></ListItemIcon>
                            <ListItemText primary={"Basic settings"}/>
                        </ListItem>
                        <Divider/>
                    </List>
                </Grid>
            </Grid>
        </>
    )
}

function UserDrawer() {
    const [state] = useContext(AppContext);
    return (
        <>
            <Typography component="div">
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{height: 60, verticalAlign: "middle", display: "table-cell", paddingLeft: 16}}>
                    User settings
                </Box>
            </Typography>
            <Grid container>
                <Grid item xs={12}>
                    <List style={{padding: 0}}>
                        <Divider/>
                        <ListItem button exact to={"/settings"} component={NavLink}
                                  activeClassName="Mui-selected">
                            <ListItemIcon><DetailsIcon/></ListItemIcon>
                            <ListItemText primary={"Basic"}/>
                        </ListItem>
                        <ListItem button exact to={"/settings/descriptor"} component={NavLink}
                                  activeClassName="Mui-selected">
                            <ListItemIcon><DescriptionIcon/></ListItemIcon>
                            <ListItemText primary={"Descriptors"}/>
                        </ListItem>
                        <Divider/>
                    </List>
                </Grid>
                <Grid item xs={12} style={{padding: 10}}>


                    <Button variant="contained" exact
                            to={state.projectId ? "/" + state.projectId : "/"} component={NavLink} fullWidth
                            color="secondary">
                        Back to project
                    </Button>


                </Grid>
            </Grid>
        </>
    )
}

function SupervisorDrawer() {
    const [state] = useContext(AppContext);
    return (
        <>
            <Typography component="div">
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 67,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16,
                         paddingTop: 7
                     }}>
                    Supervisor Console
                </Box>
            </Typography>
            <Grid
                justify={"space-between"}
                style={{height: "100%"}}
                container
                direction={"column"}
            >
                <Grid item>
                    <List style={{padding: 0}}>
                        <Divider/>
                        <ListItem button exact to={"/supervisor"} component={NavLink}
                                  activeClassName="Mui-selected">
                            <ListItemIcon><AppsIcon/></ListItemIcon>
                            <ListItemText primary={"Main"}/>
                        </ListItem>
                        <ListItem button exact to={"/supervisor/user"} component={NavLink}
                                  activeClassName="Mui-selected">
                            <ListItemIcon><GroupIcon/></ListItemIcon>
                            <ListItemText primary={"Users"}/>
                        </ListItem>
                        <ListItem button exact to={"/supervisor/project"} component={NavLink}
                                  activeClassName="Mui-selected">
                            <ListItemIcon><LibraryBooksIcon/></ListItemIcon>
                            <ListItemText primary={"Projects"}/>
                        </ListItem>
                        <ListItem button to={"/supervisor/patient"} component={NavLink}
                                  activeClassName="Mui-selected">
                            <ListItemIcon><AssignmentIndIcon/></ListItemIcon>
                            <ListItemText primary={"Patients"}/>
                        </ListItem>
                        <ListItem button to={"/supervisor/chart"} component={NavLink}
                                  activeClassName="Mui-selected">
                            <ListItemIcon><AccessibilityNewIcon/></ListItemIcon>
                            <ListItemText primary={"Body charts"}/>
                        </ListItem>
                        <ListItem button to={"/supervisor/descriptor"} component={NavLink}
                                  activeClassName="Mui-selected">
                            <ListItemIcon><DescriptionIcon/></ListItemIcon>
                            <ListItemText primary={"Global descriptors"}/>
                        </ListItem>
                        <ListItem button to={"/supervisor/service"} component={NavLink}
                                  activeClassName="Mui-selected">
                            <ListItemIcon><BubbleChartIcon/></ListItemIcon>
                            <ListItemText primary={"Analysis services"}/>
                        </ListItem>
                        <ListItem button to={"/supervisor/locale"} component={NavLink}
                                  activeClassName="Mui-selected">
                            <ListItemIcon><TranslateIcon/></ListItemIcon>
                            <ListItemText primary={"Locale settings"}/>
                        </ListItem>
                        <Divider/>
                        <ListItem button exact to={"/supervisor/feedback"} component={NavLink}
                                  activeClassName="Mui-selected">
                            <ListItemIcon><FeedbackIcon/></ListItemIcon>
                            <ListItemText primary={"Feedback list"}/>
                        </ListItem>
                        <Divider/>

                    </List>
                </Grid>
                <Grid item>
                    <List style={{paddingBottom: 0}}>
                        <Divider/>
                        <ListItem button to={state.projectId ? "/" + state.projectId : "/"} component={NavLink}
                                  activeClassName="Mui-selected">
                            <ListItemIcon><ArrowBackIosIcon/></ListItemIcon>
                            <ListItemText primary={"Exit supervisor console"}/>
                        </ListItem>
                    </List>
                </Grid>

            </Grid>
        </>
    )
}


