import React, {useCallback, useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import DrawingCard from "./DrawingCard";
import GenericValueBased from "./values/GenericValueBased";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import {fetchDrawing, postDrawingDismissError, postReprocessingRequest} from "../utils/apiCaller";
import {AppContext} from "../App";
import Skeleton from "@material-ui/lab/Skeleton";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Alert, AlertTitle} from "@material-ui/lab";


export default function DrawingImportRow({
                                             drawingId,
                                             acquisitionId,
                                             onDrawingClick,
                                             descriptors,
                                             onDrawingValueUpdate,
                                             onDrawingDiscard
                                         }) {


    const [drawing, setDrawing] = useState()
    const [loading, setLoading] = useState(true)
    const [reprocessingLoading, setReprocessingLoading] = useState(false)
    const [state, dispatch] = useContext(AppContext)

    const handleDismissAlert = () =>{
        postDrawingDismissError(state.user.jwt.token, acquisitionId, drawingId)
            .then(result => setDrawing(result))
            .catch(error =>{
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            })
    }

    const pollingDrawingFetch = useCallback((token, acquisitionId, drawingId, first = true) => {
        fetchDrawing(state.user.jwt.token, acquisitionId, drawingId)
            .then(result => {
                if (result.waitingResults) {
                    if (first) {
                        setDrawing(result)
                        setLoading(false)
                        setReprocessingLoading(false)
                    }
                    setTimeout(() => pollingDrawingFetch(token, acquisitionId, drawingId, false), 2000)
                } else {
                    setDrawing(result)
                    setLoading(false)
                    setReprocessingLoading(false)
                }
            })
            .catch(e => alert(e.message))
    }, [state.user.jwt]);

    const handleRequestReprocessing = () => {
        if (drawing.isWaitingResults || window.confirm("You will loose all the spots information related to the drawing, continue?")) {
            drawing.waitingResults = true
            setReprocessingLoading(true)
            setLoading(true)
            setDrawing(drawing)
            postReprocessingRequest(state.user.jwt.token, drawingId, acquisitionId)
                .then(() => {
                    pollingDrawingFetch(state.user.jwt.token, acquisitionId, drawingId)
                })
                .catch(result => {
                    drawing.waitingResults = false
                    drawing.processingErrorMessage = result.message

                    setReprocessingLoading(false)
                    setLoading(false)

                    setDrawing(drawing)
                    dispatch({type: "SHOW_ALERT", payload: {message: result.message, severity: "error"}})
                })
        }
    }

    useEffect(() => {
        pollingDrawingFetch(state.user.jwt.token, acquisitionId, drawingId)
    }, [drawingId, acquisitionId, state.user.jwt.token, pollingDrawingFetch])

    return (
        <Grid container spacing={3} style={{paddingLeft: 16}}>
            {drawing && drawing.processingErrorMessage && !drawing.waitingResults &&
            <Grid item xs={12}>
                <Alert severity={"warning"}
                       action={<Button onClick={handleDismissAlert} color="inherit" size="small">DISMISS</Button>}>
                    <AlertTitle>Last processing request returned an error:</AlertTitle>
                    {drawing.processingErrorMessage}
                </Alert>
            </Grid>}
            <Grid item xs={2}>
                {loading || drawing.waitingResults ?
                    <Fade in={true} style={{transitionDelay: '400ms',}} unmountOnExit>
                        <Paper>
                            <Skeleton variant="rect" animation="wave" height={250} width="100%"/>
                        </Paper>
                    </Fade>
                    :
                    <DrawingCard onClick={onDrawingClick}
                                          object={drawing}
                                          url={drawing.drawnBodyChartUrl}
                                          selectable
                                          name={drawing.id}/>
                }
            </Grid>
            <Grid item xs={10} style={{paddingLeft: 16}}>
                <Grid container spacing={3}>
                    {descriptors.map(descriptor =>
                        <Grid key={descriptor.id} item xs={4}>
                            {loading ?
                                <Fade in={true} style={{transitionDelay: '400ms',}} unmountOnExit>
                                    <Paper>
                                        <Skeleton variant="rect" animation="wave" height={100} width="100%"/>
                                    </Paper>
                                </Fade>
                                :
                                <GenericValueBased
                                    onUpdate={(id, val, color) => onDrawingValueUpdate(drawing, descriptor, val, color)}
                                    descriptor={descriptor}
                                    spots={drawing.spotDtos}
                                    value={(drawing.values[descriptor.id] || null)}
                                    chartUrl={drawing.chartLowUrl}/>
                            }
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={2} style={{marginTop: 2}}>
                <Button variant={"outlined"} fullWidth color={"secondary"}
                        onClick={() => onDrawingDiscard(drawing.id)}>
                    Discard
                </Button>
            </Grid>
            <Grid item xs={2} style={{marginTop: 2}}>

                <Button variant={"outlined"} fullWidth color={"secondary"}
                        onClick={handleRequestReprocessing}
                >
                    {reprocessingLoading && <CircularProgress color={"secondary"} size={25}/> }
                     Reprocessing
                </Button>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 16}}>
                <Divider/>
            </Grid>
        </Grid>
    )
}
