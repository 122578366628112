import React from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

export default function DateEntry({
                                      editable,
                                      onEdit,
                                      label,
                                      disableFuture = false,
                                      value,
                                      visible = true,
                                      divider = true
                                  }) {


    const handleValueChange = (e) => {
        if (e)
            value = e.getTime() / 1000
        else
            value = null
        if (onEdit !== null)
            onEdit(value)
    }

    const formatDate = () => {
        if (!value)
            return ""
        const date = new Date(value * 1000);
        let yyyy = date.getFullYear();
        let dd = date.getDate();
        let mm = date.getMonth() + 1;
        if (dd < 10)
            dd = '0' + dd;

        if (mm < 10)
            mm = '0' + mm;

        return dd + '/' + mm + '/' + yyyy;
    }

    if (!visible)
        return <></>

    return (
        <>
            <Grid item xs={5}>
                <Typography component="div">
                    <Box style={{margin: 8}} fontSize="h6.fontSize" fontWeight="fontWeightLight">
                        {label}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                {
                    editable ?
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                disableFuture
                                clearable
                                ampm={false}
                                value={value ? new Date(value * 1000) : null}
                                onChange={(e) => {
                                    handleValueChange(e)
                                }}
                                fullWidth format="dd/MM/yyyy"
                            />
                        </MuiPickersUtilsProvider>
                        // <TextField
                        //     onChange={(event) => handleValueChange(event.target.value)}
                        //     autoFocus
                        //     InputProps={{ inputProps: { min: 0 } }}
                        //     type={inputMode}
                        //     value={value}
                        //     fullWidth
                        // />
                        :
                        <Typography component="div">
                            <Box style={{margin: 8}} fontSize="h6.fontSize" fontWeight="fontWeightLight">
                                {formatDate()}
                            </Box>
                        </Typography>

                }
            </Grid>
            {divider ?
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                : ""}

        </>
    )

}