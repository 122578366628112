import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SaveIcon from '@material-ui/icons/Save';
import BodyChartStep4 from "./BodyChartStep4";
import BodyChartStep1 from "./BodyChartStep1";
import BodyChartFileUpload from "../BodyChartFileUpload";
import BodyChartStep6 from "./BodyChartStep6";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from '@material-ui/core/CircularProgress';


export default function NewBodyChartDialog(props) {
    const open = props.open
    const onClose = props.onClose
    const onSave = props.onSave
    const [step, setStep] = useState(0);

    const [selectedBase, setSelectedBase] = useState();
    const [selectedPaper, setSelectedPaper] = useState();
    const [selectedMask, setSelectedMask] = useState();
    const [selectedMap, setSelectedMap] = useState();
    const [selectedRegionDescription, setSelectedRegionDescription] = useState();
    const [selectedName, setSelectedName] = useState();
    const [isFemale, setIsFemale] = useState(false);

    const [nextEnabled, setNextEnabled] = useState(true);
    const [backEnabled, setBackEnabled] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);
    const [saving, setSaving] = useState(false);
    const [skipEnabled, setSkipEnabled] = useState(false);
    const [skipped, setSkipped] = React.useState(new Set());

    const steps = ['Introduction', 'Base', 'Paper', 'Regions', 'Mask', 'End'];

    const handleNext = () => {
        setStep(step + 1);
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleSave = () => {
        setSaving(true)
        // costruisco il payload finale
        const payload = {
            digital: selectedBase,
            paper: selectedPaper,
            mask: selectedMask,
            name: selectedName,
            isFemale: isFemale,
            mapDescription: selectedRegionDescription,
            map: selectedMap
        }

        // lo mando al parent
        onSave(payload)
    }


    const isStepOptional = (step) => {
        return step === 3;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleSkip = () => {
        // non dovrebbe mai succedere, ma sa sa mai
        if (!isStepOptional(step))
            throw new Error("You can't skip a step that isn't optional.");
        // aggiungo lo step nella lista di quelli skippati
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(step);
            return newSkipped;
        });
        // passo alla prossima pagina
        handleNext()
    };

    const handleClose = () => {
        onClose()
        setStep(0)
        setSelectedName(undefined)
        setSelectedBase(undefined)
        setSelectedMask(undefined)
        setSelectedPaper(undefined)
        setSaving(false)
    }

    useEffect(() => {
        setBackEnabled(step > 0)
        switch (step) {
            case 0:
                setNextEnabled(true)
                setSkipEnabled(false)
                break
            case 1:
                setSkipEnabled(false)
                if (selectedBase)
                    setNextEnabled(true)
                else setNextEnabled(false)
                break
            case 2:
                setSkipEnabled(false)
                if (selectedPaper)
                    setNextEnabled(true)
                else setNextEnabled(false)
                break
            case 3:
                setSkipEnabled(true)
                if (selectedRegionDescription && selectedMap)
                    setNextEnabled(true)
                else setNextEnabled(false)
                break
            case 4:
                setSkipEnabled(false)
                if (selectedMask)
                    setNextEnabled(true)
                else setNextEnabled(false)
                break
            case 5:
                setSkipEnabled(false)
                setNextEnabled(false)
                if (selectedName)
                    setSaveEnabled(true)
                else
                    setSaveEnabled(false)
                break
            default:
                break

        }


    }, [step, selectedBase, selectedName, selectedPaper, selectedMask, selectedRegionDescription, selectedMap])

    return (
        <Dialog maxWidth={"md"} open={open} onClose={handleClose}>
            <form>
                <DialogTitle>Creation wizard</DialogTitle>
                <DialogContent>

                    <Grid container>

                        <Grid item xs={12} style={{paddingBottom: 32}}>
                            <Stepper activeStep={step} style={{padding: 0}}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    if (isStepOptional(index))
                                        labelProps.optional = <Typography variant="caption">Optional</Typography>;
                                    if (isStepSkipped(index))
                                        stepProps.completed = false;

                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Grid>

                        <Grid item xs={12}>

                            <Box hidden={step !== 0}>
                                <BodyChartStep1/>
                            </Box>
                            <Box hidden={step !== 1}>
                                <BodyChartFileUpload text={"Digital body chart"} onChange={setSelectedBase}/>
                            </Box>
                            <Box hidden={step !== 2}>
                                <BodyChartFileUpload text={"Paper body chart"} onChange={setSelectedPaper}/>
                            </Box>
                            <Box hidden={step !== 3}>
                                <BodyChartStep4 onCsvSelected={setSelectedRegionDescription} onImageSelected={setSelectedMap}/>
                            </Box>
                            <Box hidden={step !== 4}>
                                <BodyChartFileUpload text={"Mask"} onChange={setSelectedMask}/>
                            </Box>
                            <Box hidden={step !== 5}>
                                <BodyChartStep6 onChange={setSelectedName} onFemaleChange={setIsFemale}/>
                            </Box>

                        </Grid>
                        <Grid item xs={4}>
                            {backEnabled && !saving ?
                                <IconButton color={"secondary"} onClick={handleBack}><ArrowBackIcon/></IconButton> : ""}
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "center"}}>
                            {saveEnabled ?
                                saving? <CircularProgress color={"secondary"} /> :
                                <IconButton color={"secondary"} onClick={handleSave}><SaveIcon/></IconButton> : ""}
                            {skipEnabled ?
                                <Button variant={"outlined"} color={"secondary"}
                                        onClick={handleSkip}>Skip</Button> : ""}
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "right"}}>
                            {nextEnabled ? <IconButton color={"secondary"}
                                                       onClick={handleNext}><ArrowForwardIcon/></IconButton> : ""}
                        </Grid>
                    </Grid>


                </DialogContent>
            </form>
        </Dialog>
    )
}