import React, {useState} from 'react';

import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from '@material-ui/icons/Refresh';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {getSimplePassword} from "../utils/simplePasswordGenerator";

export default function NewUserDialog({open, onClose, onSave}) {
    const [payload, setPayload] = useState({password: ""});
    const [password, setPassword] = useState(getSimplePassword());

    // stati per il controllo errori

    const [nameCheck, setNameCheck] = useState({error: false, text: ""});
    const [surnameCheck, setSurnameCheck] = useState({error: false, text: ""});
    const [emailCheck, setEmailCheck] = useState({error: false, text: ""});

    const handleUpdate = (prop, value) => {
        setPayload((old) => {
            old[prop] = value;
            return old
        })
    }

    const handleSave = () => {
        payload.password = password

        // faccio un po di controlli qua e la

        let error = false;

        // nome
        if (payload.name === "" || payload.name === undefined) {
            setNameCheck({error: true, text: "Please, enter a name"})
            error = true
        } else
            setNameCheck({error: false, text: ""})
        // cognome
        if (payload.surname === "" || payload.surname === undefined) {
            setSurnameCheck({error: true, text: "Please, enter a surname"})
            error = true
        } else
            setSurnameCheck({error: false, text: ""})
        // email
        if (payload.email === "" || payload.email === undefined) {
            setEmailCheck({error: true, text: "Please, enter an username"})
            error = true
        } else if (!payload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setEmailCheck({error: true, text: "Please, enter a valid email"})
        } else
            setEmailCheck({error: false, text: ""})



        if (!error)
            onSave(payload)
    }

    const handleClose = () => {
        onClose()
        setPayload(undefined)
    }


    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">New user</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}
                          style={{
                              margin: 0,
                              width: '100%',
                          }}
                    >
                        <Grid item xs={12}>
                            <TextField onChange={event => handleUpdate("name", event.target.value)} fullWidth
                                       error={nameCheck.error} helperText={nameCheck.text}  label={"Name"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={event => handleUpdate("surname", event.target.value)} fullWidth
                                       error={surnameCheck.error} helperText={surnameCheck.text} label={"Surname"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={event => handleUpdate("email", event.target.value)} type={"email"}
                                       error={emailCheck.error} helperText={emailCheck.text} fullWidth label={"Email"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Password"
                                value={password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton edge="end"
                                                        onClick={() => setPassword(getSimplePassword())}>
                                                <RefreshIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Create
                    </Button>
                </DialogActions>
        </Dialog>
    )
}