import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import {AppContext} from "../../../App";
import AdvancedTable from "../../../components/AdvancedTable";
import {fetchAllProjectBodyCharts, fetchGlobalBodyChartsPage, postProjectBcUpdate} from "../../../utils/apiCaller";
import AuthImg from "../../../components/AuthImg";
import {useParams} from "react-router-dom";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {Typography} from "@material-ui/core";

const tableColumns = [
    {key: "name", name: "Name"},
    {key: "isFemale", name: "Female", boolean: true}
]


export default function BodyChartMultipleSelectionDialog({
                                                             open, onClose, onSaved = () => {
    }
                                                         }) {
    const [state, dispatch] = useContext(AppContext)
    const jwt = state.user.jwt

    const {projectId} = useParams()

    const [filterName, setFilterName] = useState("");
    const [lastSelectedChart, setLastSelectedChart] = useState();
    const [projectBcs, setProjectBcs] = useState([]);

    const [addEnabled, setAddEnabled] = useState(false);
    const [removeEnabled, setRemoveEnabled] = useState(false);

    const handleSave = () => {
        // ora ho le bc in projectBcs :)

        const payload = projectBcs.map(pdc => {
            return {id: pdc.id}
        });

        postProjectBcUpdate(jwt.token, projectId, payload)
            .then(() => {
                dispatch({type: "SHOW_ALERT", payload: {message: "Request completed"}})
                onSaved()
            })
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )
    }

    useEffect(() => {
        fetchAllProjectBodyCharts(jwt.token, projectId)
            .then(result => setProjectBcs(result))
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )
    }, [jwt, projectId, dispatch])

    const handleSelected = (chart) => {
        setLastSelectedChart(chart)
    }

    const handleProjectBcSelected = (chart) => {
        setLastSelectedChart(chart)
    }

    useEffect(() => {
        if (!lastSelectedChart)
            return

        if (projectBcs.filter(c => c.id === lastSelectedChart.id).length === 0) {
            setAddEnabled(true)
            setRemoveEnabled(false)
        } else {
            setRemoveEnabled(true)
            setAddEnabled(false)
        }
    }, [lastSelectedChart, projectBcs])


    const handleRemove = () => {
        setProjectBcs(projectBcs.filter(c => c.id !== lastSelectedChart.id))
    }


    const handleAdd = () => {
        setProjectBcs([lastSelectedChart].concat(projectBcs))
    }

    return (
        <Dialog maxWidth={"lg"} fullWidth open={open} onClose={onClose}>
            <DialogTitle>
                Select the body charts
            </DialogTitle>
            <DialogContent style={{paddingRight: 0, paddingLeft: 0}}>
                <Grid container spacing={2} style={{width: "100%", paddingRight: 20, paddingLeft: 20}}>
                    <Grid item xs={12}
                    >
                        <TextField
                            onChange={(e) => setFilterName(e.target.value)}
                            size={"small"}
                            label="Search by name"
                            variant={"outlined"}
                            fullWidth
                            InputProps={{
                                startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}><Typography variant={"h6"}>Preview</Typography></Grid>
                    <Grid item xs={4}><Typography variant={"h6"}>All</Typography></Grid>
                    <Grid item xs={2}/>
                    <Grid item xs={4}><Typography variant={"h6"}>Selected</Typography></Grid>
                    <Grid item xs={2}>
                        <AuthImg
                            style={{width: "100%"}}
                            src={lastSelectedChart ? lastSelectedChart.digitalThumbnailUrl : undefined}/>
                    </Grid>
                    <Grid item xs={4}>
                        <AdvancedTable
                            tableProps={{size: "small"}}
                            jwt={state.user.jwt.token}
                            fields={tableColumns}
                            filterName={filterName}
                            highlightedIds={lastSelectedChart ? [lastSelectedChart.id] : []}
                            fetchingPageFunction={fetchGlobalBodyChartsPage}
                            onSelected={handleSelected}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Grid
                            container
                            justify="center"
                            alignItems="center"
                            style={{height: "100%"}}
                        >
                            <Grid item xs={12}>
                                <Button fullWidth disabled={!addEnabled} onClick={handleAdd}
                                        variant="contained" startIcon={<ArrowForwardIosIcon/>}>
                                    Add
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Button disabled={!removeEnabled} fullWidth variant="contained" onClick={handleRemove}
                                        color="secondary" startIcon={<ArrowBackIosIcon/>}>
                                    Remove
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <TableContainer>
                            <Table size={"small"}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Female</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {projectBcs.map(row => (
                                        <TableRow hover key={row.id} style={{cursor: "pointer"}}
                                                  selected={lastSelectedChart && lastSelectedChart.id === row.id}
                                                  onClick={() => handleProjectBcSelected(row)}>

                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{row.isFemale ? "Yes" : "No"}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary">
                    Close
                </Button>
                <Button
                    onClick={handleSave}
                    variant={"outlined"}
                    color="primary">
                    Confirm selection
                </Button>
            </DialogActions>
        </Dialog>
    )
}
