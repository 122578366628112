// eslint-disable-next-line no-unused-vars
import {API, SERVER} from "../constants";

const blobCache = {}

// methodo per generalizzare una richiesta https
const performCall = (path, headers, method, body, propertyCheck, ignoreResult = false) => {

    if (body !== null) {
        body = JSON.stringify(body)
    }

    // ritorno una promise
    return new Promise((resolve, reject) => {

        fetch(SERVER + path, {
            headers: headers,
            body: body,
            method: method
        })
            .then(response => {
                    // se ho ottenuto una risposta positiva allora controllo che ci sia quello che mi serve e lo ritorno
                    if (response.status < 400) {
                        // converto il risultato in oggetto js (json)
                        if (!ignoreResult)
                            response.json().then(json => {
                                if (!propertyCheck || json.hasOwnProperty(propertyCheck))
                                    resolve(json)
                                else
                                    reject({message: "Missing prop " + propertyCheck})
                            })
                        else resolve()
                        // altrimenti passo da qui e ritorno errore
                    } else {
                        response.json()
                            .then(json => {
                                console.log(json)
                                reject(json)
                            })
                            .catch(() => reject({message: "error unknown"}))
                    }
                }
            )
            .catch(() => reject({message: "Server unavailable"}))
    })
}

export const authenticate = (email, password) => {
    return performCall(
        "/authenticate",
        {'Content-Type': 'application/json'},
        "POST",
        {email: email, password: password},
        "token"
    )
}

export const fetchAuthUserDetail = (token) => {
    return performCall(
        API + "/user",
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "email"
    )
}

export const fetchDescriptorDetail = (token, id) => {
    return performCall(
        API + "/descriptor/" + id,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "kind"
    )
}

export const fetchServiceDetail = (token, id) => {
    return performCall(
        API + "/analysis/service/" + id,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "id"
    )
}

export const fetchLocaleDetail = (token, id) => {
    return performCall(
        API + "/locale/" + id,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "id"
    )
}

export const fetchPrimaryLocale = (token) => {
    return performCall(
        API + "/locale",
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "id"
    )
}

export const fetchChartDetail = (token, id) => {
    return performCall(
        API + "/chart/" + id,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "id"
    )
}

export const fetchServiceRequestFull = (token, id) => {
    return performCall(
        API + "/analysis/result/" + id,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "id"
    )
}

export const fetchDrawing = (token, acquisitionId, drawingId) => {
    return performCall(
        API + "/acquisition/" + acquisitionId + "/" + drawingId,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "id"
    )

}

export const fetchSpot = (token, acquisitionId, drawingId, spotId) => {
    return performCall(
        API + "/acquisition/" + acquisitionId + "/" + drawingId + "/" + spotId,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "id"
    )

}

export const generateMassiveImport = (token, id, copies, dir = false) => {
    return performCall(
        API + "/project/" + id + "/massive?copies=" + copies + "&dir=" + dir,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        null
    )
}

export const generateManualPatientSheet = (token, patientId, projectId) => {
    return performCall(
        API + "/patient/" + patientId + "/manual?project=" + projectId,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        null
    )
}

export const fetchPatientDetail = (token, id) => {
    return performCall(
        API + "/patient/" + id,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "id"
    )
}

export const fetchFeedbackDetail = (token, id) => {
    return performCall(
        API + "/feedback/" + id,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "url"
    )
}

export const fetchUserDetail = (token, id) => {
    return performCall(
        API + "/user/" + id,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "id"
    )
}

export const fetchAllAssignedProjectsPage = ({userId, jwt, page, size, sortDir, sortField, search = ""}) => {
    return performCall(
        API + "/user/" + userId + `/projects?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {'Authorization': 'Bearer ' + jwt},
        "GET"
    )
}

export const fetchAllProjectPatients = ({projectId, jwt, page, size, sortDir, sortField, search = ""}) => {

    return performCall(
        API + "/project/" + projectId + `/patients?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {'Authorization': 'Bearer ' + jwt},
        "GET",
        null,
        "totalElements"
    )
}

export const fetchAllServicesPage = ({jwt, page, size, sortDir, sortField, search = ""}) => {

    return performCall(
        API + `/analysis/service/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {'Authorization': 'Bearer ' + jwt},
        "GET",
        null,
        "totalElements"
    )
}

export const fetchAllLocalesPage = ({jwt, page, size, sortDir, sortField, search = ""}) => {

    return performCall(
        API + `/locale/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {'Authorization': 'Bearer ' + jwt},
        "GET",
        null,
        "totalElements"
    )
}

export const fetchAllProjectBodyCharts = (token, id) => {
    return performCall(
        API + "/project/" + id + "/bodyChart/list",
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        null
    )
}

export const fetchAllAcquisitionDrawings = (token, id) => {
    return performCall(
        API + "/acquisition/" + id + "/drawings",
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        null
    )
}

export const fetchAllSelectableBodyCharts = (token, id, filter, pageIndex, pageSize) => {
    return performCall(
        API + `/project/${id}/bodyChart/selectable?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "content"
    )
}

export const fetchAllSelectableDescriptors = (token, id, filter, pageIndex, pageSize) => {
    return performCall(
        API + `/project/${id}/descriptors/selectable?pageSize=${pageSize}&pageIndex=${pageIndex}&filter=${filter}`,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "content"
    )
}

export const fetchProjectTeam = (token, id) => {
    return performCall(
        API + "/project/" + id + "/team/list",
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "leaders"
    )
}

export const fetchProjectLeaderPermission = (token, id) => {
    return performCall(
        API + "/project/" + id + "/team/permissions/leader",
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        null
    )
}

export const fetchProjectSettings = (token, id) => {
    return performCall(
        API + "/project/" + id + "/settings",
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        null
    )
}

export const fetchAllLocales = (token) => {
    return performCall(
        API + "/locale/list",
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        null
    )
}

export const fetchAllServices = (token) => {
    return performCall(
        API + "/analysis/service/list",
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        null
    )
}

export const fetchAllRequests = (token, projectId) => {
    return performCall(
        API + "/analysis/request/list?projectId=" + projectId,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        null
    )
}

export const fetchAllRequestsPage = ({jwt, page, size, sortDir, sortField, projectId}) => {
    return performCall(
        API + `/analysis/request/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&projectId=${projectId}`,
        {'Authorization': 'Bearer ' + jwt},
        "GET",
        null,
        null
    )
}


export const fetchProjectOperatorPermission = (token, id) => {
    return performCall(
        API + "/project/" + id + "/team/permissions/operator",
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "canSeeAcquisitionHistory"
    )
}

export const fetchAllPatients = ({jwt, page, size, sortDir, sortField, search = ""}) => {
    return performCall(
        API + `/patient/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {'Authorization': 'Bearer ' + jwt},
        "GET",
        null,
        "totalElements"
    )
}

export const fetchAllProjectDescriptors = ({projectId, cat, jwt, page, size, sortDir, sortField, search = ""}) => {
    return performCall(
        API + "/project/" + projectId + `/descriptor/list?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}&cat=${cat}`,
        {'Authorization': 'Bearer ' + jwt},
        "GET",
        null,
        null
    )
}

export const fetchProjectsPage = ({jwt, page, size, sortDir, sortField, search = "", disabled = false}) => {
    return performCall(
        API + `/project/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}&disabled=${disabled}`,
        {'Authorization': 'Bearer ' + jwt},
        "GET",
        null,
        "content"
    )
}

export const fetchGlobalBodyChartsPage = ({jwt, search = "", page, size, sex}) => {

    let path = `/chart/list/global?pageSize=${size}&pageIndex=${page}&name=${search}`

    if (sex !== undefined)
        path += "&sex=" + sex

    return performCall(
        API + path,
        {'Authorization': 'Bearer ' + jwt},
        "GET",
        null,
        "content"
    )
}

export const fetchAcquisitionsPage = ({projectId, jwt, page, size, sortDir, sortField, search = ""}) => {

    return performCall(
        API + `/project/${projectId}/acquisitions?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {
            'Authorization': 'Bearer ' + jwt
        },
        "get"
    )
}

export const fetchAcquisitionsNotCompletedPage = ({
                                                      projectId,
                                                      sessions,
                                                      jwt,
                                                      page,
                                                      size,
                                                      sortDir,
                                                      sortField,
                                                      search = ""
                                                  }) => {

    return performCall(
        API + `/project/${projectId}/acquisitions/imported?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}&sessions=${sessions}`,
        {
            'Authorization': 'Bearer ' + jwt
        },
        "get"
    )
}

export const fetchPatientProjectAcquisitions = ({
                                                    projectId,
                                                    patientId,
                                                    jwt,
                                                    page,
                                                    size,
                                                    sortDir,
                                                    sortField,
                                                    search = ""
                                                }) => {


    return performCall(
        API + `/patient/${patientId}/acquisitions/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}&projectId=${projectId}`,
        {
            'Authorization': 'Bearer ' + jwt
        },
        "get"
    )
}

export const fetchUsersPage = ({jwt, page, size, sortDir, sortField, search = ""}) => {
    return performCall(
        API + `/user/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {'Authorization': 'Bearer ' + jwt},
        "GET",
        null,
        "content"
    )
}

export const fetchGlobalDescriptorsPage = ({jwt, page, size, sortDir, sortField, search = ""}) => {
    return performCall(
        API + `/descriptor/page/global?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {'Authorization': 'Bearer ' + jwt},
        "GET",
        null,
        "content"
    )
}

export const fetchPrivateDescriptorsPage = ({jwt, page, size, sortDir, sortField, search = ""}) => {
    return performCall(
        API + `/descriptor/page/private?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {'Authorization': 'Bearer ' + jwt},
        "GET",
        null,
        "content"
    )
}

export const fetchFeedbackPage = ({jwt, page, size, sortDir, sortField, search = ""}) => {
    return performCall(
        API + `/feedback/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}&q=${search}`,
        {'Authorization': 'Bearer ' + jwt},
        "GET",
        null,
        "content"
    )
}

export const fetchProjectDetails = (token, id) => {
    return performCall(
        API + `/project/${id}`,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "id"
    )
}

export const fetchProjectDescriptors = (token, id) => {
    return performCall(
        API + `/project/${id}/descriptors`,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "drawingDescriptors"
    )
}

export const fetchProjectAdditionalInfoList = (token, id) => {
    return performCall(
        API + `/project/${id}/additional-info`,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        null
    )
}

export const fetchProjectAdditionalInfo = (token, projectId, aipId) => {
    return performCall(
        API + `/project/${projectId}/additional-info/${aipId}`,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        null
    )
}

export const fetchProjectAdditionalInfoPage = ({jwt, page, size, sortDir, sortField, projectId}) => {
    return performCall(
        API + `/project/${projectId}/additional-info/page?size=${size}&page=${page}&sort=${sortField}&dir=${sortDir}`,
        {'Authorization': 'Bearer ' + jwt},
        "GET",
        null,
        null
    )
}

export const fetchAcquisitionDetail = (token, id) => {
    return performCall(
        API + `/acquisition/${id}`,
        {'Authorization': 'Bearer ' + token},
        "GET",
        null,
        "id"
    )
}

export const updateDrawingValue = (token, acquisitionId, drawingId, descriptorId, value, color = "") => {

    color = color.replaceAll("#", "%23")

    return performCall(
        API + "/acquisition/" + acquisitionId + "/" + drawingId + "/value?descriptorId=" + descriptorId + "&value=" + value + "&color=" + color,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        null,
        null
    )

}

export const updateAcquisitionValue = (token, acquisitionId, descriptorId, value) => {

    return performCall(
        API + "/acquisition/" + acquisitionId + "/value?descriptorId=" + descriptorId + "&value=" + value,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        null,
        null
    )
}

export const updateAcquisition = (token, payload) => {

    return performCall(
        API + "/acquisition",
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        null,
        false
    )

}


export const updateBcInfo = (token, payload) => {

    return performCall(
        API + "/chart",
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        null,
        false
    )

}

export const updatePatient = (token, payload, projectId) => {

    return performCall(
        API + `/patient?projectId=${projectId}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        null
    )

}

export const updateLocaleTranslations = (token, payload) => {

    return performCall(
        API + "/locale",
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        null,
        false
    )

}

export const updateLeaderPermission = (token, payload, projectId) => {

    return performCall(
        API + "/project/" + projectId + "/team/permissions/leader",
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        null
    )

}

export const updateProjectSettings = (token, payload, projectId) => {

    return performCall(
        API + "/project/" + projectId + "/settings",
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        null
    )

}

export const updateOperatorPermission = (token, payload, projectId) => {

    return performCall(
        API + "/project/" + projectId + "/team/permissions/operator",
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        null
    )

}

export const putNewProject = (token, payload) => {
    return performCall(
        API + `/project`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        payload,
        null
    )
}

export const postProjectUpdate = (token, payload) => {
    return performCall(
        API + `/project`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        null
    )
}

export const putNewUser = (token, payload) => {
    return performCall(
        API + `/user`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        payload,
        "id"
    )
}

export const putNewAdditionalInfo = (token, id, payload) => {
    return performCall(
        API + `/project/${id}/additional-info`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        payload,
        "id"
    )
}

export const postAdditionalInfo = (token, id, payload) => {
    return performCall(
        API + `/project/${id}/additional-info`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        "id"
    )
}

export const postDisableAdditionalInfo = (token, projectId, id) => {
    return performCall(
        API + `/project/${projectId}/additional-info/toggle-disabled?id=${id}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        null,
        "id"
    )
}

export const putNewChart = (token, payload, global, privateChart) => {
    return performCall(
        API + `/chart?global=${global}&privateChart=${privateChart}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        payload,
        "id"
    )
}

export const putNewLocale = (token, payload) => {
    return performCall(
        API + `/locale?name=${payload}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        null,
        "id"
    )
}

export const putNewFeedback = (token, payload) => {
    return performCall(
        API + `/feedback`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        payload,
        "id"
    )
}

export const putNewPatient = (token, payload) => {

    return performCall(
        API + "/patient",
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        payload,
        null
    )

}

export const putNewDescriptor = (token, payload) => {
    return performCall(
        API + `/descriptor`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        payload,
        "kind"
    )
}

export const putNewService = (token, payload) => {
    return performCall(
        API + `/analysis/service`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        payload,
        "id"
    )
}

export const postDescriptorUpdate = (token, payload) => {
    return performCall(
        API + `/descriptor`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        "kind"
    )
}

export const postProjectBcUpdate = (token, id, payload) => {
    return performCall(
        API + "/project/" + id + "/bodyChart",
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        null
    )
}

export const postProjectBcMove = (token, id, bcId, dir) => {
    return performCall(
        API + "/project/" + id + "/bodyChart/" + bcId + "/move?dir=" + dir,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        null,
        null
    )
}

export const postNewAnalysisRequest = (token, payload) => {
    return performCall(
        API + "/analysis/request",
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        null
    )
}

export const postServiceUpdate = (token, payload) => {
    return performCall(
        API + `/analysis/service`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        "id"
    )
}

export const postDrawingDismissError = (token, acqId, drawId) => {
    return performCall(
        API + `/acquisition/${acqId}/${drawId}/dismissError`,
        {'Authorization': 'Bearer ' + token},
        "POST",
        null,
        "id"
    )
}

export const postUserUpdate = (token, payload) => {
    return performCall(
        API + `/user`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        "id"
    )
}

export const postResetUnapprovedDrawing = (token, acquisitionId, drawingId) => {
    return performCall(
        API + `/acquisition/${acquisitionId}/${drawingId}/reset`,
        {
            'Authorization': 'Bearer ' + token
        },
        "POST",
        null,
        null,
        true
    )
}

export const postReprocessingRequest = (token, drawingId, acquisitionId) => {
    return performCall(
        API + `/acquisition/${acquisitionId}/${drawingId}/reprocess`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        null,
        null,
        true
    )
}

export const postBodychartDisabledFlag = (token, id, flag) => {
    return performCall(
        API + `/chart/${id}/disable?flag=${flag}`,
        {
            'Authorization': 'Bearer ' + token
        },
        "POST",
        null,
        null,
        true
    )
}

export const putBcToProject = (token, id, bc) => {
    return performCall(
        API + `/project/${id}/bodyChart/${bc}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        null,
        "result"
    )
}

export const putPdToProject = (token, id, pd, kind) => {
    return performCall(
        API + `/project/${id}/descriptors/${kind}/${pd}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        null,
        "result"
    )
}

export const putUserToProject = (token, id, mail, admin) => {
    return performCall(
        API + `/project/${id}/team?email=${mail}&admin=${admin}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        null,
        "result"
    )
}

export const putMassiveImport = (token, data, projectId) => {
    return performCall(
        API + `/project/${projectId}/paperImport`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        data,
        null
    )
}

export const putCropBc = (token, bcId, data) => {
    return performCall(
        API + `/chart/${bcId}/crop`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        data,
        null
    )
}

export const putPreviewCropBc = (token, bcId, data) => {
    return performCall(
        API + `/chart/${bcId}/crop/preview`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "PUT",
        data,
        null
    )
}

export const removeUserFromProject = (token, id, userId) => {
    return performCall(
        API + `/project/${id}/team?userId=${userId}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "DELETE",
        null,
        "result"
    )
}

export const deleteDrawing = (token, acquisitionId, drawingId) => {
    return performCall(
        API + `/acquisition/${acquisitionId}/${drawingId}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "DELETE",
        null,
        null,
        true
    )
}

export const deleteAcquisition = (token, acquisitionId) => {
    return performCall(
        API + `/acquisition/${acquisitionId}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "DELETE",
        null,
        null,
        true
    )
}

export const postUserSwitchRoleProject = (token, id, userId) => {
    return performCall(
        API + `/project/${id}/team/switch?userId=${userId}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        null,
        "result"
    )
}

export const removeBcFromProject = (token, id, bc) => {
    return performCall(
        API + `/project/${id}/bodyChart/${bc}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "DELETE",
        null,
        "result"
    )
}

export const removePdFromProject = (token, id, pd, kind) => {
    return performCall(
        API + `/project/${id}/descriptors/${kind}/${pd}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "DELETE",
        null,
        "result"
    )
}

export const toggleMandatoryPdFromProject = (token, id, pd, kind) => {
    return performCall(
        API + `/project/${id}/descriptor/toggle?descriptorId=${pd}&cat=${kind}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        null,
        null,
        true
    )
}

export const updateProject = (token, payload) => {
    return performCall(
        API + `/project`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "POST",
        payload,
        null,
        false
    )
}

export const rebasePatients = (token, projectId) => {
    return performCall(
        API + `/project/${projectId}/patients/rebase`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "GET",
        null,
        null,
        true
    )
}

export const checkPatientExists = (token, patientId) => {


    return performCall(
        API + `/patient/${patientId}/exists`,
        {
            'Authorization': 'Bearer ' + token
        },
        "get",
        null,
        null,
        true
    )
}

export const removeAcquisition = (token, id) => {
    return performCall(
        API + `/acquisition/${id}`,
        {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        "DELETE",
        null,
        null,
        true
    )
}

export const getBase64 = (token, endpoint) => {

    // ritorno una promise
    return new Promise((resolve, reject) => {
        fetch(SERVER + endpoint, {
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'GET'
        })
            .then(response => {

                    // se ho ottenuto una risposta positiva allora controllo che ci sia quello che mi serve e lo ritorno
                    if (response.status < 400)
                        // converto il risultato in testo
                        response.json().then(json => {
                            if (json.hasOwnProperty("data"))
                                resolve(json)
                            else
                                reject("Invalid image")
                        })
                    // altrimenti passo da qui e ritorno errore
                    else
                        reject(response.status)
                }
            )
    })
}

export const fetchBlob = (token, subUrl, cache = true) => {

    // ritorno una promise
    return new Promise((resolve, reject) => {

        if (!!blobCache[subUrl] && cache) {
            console.log("loading blob from cache")
            resolve(blobCache[subUrl])
        } else {
            //console.log("loading blob from server")
            fetch(SERVER + subUrl, {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                method: 'GET'
            })
                .then(response => {

                        // se ho ottenuto una risposta positiva allora controllo che ci sia quello che mi serve e lo ritorno
                        if (response.status < 400)
                            // converto il risultato in testo
                            response.blob().then(blob => {
                                blob = URL.createObjectURL(blob)
                                blobCache[subUrl] = blob
                                resolve(blob)
                            })
                        // altrimenti passo da qui e ritorno errore
                        else
                            reject(response.status)
                    }
                )
        }
    })
}



