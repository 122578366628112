import React, {useContext, useEffect, useState} from "react";

import OptionBased from "./OptionBased";
import RangeBased from "./RangeBased";
import SpotBased from "./SpotBased";
import PointBased from "./PointBased";
import {fetchBlob} from "../../utils/apiCaller";
import {AppContext} from "../../App";

export default function GenericValueBased({descriptor, onUpdate, value, chartUrl, spots = []}) {
    const [state] = useContext(AppContext)
    const [image, setImage] = useState();

    // scarico l'immagine, se c'è
    useEffect(() => {

        // controllo sui casi che non necessitano di un'immagine
        if (chartUrl === undefined || state.user.jwt === undefined)
            return
        if (descriptor.kind === "RangeBasedDescriptor" ||
            descriptor.kind === "OptionBasedDescriptor"
        )
            return

        fetchBlob(state.user.jwt.token, chartUrl)
            // metto l'immagine nello stato
            .then(i => setImage(i))

    }, [state.user.jwt, chartUrl, descriptor.kind])


    switch (descriptor.kind) {
        case "RangeBasedDescriptor":
            return <RangeBased onUpdate={onUpdate} value={value} descriptor={descriptor}/>
        case "OptionBasedDescriptor":
            return <OptionBased onUpdate={onUpdate} value={value} descriptor={descriptor}/>
        case "SpotBasedDescriptor":
            return <SpotBased onUpdate={onUpdate} value={value} descriptor={descriptor} imageBlob={image}
                              spots={spots}/>
        case "PointBasedDescriptor":
            return <PointBased onUpdate={onUpdate} value={value} descriptor={descriptor} imageBlob={image}
                               spots={spots}/>
        default:
            return null;
    }
}
