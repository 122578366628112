import React from 'react';
import {Route, Switch} from "react-router-dom";
import UserRouter from "./user/UserRouter";
import ProjectsRouter from "./project/ProjectRouter";
import PatientRouter from "./patient/PatientRouter";
import BodychartRouter from "./bodychart/BodychartRouter";
import DescriptorsRouter from "./descriptors/DescriptorsRouter";
import FeedbackRouter from "./feedback/FeedbackRouter";
import ServiceRouter from "./services/ServiceRouter";
import LocaleRouter from "./locale/LocaleRouter";


export default function SupervisorRouter() {

    return (
        <Switch>
            <Route path="/supervisor/user" component={UserRouter}/>
            <Route path="/supervisor/project" component={ProjectsRouter}/>
            <Route path="/supervisor/patient" component={PatientRouter}/>
            <Route path="/supervisor/chart" component={BodychartRouter}/>
            <Route path="/supervisor/descriptor" component={DescriptorsRouter}/>
            <Route path="/supervisor/feedback" component={FeedbackRouter}/>
            <Route path="/supervisor/service" component={ServiceRouter}/>
            <Route path="/supervisor/locale" component={LocaleRouter}/>
        </Switch>
    );
}
