import React, {useState} from 'react';

import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

export default function NewServiceDialog({open, onClose, onSave}) {
    const [payload, setPayload] = useState({password: ""});

    // stati per il controllo errori

    const [nameCheck, setNameCheck] = useState({error: false, text: ""});
    const [descriptionCheck, setDescriptionCheck] = useState({error: false, text: ""});
    const [urlCheck, setUrlCheck] = useState({error: false, text: ""});

    const handleUpdate = (prop, value) => {
        setPayload((old) => {
            old[prop] = value;
            return old
        })
    }

    const handleSave = () => {
        // faccio un po di controlli qua e la

        let error = false;

        // nome
        if (payload.name === "" || payload.name === undefined) {
            setNameCheck({error: true, text: "Please, enter a name"})
            error = true
        } else
            setNameCheck({error: false, text: ""})
        // cognome
        if (payload.description === "" || payload.description === undefined) {
            setDescriptionCheck({error: true, text: "Please, enter a description"})
            error = true
        } else
            setDescriptionCheck({error: false, text: ""})
        // email
        if (payload.serviceUrl === "" || payload.serviceUrl === undefined) {
            setUrlCheck({error: true, text: "Please, enter a service url"})
            error = true
        } else
            setUrlCheck({error: false, text: ""})

        if (!error)
            onSave(payload)
    }

    const handleClose = () => {
        onClose()
        setPayload(undefined)
    }


    return (
        <Dialog open={open} onClose={handleClose}>
                <DialogTitle>New service</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}
                          style={{
                              margin: 0,
                              width: '100%',
                          }}
                    >
                        <Grid item xs={12}>
                            <TextField onChange={event => handleUpdate("name", event.target.value)} fullWidth
                                       error={nameCheck.error} helperText={nameCheck.text}  label={"Name"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={event => handleUpdate("description", event.target.value)} fullWidth
                                       error={descriptionCheck.error} helperText={descriptionCheck.text} label={"Description"}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={event => handleUpdate("serviceUrl", event.target.value)}
                                       error={urlCheck.error} helperText={urlCheck.text} fullWidth label={"Service url"}/>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Create
                    </Button>
                </DialogActions>
        </Dialog>
    )
}