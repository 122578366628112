import React from "react";
import {Card} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import Typography from '@material-ui/core/Typography';
import ImageMapper from "../image-mapper/ImageMapper";
import Grid from "@material-ui/core/Grid";
import {getRandomColor} from "../../utils/generics";
import {BlockPicker} from "react-color";
import Popover from "@material-ui/core/Popover";


// per avere l'effetto che si apre da sotto
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function SpotBased({descriptor, value, onUpdate, imageBlob, spots = []}) {

    const defaultValue = value === null ? "" : value.value
    const defaultColor = !value || !value.color ? getRandomColor() : value.color

    const [open, setOpen] = React.useState(false);
    const [showValue, setShowValue] = React.useState(defaultValue);
    const [highlightColor, setHighlightColor] = React.useState(defaultColor);
    const [openPicker, setOpenPicker] = React.useState(null);


    const handlePointSelection = () => {
        setOpen(true)
    }

    const areas = spots.map(spot => {
        const coords = spot.spotContours.map(point => point.x + "," + point.y).join(",").split(",")

        const holes = spot.spotHolesContours.map(
            hole => hole.map(point => point.x + "," + point.y).reverse().join(",").split(",")
        )
        // se completo metto il colore verde
        const fill = spot.complete ? "rgba(0, 255, 0, 0.5)" : "rgba(255, 0, 0, 0.5)"
        return {id: spot.id, coords: coords, fill: fill, holes: holes}
    })

    const handlePointSelected = (coords, area) => {
        if (!area)
            return

        setShowValue(area.id)
        onUpdate(descriptor.id, area.id, highlightColor)
        setOpen(false)
    }

    const handleClick = (event) => {
        setOpenPicker(openPicker ? null : event.currentTarget);
    };

    const handleColorChanged = (color) =>{
        setHighlightColor(color.hex)
        setOpenPicker(null)
        onUpdate(descriptor.id, showValue, color.hex)
    }


    return (
        <Card>
            <CardHeader
                title={descriptor.name || descriptor.key}
                subheader={descriptor.isMandatory ? <div style={{color: "red"}}>Mandatory</div> : null}
                action={
                    <svg height="50" width="50" style={{cursor: 'pointer', display: !showValue?"none":""}}>
                        <circle cx="25" cy="25" r="20" stroke="white"
                                onClick={handleClick}
                                style={{fill: highlightColor}}
                                strokeWidth="1"/>
                    </svg>
                }
            />
            <CardContent>
                <Grid container justify={"space-between"}>
                    <Popover open={Boolean(openPicker)}
                             anchorEl={openPicker}
                             onClose={() => setOpenPicker(null)}
                             placement="bottom-end">
                        <BlockPicker
                            color={highlightColor}
                            triangle={"hide"}
                            onChange={handleColorChanged}/>
                    </Popover>

                    <Grid item xs={8}>
                        <Typography variant="body1">
                            {showValue}
                        </Typography>
                    </Grid>
                    <Grid item>

                    </Grid>
                    <Grid item xs={12}>
                        <Button disabled={spots.length === 0} onClick={handlePointSelection} variant="contained"
                                color="primary" fullWidth>
                            Select a spot
                        </Button>
                    </Grid>
                </Grid>
            </CardContent>

            {/*Dialog che mostra l'immagine fullscreen per scegliere un point*/}
            <Dialog id="dialog" open={open} onClose={() => setOpen(false)} TransitionComponent={Transition}>

                <ImageMapper
                    src={imageBlob}
                    areas={areas}
                    referenceSize={{
                        x: 1536,
                        y: 2048
                    }}
                    onClick={handlePointSelected}
                />

            </Dialog>

        </Card>
    )
}

export default SpotBased