import getWord from "random-words/index";

export const getSimplePassword = () => {

    // ottengo una parola casuale
    let randomWords = getWord({exactly:2, wordsPerString:1,})

    // dizionario che contiene gli scambi
    let replacing = {
        a: "4",
        s: "5",
        l: "1",
        z: "2",
        e: "3",
        g: "6",
        t: "7",
        b: "8",
        o: "0",
    };

    // rendo maiuscola la prima lettera della seconda parola
    randomWords[1] = randomWords[1][0].toUpperCase() + randomWords[1].slice(1)

    // ora le concateno in una parola unica
    let passwordBase = randomWords.join("")
    let resultPassword = ""
    // rimpiazzo le lettere con i numeri casualmente al 50%
    for( let i=0;i<passwordBase.length;i++){
        if(Math.floor(Math.random() * 10) > 3)
            resultPassword += replacing[passwordBase[i]] || passwordBase[i]
        else
            resultPassword += passwordBase[i]
    }


    return resultPassword
}



