const checkToken = q4_jwt => {
    const date = new Date();

    // mi accerto che non sia nullo
    if(q4_jwt == null)
        return false

    // controllo che non sia scaduto
    if(q4_jwt.hasOwnProperty("expire") && q4_jwt.expire<date.getTime()/1000) {
        return false
    }

    // se tutti i controlli passano
    return true

}

export default checkToken