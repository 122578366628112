import React, {useCallback, useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import DrawingImage from "../../../../components/DrawingImage";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import GestureIcon from '@material-ui/icons/Gesture';
import MemoryIcon from '@material-ui/icons/Memory';
import ToggleButton from "@material-ui/lab/ToggleButton";
import {AppContext} from "../../../../App";
import {
    deleteDrawing,
    fetchBlob,
    fetchDrawing,
    fetchProjectDescriptors,
    postDrawingDismissError,
    postReprocessingRequest
} from "../../../../utils/apiCaller";
import PainValues from "../../../../components/PainValues";
import {EasyBreadcrumbs} from "../../../../components/Breadcumbs";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Alert, AlertTitle} from "@material-ui/lab";
import PainExtent from "../../../../components/PainExtent";
import {useQueryParams} from "../../../../hooks/useQueryParams";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Menu, MenuItem} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const downloadingImage = (
    <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
            <CircularProgress/>
        </Grid>
        <Grid item>
            Downloading image
        </Grid>
    </Grid>
)

function DrawingDetail() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext)
    const jwt = state.user.jwt
    // carico il progetto attualmente selezionato

    const {acquisitionId, drawingId, projectId} = useParams();

    const [menuAnchor, setMenuAnchor] = useState(null);

    // stato che contiene il drawing
    const [drawing, setDrawing] = useState({descriptors: [], spotContours: []});
    const [descriptors, setDescriptors] = useState();
    const [values, setValues] = useState([]);
    const [bodyChartBlob, setBodyChartBlob] = useState("");
    const [drawingBlob, setDrawingBlob] = useState("");

    const [pointBasedValues, setPointBasedValues] = useState([]);
    const [spotBasedValues, setSpotBasedValues] = useState({});

    const [drawShow, setDrawShow] = useState("approx");

    const history = useHistory();
    const currentTs = new Date().getTime() / 1000;

    const queryParams = useQueryParams()

    let destinationParams = `?page=${queryParams.qp.page || ""}&size=${queryParams.qp.size || ""}&sort=${queryParams.qp.sort || ""}&dir=${queryParams.qp.dir || ""}`

    const breadcrumbs = [
        {destination: "/" + projectId + "/acquisition" + destinationParams, label: "Acquisitions"},
        {destination: "/" + projectId + "/acquisition/" + acquisitionId + destinationParams, label: "Acquisition"}
    ]

    const pollingDrawingFetch = useCallback((token, acquisitionId, drawingId, first = true) => {
        fetchDrawing(token, acquisitionId, drawingId)
            .then(drawing => {
                if (drawing.waitingResults) {
                    if (first) {
                        setDrawing(drawing)
                    }
                    setTimeout(() => pollingDrawingFetch(token, acquisitionId, drawingId, false), 2000)
                } else setDrawing(drawing)
            })
            .catch(e => alert(e))
    }, []);

    //http://isteps-sps-01.supsi.ch/syp-api/detector/60425938855bf262c3be1023/60425938855bf262c3be1024/report.pdf
    const handleReportDownload = () => {
        fetchBlob(jwt.token, "/detector/" + acquisitionId + "/" + drawingId + "/report.pdf")
            .then(blob => {
                // const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = blob;
                // the filename you want
                a.download = drawingId + '-report.pdf';
                document.body.appendChild(a);
                a.click();
            })
            .catch(() => dispatch({type: "SHOW_ALERT", payload: {message: "No report available", severity: "error"}}))
    }

    useEffect(() => {
        dispatch({type: "START_LOADING", payload: {key: "fetchDrawing"}})
        dispatch({type: "START_LOADING", payload: {key: "fetchProjectDescriptors"}})

        pollingDrawingFetch(jwt.token, acquisitionId, drawingId)
        fetchProjectDescriptors(jwt.token, projectId)
            .then(result => {
                setDescriptors(result.drawingDescriptors)
                dispatch({type: "LOADED", payload: {key: "fetchProjectDescriptors"}})
            })
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

    }, [drawingId, acquisitionId, jwt, pollingDrawingFetch, dispatch, projectId])

    useEffect(() => {
        if (drawing.id)
            dispatch({type: "LOADED", payload: {key: "fetchDrawing"}})
    }, [drawing, dispatch])

    useEffect(() => {
        if (drawing.id === undefined || !descriptors)
            return

        // mappo i descriptors con i valori


        const points = []
        const spots = {}

        const result = descriptors.map(
            d => {
                let value = drawing.values[d.id]

                let result = {
                    unitOfMeasure: d.unitOfMeasure,
                    key: d.key,
                    id: d.id,
                    description: d.description
                }
                let color
                if (value)
                    color = value.color

                if (d.kind === "PointBasedDescriptor" && value) {
                    points.push({
                        x: value.x,
                        y: value.y,
                        fill: color
                    })
                    result.value = `(${value.x},${value.y})`
                } else if (d.kind === "SpotBasedDescriptor" && value) {
                    spots[value.value] = color
                    result.value = value.value
                } else if (value) {
                    result.value = value.value
                } else
                    result.value = "unknown"

                result.color = color
                return result
            }
        )

        // scarico l'immagine se non è già stato fatto
        if (bodyChartBlob === "" && drawing.chartLowUrl)
            fetchBlob(jwt.token, drawing.chartLowUrl)
                // metto l'immagine nello stato
                .then(image => setBodyChartBlob(image))
                .catch(e => console.log(e))

        if (drawingBlob === "" && drawing.drawnBodyChartUrl)
            fetchBlob(jwt.token, drawing.drawnBodyChartUrl)
                // metto l'immagine nello stato
                .then(image => setDrawingBlob(image))

        setValues(result)
        setPointBasedValues(points)
        setSpotBasedValues(spots)

    }, [drawing, bodyChartBlob, drawingBlob, jwt, descriptors])

    const handleDismissAlert = () => {
        postDrawingDismissError(jwt.token, acquisitionId, drawingId)
            .then(result => setDrawing(result))
            .catch(error => {
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            })
    }

    const handleSpotClick = (id) => {
        // se ho l'info sul id paziente allora il link deve essere differente
        const url = `/${projectId}/acquisition/${acquisitionId}/${drawingId}/${id}${destinationParams}`

        history.push(url)
    }

    const handleDrawingDelete = () => {
        if (!drawing.isWaitingResults
            && window.confirm("You will loose all the spots information related to the drawing, continue?")) {
            deleteDrawing(jwt.token, acquisitionId, drawingId)
                .then(() => {
                    history.push(`/${projectId}/acquisition/${acquisitionId}`)
                    dispatch({type: "SHOW_ALERT", payload: {message: "Drawing removed", severity: "success"}})
                })
                .catch(result => {
                    dispatch({type: "SHOW_ALERT", payload: {message: result.message, severity: "error"}})
                })
        }
    }

    const handleRequestReprocessing = () => {
        if (drawing.isWaitingResults || window.confirm("You will loose all the spots information related to the drawing, continue?")) {
            setMenuAnchor(undefined)
            drawing.waitingResults = true
            setDrawing(drawing)
            postReprocessingRequest(jwt.token, drawingId, acquisitionId)
                .then(() => {
                    dispatch({type: "SHOW_ALERT", payload: {message: "Request submitted", severity: "success"}})
                    pollingDrawingFetch(jwt.token, acquisitionId, drawingId)
                })
                .catch(result => {
                    drawing.waitingResults = false
                    drawing.processingErrorMessage = result.message
                    setDrawing(drawing)
                    dispatch({type: "SHOW_ALERT", payload: {message: result.message, severity: "error"}})
                })
        }
    }

    return (
        <Grid container>
            <Grid item xs={6} style={{height: 60}} className={classes.banner}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                         style={{
                             height: 55,
                             paddingTop: 16,
                             verticalAlign: "middle",
                             display: "table-cell",
                             paddingLeft: 16
                         }}>
                        <EasyBreadcrumbs breadcrumbs={breadcrumbs} current={"Drawing"}/>
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={6} className={classes.banner} style={{textAlign: "right"}}>
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{height: 68, paddingLeft: 16, paddingRight: 16, paddingTop: 5}}
                    spacing={1}
                >
                    <Grid item>
                        <ToggleButtonGroup
                            style={{marginRight: 10, marginTop: 3}}
                            value={drawShow}
                            size={"small"}
                            exclusive
                            onChange={((event, value) => {
                                if (value !== null)
                                    setDrawShow(value)
                            })}
                        >
                            <ToggleButton value="approx" aria-label="approx">
                                <MemoryIcon/>
                            </ToggleButton>
                            <ToggleButton value="raw" aria-label="raw">
                                <GestureIcon/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item>
                        <Button
                            // disabled={!drawing.waitingResults}
                            variant="outlined" color="primary"
                            onClick={handleReportDownload}>
                            Download report
                        </Button>
                    </Grid>
                    <Grid item>
                        <IconButton
                            onClick={(e) => setMenuAnchor(e.currentTarget)}>
                            <MoreVertIcon/>
                        </IconButton>

                        <Menu
                            anchorEl={menuAnchor}
                            keepMounted
                            open={Boolean(menuAnchor)}
                            onClose={() => setMenuAnchor(undefined)}
                        >
                            <MenuItem onClick={handleRequestReprocessing}>Request re-processing</MenuItem>
                            <MenuItem onClick={handleDrawingDelete}>Delete drawing</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            {drawing.processingErrorMessage && !drawing.waitingResults &&
                <Grid item xs={12}>
                    <Alert severity={"warning"}
                           action={<Button onClick={handleDismissAlert} color="inherit" size="small">DISMISS</Button>}>
                        <AlertTitle>Last processing request returned an error:</AlertTitle>
                        {drawing.processingErrorMessage}
                    </Alert>
                </Grid>}
            <Grid item xs={8} style={{paddingLeft: 16, paddingTop: 16}}>
                {drawing.waitingResults ?
                    <Grid container direction="column" justify="center" alignItems="center">
                        <Grid item>
                            <CircularProgress/>
                        </Grid>
                        <Grid item>
                            Waiting result...
                        </Grid>
                        <Grid item>
                            {(currentTs - drawing.processingRequestTime) > 300 ?
                                <span>Is taking a long time, please consider executing again the processing procedure.</span>
                                : null}

                        </Grid>
                    </Grid>

                    :
                    <div style={{width: "50%"}}>
                        {drawShow === "approx" ?
                            !bodyChartBlob ?
                                downloadingImage
                                :
                                <DrawingImage imageBlob={bodyChartBlob}
                                              spots={drawing.spotDtos}
                                              spotsStrokes={spotBasedValues}
                                              points={pointBasedValues}
                                              pointer
                                              cursor={"pointer"}
                                              onClick={handleSpotClick}/>
                            :
                            !drawingBlob ? downloadingImage :
                                <img alt={"Drawn body chart"} src={drawingBlob} style={{width: "100%"}}/>}
                    </div>
                }
            </Grid>

            <Grid item xs={4}>
                <PainValues values={values}/>
                {drawing.painExtent !== null &&
                    <PainExtent painExtent={drawing.painExtent}/>}
            </Grid>

        </Grid>
    )
}

export default DrawingDetail;