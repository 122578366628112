import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import BodyChartFileUpload from "../BodyChartFileUpload";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FileInput from "../../../components/FileInput";
import {toBase64} from "../../../utils/generics";

export default function BodyChartChangeDialog({open, onClose, onSave, isMap, loading}) {
    const [selectedChart, setSelectedChart] = useState(null);
    const [selectedCsv, setSelectedCsv] = useState(null);
    const [csvError, setCsvError] = useState({error: false, message: ""});

    const handleClose = () => {
        setSelectedChart(null)
        onClose()
    }

    const handleSCVSelected = (event) => {
        // estraggo la lista di file
        const fi = event.target

        // controllo se c'è almeno qualcosa
        if (fi.files.length > 0) {
            // se ce qualcosa dovrebbe essere 1 solo file!
            const file = fi.files.item(0)

            toBase64(file)
                .then(result => {
                    setSelectedCsv(result)
                })
                .catch(error => setCsvError({error: true, message: error}))

        }
    }

    return (
        <Dialog maxWidth={"sm"} fullWidth open={open} onClose={onClose}>
            <DialogTitle>
                Change file
            </DialogTitle>
            <DialogContent>
                <BodyChartFileUpload text={"Image"} onChange={setSelectedChart}/>
                {isMap && <Grid container style={{marginTop:16}}>
                    <Grid item xs={12}>
                        <Typography variant="body2" style={{color: "#626262"}} gutterBottom>
                            Region description file
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FileInput label={"select file"} onChange={handleSCVSelected} accept={".csv"}/>
                        {csvError.error &&
                        <Grid item xs={12}>
                            <Typography variant="body2" color={"error"} gutterBottom>
                                {csvError.message}
                            </Typography>
                        </Grid>
                        }
                    </Grid>
                </Grid> }
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="primary">
                    Close
                </Button>
                <Button
                    onClick={() => onSave({image: selectedChart, mapDescriptions: selectedCsv})}
                    variant={"outlined"}
                    color="primary">
                    Upload and save
                </Button>
            </DialogActions>
        </Dialog>
    )
}
