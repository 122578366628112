import React from "react";
import Grid from "@material-ui/core/Grid";
import {useParams} from "react-router-dom";
import ProjectDetails from "./ProjectDetails";
import Box from "@material-ui/core/Box";


function DetailsScreen() {

    const {projectId} = useParams()

    return (
        <Box p={2}>
            <Grid container>
                <Grid item xs={12} md={12} lg={8} xl={8}>
                    <ProjectDetails
                        projectId={projectId}/>
                </Grid>
            </Grid>
        </Box>
    )
}


export default DetailsScreen;