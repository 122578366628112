import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import {postProjectUpdate, putNewProject} from "../utils/apiCaller";
import {AppContext} from "../App";


export default function ProjectDialog({open, onClose, project, onPerformed}) {

    const [state, dispatch] = useContext(AppContext);
    const jwt = state.user.jwt

    const isCreation = !project

    const [projectData, setProjectData] = useState({
        name: "",
        description: ""
    })

    useEffect(() => {
        if (project)
            setProjectData({name: project.name, description: project.description})
        else
            setProjectData({name: "", description: ""})

    }, [project])

    const handleNewProject = () => {
        if (projectData.name === "")
            alert("Fill the name field please")
        else
            putNewProject(jwt.token, projectData)
                .then(result => {
                    dispatch({type: "SHOW_ALERT", payload: {message: "New project created successfully"}})
                    onPerformed(result.id)
                })
                .catch(result => alert(result.message))
    }

    const handleProjectUpdate = () => {
        projectData.id = project.id
        if (projectData.name === "")
            alert("Fill the name field please")
        else
            postProjectUpdate(jwt.token, projectData)
                .then(result => {
                    dispatch({type: "SHOW_ALERT", payload: {message: "Project information updated successfully"}})
                    onPerformed(result.id)
                })
                .catch(result => alert(result.message))
    }

    return (
        <Dialog maxWidth={"md"} open={open}
                onExited={() => setProjectData({name: "", description: ""})}
                onClose={onClose}>
            <DialogTitle>
                {isCreation ?
                    "Create a new project"
                    :
                    "Update project"
                }
            </DialogTitle>


            <ProjectCreation
                name={projectData.name}
                desc={projectData.description}
                onNameChange={(n) => {
                    setProjectData({name: n, description: projectData.description})
                }}
                onDescChange={(n) => {
                    setProjectData({name: projectData.name, description: n})
                }}
            />


            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary">
                    Close
                </Button>

                <Button
                    onClick={isCreation ? handleNewProject : handleProjectUpdate}
                    color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export function ProjectCreation({name, desc, onNameChange, onDescChange}) {

    return (
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        onChange={(e) => {
                            onNameChange(e.target.value)
                        }}
                        value={name}
                        size={"small"}
                        label="Project name"
                        variant={"outlined"}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        onChange={(e) => onDescChange(e.target.value)}
                        value={desc}
                        size={"small"}
                        label="Description"
                        multiline
                        variant={"outlined"}
                        fullWidth
                    />
                </Grid>
            </Grid>
        </DialogContent>
    )
}