import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../App";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {useHistory, useParams} from "react-router-dom";
import {fetchAcquisitionsPage, fetchProjectDescriptors, fetchProjectDetails} from "../../../utils/apiCaller";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MassivePDFDialog from "../../../dialogs/MassivePDFDialog";
import PaginatedTable from "../../../components/PaginatedTable";
import {useQueryParams} from "../../../hooks/useQueryParams";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));
const renderFunction = (descriptorId) => (row) => {
    if (row.values && row.values[descriptorId])
        return row.values[descriptorId].value
    return ""
}

const tableColumns = [
    {name: "Date", key: "date"},
    {name: "Operator", key: "operatorFullName", sorting: false},
    {name: "Drawings", key: "drawingsCount", sorting: false},
    {name: "Manual session ID", key: "manualSessionId", sorting: false},
    {name: "Manual session SET", key: "manualSessionSet", sorting: false}
]

function ListScreen() {
    const classes = useStyles();
    const [state] = useContext(AppContext)
    const {projectId} = useParams()
    const history = useHistory()
    const [mustRefresh, setMustRefresh] = useState(false)
    const [massiveOpen, setMassiveOpen] = useState(false);

    const [project, setProject] = useState(false);
    const [descriptors, setDescriptors] = useState([]);

    const [pageArgs, setPageArgs] = useState()

    useEffect(() => {
        if (projectId && state)
            setPageArgs({
                projectId: projectId,
                jwt: state.user.jwt.token
            })
    }, [projectId, state])

    useEffect(() => {
        fetchProjectDetails(state.user.jwt.token, projectId)
            .then(result => setProject(result))
            .catch(error => alert(error.message))

        fetchProjectDescriptors(state.user.jwt.token, projectId)
            .then(result => {
                const acqDesc = result.acquisitionDescriptors
                acqDesc.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                setDescriptors(acqDesc)
            })
            .catch(error => alert(error.message))

    }, [projectId, state.user.jwt])

    const tableColumnsAdditional = [...tableColumns, ...descriptors.map(d => {
        return {
            key: d.id,
            name: d.name,
            renderingFunc: renderFunction(d.id),
            sort: `painValues.${d.id}.value`
        }
    })]

    const queryParams = useQueryParams()

    let destinationParams = `?page=${queryParams.qp.page || ""}&size=${queryParams.qp.size || ""}&sort=${queryParams.qp.sort || ""}&dir=${queryParams.qp.dir || ""}`

    return (
        <Grid
            container
        >
            <Grid item xs={6} className={classes.banner}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 60,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16
                     }}>
                    Acquisitions
                </Box>
            </Grid>
            <Grid item xs={6} className={classes.banner} style={{textAlign: "right"}}>
                <Button variant="outlined" color="primary" style={{marginRight: 16, marginTop: 16}}
                        onClick={() => setMassiveOpen(true)}>
                    Generate manual pdf
                </Button>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                <MassivePDFDialog
                    projectId={projectId}
                    open={massiveOpen}
                    multiGender={project ? project.isMultiGender : false}
                    onClose={() => setMassiveOpen(false)}/>

                {pageArgs && <PaginatedTable
                    mustRefresh={mustRefresh}
                    onRefreshed={() => setMustRefresh(false)}
                    fields={tableColumnsAdditional}
                    sortProperty={"date"}
                    requestArgs={pageArgs}
                    fetchingPageFunction={fetchAcquisitionsPage}
                    onSelected={(acquisition) => history.push("/" + projectId + "/acquisition/" + acquisition.id + destinationParams)}
                />}

            </Grid>
        </Grid>

    )
}

export default ListScreen;