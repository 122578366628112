import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {v4 as uuidv4} from 'uuid';
import {toBase64} from "../utils/generics";


export default function FileInput({
                                      onChange  = () => {}, label = "select file", accept = "*", onSelected = () => {}
}) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [id, setId] = useState("");

    const handleFileChange = (event) => {
        let value = event.target.value
        value = value.split("\\")
        value = value[value.length - 1]

        setSelectedFile(value)


        onChange(event)
        handleFileToBase(event.target)
    }

    console.log(selectedFile)

    useEffect(() => {
        // ottengo un id univoco
        setId(uuidv4())
    }, []);


    const handleFileToBase = (fi) => {
        // controllo se c'è almeno qualcosa
        if (fi.files.length > 0) {
            // se ce qualcosa dovrebbe essere 1 solo file!
            const file = fi.files.item(0)

            toBase64(file)
                .then(result => {
                    onSelected(result) // lo mando al padre
                })
                .catch(console.log)

        }
    }


    return (

        <div style={{borderRadius: 4, border: "1px solid #bdbdbd"}}>
            <Grid container
                  alignItems={"center"}>
                <Grid item style={{borderRight: "1px solid #bdbdbd"}}>
                    <input
                        accept={accept}
                        style={{display: 'none'}}
                        id={id}
                        onChange={handleFileChange}
                        type="file"
                    />
                    <label style={{height: "100%"}} htmlFor={id}>
                        <Button style={{height: "100%"}} component="span">
                            {label}
                        </Button>
                    </label>
                </Grid>
                <Grid item>
                    <Typography variant="body2" style={{color: "#626262", marginLeft: 16}}>
                        {selectedFile}
                    </Typography>
                </Grid>
            </Grid>


        </div>

    )

}