import React, {useContext, useEffect, useState} from "react";
import {fetchProjectAdditionalInfoPage, postDisableAdditionalInfo} from "../../../utils/apiCaller";

import PaginatedTable from "../../../components/PaginatedTable";
import Popover from "@material-ui/core/Popover";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ToggleOffIcon from "@material-ui/icons/ToggleOff";
import {AppContext} from "../../../App";
import EditIcon from '@material-ui/icons/Edit';
import AdditionalInfoDialog from "../../../dialogs/AdditionalInfoDialog";

const tableColumns = [
    {key: "name", name: "Name", sorting: false},
    {key: "description", name: "Description", sorting: false},
    {key: "type", name: "Type", sorting: false},
    {key: "defaultValue", name: "Default value", sorting: false},
    // {key: "isMandatory", name: "Mandatory", boolean: true}
]


function AdditionalInfoContent({projectId, mustRefresh, onRefresh, onMustRefresh}) {

    const [state, dispatch] = useContext(AppContext)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const uMenuOpen = Boolean(anchorEl);

    const [args, setArgs] = useState()
    const [selectedAdditionalInfo, setSelectedAdditionalInfo] = useState()

    useEffect(() => {
        setArgs({projectId: projectId, jwt: state.user.jwt.token})

    }, [projectId, state.user.jwt, mustRefresh, dispatch])

    const handleMenu = (event, elem) => {
        setAnchorEl({el: event.currentTarget, elem: elem});
    };

    const handleDisable = (elem) => {
        postDisableAdditionalInfo(state.user.jwt.token, projectId, elem.id)
            .then(result => onMustRefresh())
            .catch((error) => {
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            })
    }

    return (
        <React.Fragment>
            {args && <PaginatedTable
                jwt={state.user.jwt.token}
                mustRefresh={mustRefresh}
                onRefreshed={onRefresh}
                onMenuSelected={handleMenu}
                fields={tableColumns}
                requestArgs={args}
                fetchingPageFunction={fetchProjectAdditionalInfoPage}
            />}

            {selectedAdditionalInfo && <AdditionalInfoDialog
                projectId={projectId}
                open={!!selectedAdditionalInfo}
                existing={selectedAdditionalInfo}
                onClose={() => setSelectedAdditionalInfo(setSelectedAdditionalInfo(null))}
                onSave={() =>{
                    onMustRefresh()
                    setSelectedAdditionalInfo(null)
                }}
            />}

            <Popover
                open={uMenuOpen}
                anchorEl={anchorEl ? anchorEl.el : null}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <List style={{marginTop: "auto", cursor: "pointer"}}>
                    <ListItem button onClick={() => {
                        setAnchorEl(null)
                        handleDisable(anchorEl.elem)
                    }}>
                        <ListItemIcon>
                            <ToggleOffIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Disable"/>
                    </ListItem>

                    <ListItem button onClick={() => {
                        setAnchorEl(null)
                        setSelectedAdditionalInfo(anchorEl.elem)
                    }}>
                        <ListItemIcon>
                            <EditIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Edit"/>
                    </ListItem>

                </List>
            </Popover>
        </React.Fragment>
    )
}

export default AdditionalInfoContent;