import React, {useContext, useEffect, useState} from "react";
import './Style.css'
import {fetchBlob} from "../utils/apiCaller";
import {AppContext} from "../App";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fade from "@material-ui/core/Fade";

export default function AuthImg({
                                    render = "",
                                    src,
                                    cache = true,
                                    alt = "",
                                    style = {},
                                    onClick = () => {
                                    },
                                    className
                                }) {

    const [state] = useContext(AppContext)

    const [image, setImage] = useState("")
    const [loading, setLoading] = useState(false)
    const [hasError, setHasError] = useState(false)


    // scarico l'immagine
    useEffect(() => {

            if (src === undefined || state.user.jwt === undefined)
                return

            setImage("")
            setLoading(true)
            fetchBlob(state.user.jwt.token, src, cache)
                // metto l'immagine nello stato
                .then(image => {
                    // onLoaded()
                    setImage(image)
                    setLoading(false)
                })
                .catch((e) => {
                    setHasError(true)
                    setLoading(false)
                    console.log(e)
                })

        }, [state.user.jwt, src, cache]
    )

    useEffect(() => {

            if (image !== "")
                setLoading(false)

        }, [image]
    )


    return (

        loading ?
            <Fade
                in={loading} unmountOnExit
                style={{transitionDelay: loading ? '800ms' : '0ms'}}>
                <CircularProgress/>
            </Fade>
            :
            !hasError?
            <img src={image} alt={alt}
                 style={style}
                 className={className}
                 onClick={onClick}/>
                 :
                <div>Missing</div>


    )
}
