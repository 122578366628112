import React from 'react';
import {Route, Switch} from "react-router-dom";
import DescriptorsRouter from "./descriptors/DescriptorsRouter";


export default function SettingsRouter() {

    return (
        <Switch>
            <Route path="/settings/descriptor" component={DescriptorsRouter}/>
        </Switch>
    );
}
