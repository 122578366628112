import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {useParams} from "react-router-dom";
import {AppContext} from "../../../App";
import {fetchLocaleDetail, updateLocaleTranslations} from "../../../utils/apiCaller";
import {EasyBreadcrumbs} from "../../../components/Breadcumbs";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {TextField} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

function LocaleDetailScreen() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext);

    const jwt = state.user.jwt

    // stato che contiene la lista di descriptors globali
    const [locale, setLocale] = useState();
    const [translations, setTranslations] = useState([]);
    // const [snackOpen, setSnackOpen] = useState(false);

    const {localeId} = useParams();

    useEffect(() => {
        fetchLocaleDetail(jwt.token, localeId)
            .then(result => {
                setLocale(result)
                result.translations.sort((a, b) => (a.code > b.code) ? 1 : -1)
                setTranslations(result.translations)
            })
            .catch(result => alert(result))

    }, [localeId, jwt])

    const handleSave = () =>{
        locale.translations = translations

        updateLocaleTranslations(jwt.token, locale)
            .then(result => {
                dispatch({type: "SHOW_ALERT", payload: {message: "Translation updated"}})
            })
            .catch((error) => dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}}))
    }

    const handleTranslationChange = (code, text) => {
        const newList = translations.map((t) => {
            if (t.code === code)
                return {...t, text: text}
            return t
        })

        setTranslations(newList)
    }


    return (

        <Grid container>
            <Grid item xs={8} style={{height: 60}} className={classes.banner}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                         style={{
                             height: 55,
                             paddingTop: 16,
                             verticalAlign: "middle",
                             display: "table-cell",
                             paddingLeft: 16
                         }}>
                        <EasyBreadcrumbs breadcrumbs={[{destination: "/supervisor/locale", label: "Locale"}]}
                                         current={locale ? locale.name : ""}/>
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={4} className={classes.banner}>

                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{height: 68, paddingLeft: 16, paddingRight: 16, paddingTop: 5}}
                    spacing={1}
                >
                    <Grid item>
                        <Button
                            variant="outlined" color={"secondary"} onClick={handleSave}
                            startIcon={<SaveIcon />}
                        >Save</Button>
                    </Grid>
                </Grid>


            </Grid>
            <Grid item xs={12} style={{marginBottom: 16}}><Divider/></Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    Code
                                </TableCell>
                                <TableCell>
                                    Value
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {translations.map(translation => (
                                <TableRow key={translation.code}>
                                    <TableCell>
                                        {translation.code}
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            onChange={(e) => handleTranslationChange(translation.code, e.target.value)}
                                            value={translation.text}/>
                                    </TableCell>
                                </TableRow>
                            ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

        </Grid>


    )
}

export default LocaleDetailScreen;