import React from "react";
import {Card} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Typography from '@material-ui/core/Typography';
import ImageMapper from "../image-mapper/ImageMapper";
import {getRandomColor} from "../../utils/generics";
import {BlockPicker} from "react-color";
import Popover from "@material-ui/core/Popover";


function PointBased({descriptor, value, onUpdate, imageBlob, spots = [], color}) {


    const defaultValue = value === null ? "" : `${value.x},${value.y}`
    const defaultColor = !value || !value.color ? getRandomColor() : value.color

    const [open, setOpen] = React.useState(false);
    const [openPicker, setOpenPicker] = React.useState(null);

    const [showValue, setShowValue] = React.useState(defaultValue);
    const [highlightColor, setHighlightColor] = React.useState(defaultColor);

    const handleClick = (event) => {
        setOpenPicker(openPicker ? null : event.currentTarget);
    };

    const handlePointSelection = () => {
        setOpen(true)
    }

    const areas = spots.map(spot => {
        const coords = spot.spotContours.map(point => point.x + "," + point.y).join(",").split(",")

        const holes = spot.spotHolesContours.map(
            hole => hole.map(point => point.x + "," + point.y).reverse().join(",").split(",")
        )
        // se completo metto il colore verde
        const fill = spot.complete ? "rgba(0, 255, 0, 0.5)" : "rgba(255, 0, 0, 0.5)"
        return {id: spot.id, coords: coords, fill: fill, holes: holes}
    })

    const handlePointSelected = (coords, area) => {
        if (descriptor.inSpot && !area)
            return

        setShowValue(`${coords.x},${coords.y}`)
        onUpdate(descriptor.id, `${coords.x},${coords.y}`, highlightColor)
        setOpen(false)
    }

    const handleColorChanged = (color) =>{
        setHighlightColor(color.hex + "")
        setOpenPicker(null)
        onUpdate(descriptor.id, showValue, color.hex)
    }

    return (
        <Card>
            <CardHeader
                title={descriptor.name || descriptor.key}
                subheader={descriptor.isMandatory ? <div style={{color: "red"}}>Mandatory</div> : null}
                action={
                    <svg height="50" width="50" style={{cursor: 'pointer', display: !showValue?"none":""}}>
                        <circle cx="25" cy="25" r="20" stroke="white"
                                onClick={handleClick}
                                style={{fill: highlightColor}}
                                strokeWidth="1"/>
                    </svg>
                }
            />
            <CardContent>

                <Popover open={Boolean(openPicker)}
                         anchorEl={openPicker}
                         onClose={() => setOpenPicker(null)}
                         placement="bottom-end">
                    <BlockPicker
                        color={highlightColor}
                        triangle={"hide"}
                        onChange={handleColorChanged}/>
                </Popover>

                <Typography variant="body1">
                    {showValue}
                </Typography>

                <Button disabled={spots.length === 0} onClick={handlePointSelection} variant="contained" color="primary"
                        fullWidth>
                    Select point
                </Button>
            </CardContent>

            <Dialog open={open} onClose={() => setOpen(false)}>
                <ImageMapper
                    src={imageBlob}
                    areas={areas}
                    referenceSize={{
                        x: 1536,
                        y: 2048
                    }}
                    onClick={handlePointSelected}
                />

            </Dialog>

        </Card>
    )
}

export default PointBased