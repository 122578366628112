import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";


function RangeBasedEdit({descriptor, onSave, }) {

    const [payload, setPayload] = useState({});

    useEffect(() => {
        let payload = {
            id: descriptor.id,
            key: descriptor.key,
            name: descriptor.name,
            description: descriptor.description,
            min: descriptor.min,
            max: descriptor.max,
            kind: descriptor.kind,
            step: descriptor.step,
            disabled: descriptor.disabled,
            unitOfMeasure: descriptor.unitOfMeasure
        }

        setPayload(payload)

    }, [descriptor])

    const onValueChange = (key, value) => {

        if ((key === "min" ||
        key === "max" ||
        key === "step" ) && value === "")
            value = 0

        payload[key] = value;
        setPayload(payload)

    }


    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" style={{textAlign: "end", marginTop: 4}}>
                        Key
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField disabled multiline defaultValue={descriptor.key} fullWidth placeholder={"Key"}
                           onChange={(event => onValueChange("key", event.target.value))}/>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={3}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" style={{textAlign: "end", marginTop: 4}}>
                        Name
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField multiline defaultValue={descriptor.name} fullWidth placeholder={"Name"}
                           onChange={(event => onValueChange("name", event.target.value))}/>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={3}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" style={{textAlign: "end", marginTop: 4}}>
                        Min
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField type="number" defaultValue={descriptor.min} fullWidth placeholder={"Minimum value"}
                           onChange={(event => onValueChange("min", event.target.value))}/>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={3}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" style={{textAlign: "end", marginTop: 4}}>
                        Max
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField type="number" defaultValue={descriptor.max} fullWidth placeholder={"Maximum value"}
                           onChange={(event => onValueChange("max", event.target.value))}/>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={3}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" style={{textAlign: "end", marginTop: 4}}>
                        Increase step
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField type="number" defaultValue={descriptor.step} fullWidth placeholder={"Increase step"}
                           onChange={(event => onValueChange("step", event.target.value))}/>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={3}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" style={{textAlign: "end", marginTop: 4}}>
                        Unit of measure
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField value={descriptor.unitOfMeasure || ""} fullWidth multiline placeholder={"unit of measure"}
                           onChange={(event => onValueChange("unitOfMeasure", event.target.value))}/>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={3}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" style={{textAlign: "end", marginTop: 4}}>
                        Description
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField defaultValue={descriptor.description} fullWidth multiline placeholder={"Description"}
                           onChange={(event => onValueChange("description", event.target.value))}/>
            </Grid>
            <Grid item xs={3}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" style={{textAlign: "end", marginTop: 4}}>
                        Disabled
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <Switch
                    defaultChecked={payload.disabled}
                    onChange={((event, checked) => onValueChange("disabled", checked))}
                    color="primary"
                />
            </Grid>
            <Grid item xs={10} style={{textAlign: "right"}}>
                <Button variant={"outlined"} color={"primary"} onClick={() => onSave(payload)}>Save changes</Button>
            </Grid>
        </Grid>
    )
}

export default RangeBasedEdit