import React, {useCallback, useContext, useEffect, useState} from "react";

import {AppContext} from "../../../App";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {
    fetchProjectLeaderPermission,
    fetchProjectOperatorPermission,
    updateLeaderPermission,
    updateOperatorPermission
} from "../../../utils/apiCaller";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import debounce from "lodash/debounce";

function Leader({projectId}) {

    const [state, dispatch] = useContext(AppContext);
    const [permission, setPermission] = useState();
    const [loading, setLoading] = useState(true);

    const [results, setResults] = useState(false);

    useEffect(() => {
        dispatch({type: "START_LOADING", payload: {key: "teamLeaderPermission"}})
        fetchProjectLeaderPermission(state.user.jwt.token, projectId)
            .then(setPermission)
            .catch(e => alert(e.message))
    }, [projectId, state.user.jwt, dispatch])

    // debouncing sull update dei valori
    const handleUpdate = useCallback(debounce((results) => {

        const payload = {
            canSeeResults: results
        }

        updateLeaderPermission(state.user.jwt.token, payload, projectId)
            .then(() => dispatch({type: "SHOW_ALERT", payload: {message: "Leader permissions updated"}}))
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

    }, 200), []);

    useEffect(() => {
        if (permission) {
            dispatch({type: "LOADED", payload: {key: "teamLeaderPermission"}})
            setLoading(false)
            setResults(permission.canSeeResults)
        }
    }, [permission, dispatch])

    const handleChange = (kind, value) => {
        switch (kind) {
            case "results":
                setResults(value)
                handleUpdate(value)
                break
            default:
        }


    }

    return (
        <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                    Leader
                </Box>
                <Divider/>
            </Grid>
            <Grid item xs={4}>Access smart analysis results</Grid>
            <Grid item xs={2}>
                <Switch
                    disabled={loading}
                    checked={results}
                    onChange={(e, c) => handleChange(e.target.name, c)}
                    name="results"
                    color="primary"
                />
            </Grid>
        </Grid>)
}

function Operator({projectId}) {

    const [state, dispatch] = useContext(AppContext);
    const [permission, setPermission] = useState();

    const [history, setHistory] = useState(false);
    const [others, setOthers] = useState(false);

    const [loading, setLoading] = useState(true);

    // debouncing sull update dei valori
    const handleUpdate = useCallback(debounce((history, others) => {

        const payload = {
            canSeeAcquisitionHistory: history,
            canSeeOthersAcquisitions: others
        }

        updateOperatorPermission(state.user.jwt.token, payload, projectId)
            .then(() => dispatch({type: "SHOW_ALERT", payload: {message: "Operator permissions updated"}}))
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

    }, 500), []);

    useEffect(() => {
        if (permission) {
            setLoading(false)
            dispatch({type: "LOADED", payload: {key: "teamOperatorPermission"}})
            setHistory(permission.canSeeAcquisitionHistory)
            setOthers(permission.canSeeOthersAcquisitions)
        }
    }, [permission,dispatch])

    useEffect(() => {
        dispatch({type: "START_LOADING", payload: {key: "teamOperatorPermission"}})
        fetchProjectOperatorPermission(state.user.jwt.token, projectId)
            .then(setPermission)
            .catch(e => alert(e.message))
    }, [projectId, state.user.jwt, dispatch])

    const handleChange = (kind, value) => {
        switch (kind) {
            case "others":
                setOthers(value)
                handleUpdate(history, value)
                break
            case "history":
                setHistory(value)
                handleUpdate(value, others)
                break
            default:
        }
    }

    return (
        <Grid container spacing={2} alignItems={"center"}>
            <Grid item xs={12}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                    Operator
                </Box>
                <Divider/>
            </Grid>
            <Grid item xs={4}>View past complete acquisitions</Grid>
            <Grid item xs={2}>
                <Switch
                    disabled={loading}
                    checked={history}
                    onChange={(e, c) => handleChange(e.target.name, c)}
                    name="history"
                    color="primary"
                />
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={4}>View others operators acquisitions</Grid>
            <Grid item xs={2}>
                <Switch
                    disabled={loading}
                    checked={others}
                    onChange={(e, c) => handleChange(e.target.name, c)}
                    name="others"
                    color="primary"
                />
            </Grid>
        </Grid>)
}


export default function TeamPermissions({projectId}) {
    const [state] = useContext(AppContext);

    return (
        <Grid container spacing={3} style={{margin: 8}}>

            {state.user.isSupervisor && <Grid item xs={12}><Leader projectId={projectId}/></Grid>}
            <Grid item xs={12}>
                <Operator projectId={projectId}/>
            </Grid>

        </Grid>
    )
}