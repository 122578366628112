import React from "react";
import Typography from "@material-ui/core/Typography";
import './Style.css'
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

function PainExtent({painExtent}) {

    return (
        <Typography component="div">
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <Box style={{marginLeft: 10}} fontWeight="fontWeightLight"
                         fontSize={"h6.fontSize"}>
                        Pain extent
                    </Box>
                </Grid>

                <Grid item xs={6}>
                    <Box style={{marginLeft: 10}} fontWeight="fontWeightLight"
                         fontSize={"h6.fontSize"}>
                        {Math.round(painExtent * 10000) / 100}%
                    </Box>
                </Grid>
            </Grid>


        </Typography>
    )
}

export default PainExtent;