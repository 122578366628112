import React, {useContext, useEffect, useState} from "react";

import {AppContext} from "../../../App";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {fetchAllLocales, fetchProjectSettings, updateProjectSettings,} from "../../../utils/apiCaller";
import Divider from "@material-ui/core/Divider";
import Switch from "@material-ui/core/Switch";
import {useParams} from "react-router-dom";
import {Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function SettingsScreen() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext);

    const {projectId} = useParams()
    const [settings, setSettings] = useState();

    const [allLocales, setAllLocales] = useState();
    const [locale, setLocale] = useState({name:''});

    const [loading, setLoading] = useState(true);

    const [patientZoom, setPatientZoom] = useState(false);
    const [patientBrushSize, setPatientBrushSize] = useState(false);

    useEffect(() => {
        dispatch({type: "START_LOADING", payload: {key: "fetchProjectSettings"}})
        fetchProjectSettings(state.user.jwt.token, projectId)
            .then(setSettings)
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

        fetchAllLocales(state.user.jwt.token)
            .then(setAllLocales)
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

    }, [projectId, state.user.jwt, dispatch])

    useEffect(() => {
        if (settings) {
            dispatch({type: "LOADED", payload: {key: "fetchProjectSettings"}})
            setLoading(false)
            setPatientZoom(settings.patientCanZoom)
            setPatientBrushSize(settings.patientCanSelectBrushSize)
        }

        if(settings && allLocales){
            let temp = allLocales.find(x => x.id === settings.localeId)
            if (!temp)
                temp = allLocales.find(x => x.isPrimary === true)
            setLocale(temp)
        }

    }, [settings, dispatch, allLocales])

    const handleChange = (kind, value) => {
        switch (kind) {
            case "zoom":
                setPatientZoom(value)
                handleUpdate({patientCanZoom: value})
                break
            case "brush":
                setPatientBrushSize(value)
                handleUpdate({patientCanSelectBrushSize: value})
                break
            default:
        }
    }


    const handleUpdate = (payload) => {

        updateProjectSettings(state.user.jwt.token, payload, projectId)
            .then(() => dispatch({type: "SHOW_ALERT", payload: {message: "Project settings updated"}}))
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )
    }

    const handleChangeLocale = (event, value) => {
        setLocale(value)
        handleUpdate({localeId: value.id})
    }

    return (
        <Grid container>
            <Grid item xs={12} className={classes.banner}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 60,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16
                     }}>
                    Settings
                </Box>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                <Grid container spacing={2} alignItems={"center"} style={{padding:24}}>
                    <Grid item xs={12}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Patient drawing studio
                        </Box>
                        <Divider/>
                    </Grid>
                    <Grid item xs={4}>Zoom the body chart</Grid>
                    <Grid item xs={2}>
                        <Switch
                            disabled={loading}
                            checked={patientZoom}
                            onChange={(e, c) => handleChange(e.target.name, c)}
                            name="zoom"
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={12}/>
                    <Grid item xs={4}>Change brush size</Grid>
                    <Grid item xs={2}>
                        <Switch
                            disabled={loading}
                            checked={patientBrushSize}
                            onChange={(e, c) => handleChange(e.target.name, c)}
                            name="brush"
                            color="primary"
                        />
                    </Grid>
                    <Grid item xs={12}/>
                    <Grid item xs={4}>Locale</Grid>
                    <Grid item xs={2}>
                        <Autocomplete
                            options={allLocales || []}
                            getOptionLabel={(option) => option.name}
                            onChange={handleChangeLocale}
                            value={locale}
                            renderInput={params => <TextField fullWidth{...params}/>}
                        />
                    </Grid>
                </Grid>
            </Grid>

        </Grid>)
}
