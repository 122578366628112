import React, {useContext, useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SwipeableViews from 'react-swipeable-views';
import DescriptorStep1 from "./DescriptorStep1";
import Grid from "@material-ui/core/Grid";
import DescriptorStep2 from "./DescriptorStep2";
import DescriptorStep3 from "./DescriptorStep3";
import DescriptorStep4 from "./DescriptorStep4";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SaveIcon from '@material-ui/icons/Save';
import {putNewDescriptor} from "../../utils/apiCaller";
import {AppContext} from "../../App";

export default function NewDescriptorDialog({open, onClose, onSave, global = false}) {

    const [step, setStep] = useState(0);

    const [state, dispatch] = useContext(AppContext);

    const [selectedKind, setSelectedKind] = useState("");
    const [descriptorPayload, setDescriptorPayload] = useState();

    const [nextEnabled, setNextEnabled] = useState(true);
    const [backEnabled, setBackEnabled] = useState(false);
    const [saveEnabled, setSaveEnabled] = useState(false);

    const handleCreateButton = (event) => {
        event.preventDefault()


        // costruisco un oggetto contenente i dati della form
        const descriptor = {}
        onSave(descriptor)
    }

    const steps = ['Introduction', 'Type selection', 'Properties', 'Preview'];

    const handleNext = () => {
        setStep(step + 1);
        setNextEnabled(false)
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const handleStepChange = (step) => {
        setStep(step);
    };

    const handleSave = () => {

        let payload = descriptorPayload

        payload.kind = selectedKind
        // mi assicuro di crearlo della tipologia corretta
        payload.global = global
        putNewDescriptor(state.user.jwt.token, payload)
            .then(result => {
                    // faccio pulizia
                    setSelectedKind("")
                    setDescriptorPayload(undefined)
                    setStep(0)

                    // notifico che è tutto apposto
                    onSave(result)
                }
            )
            .catch((error) =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )
    }

    const handleClose = () => {
        onClose()
        setStep(0)
        setDescriptorPayload(undefined)
        setSelectedKind("")
    }

    useEffect(() => {
        setBackEnabled(step > 0)
        setSaveEnabled(step === 3)
        if (step === 0)
            setNextEnabled(true)
        else if (step === 1 && selectedKind !== "")
            setNextEnabled(true)
        else if (step === 2 && descriptorPayload !== undefined)
            setNextEnabled(true)
        else setNextEnabled(false)


    }, [step, selectedKind, descriptorPayload])

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={handleCreateButton}>
                <DialogTitle id="form-dialog-title">Creation wizard</DialogTitle>
                <DialogContent>

                    <Grid container>

                        <Grid item xs={12} style={{paddingBottom: 32}}>
                            <Stepper activeStep={step} style={{padding: 0}}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};
                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>
                        </Grid>

                        <Grid item xs={12}>
                            <SwipeableViews
                                axis={'x'}
                                index={step}
                                onChangeIndex={handleStepChange}>

                                <DescriptorStep1/>

                                <DescriptorStep2 onChange={(kind) => {
                                    setSelectedKind(kind);
                                    setDescriptorPayload(undefined);
                                }}/>

                                <DescriptorStep3
                                    onChange={(payload, isValid) => {
                                        if (isValid) {
                                            setDescriptorPayload(payload);
                                        }
                                    }}
                                    kind={selectedKind}/>

                                <DescriptorStep4
                                    descriptor={descriptorPayload}
                                    kind={selectedKind}/>


                            </SwipeableViews>
                        </Grid>
                        <Grid item xs={4}>
                            {backEnabled ?
                                <IconButton color={"secondary"} onClick={handleBack}><ArrowBackIcon/></IconButton> : ""}
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "center"}}>
                            {saveEnabled ?
                                <IconButton color={"secondary"} onClick={handleSave}><SaveIcon/></IconButton> : ""}
                        </Grid>
                        <Grid item xs={4} style={{textAlign: "right"}}>
                            {nextEnabled ? <IconButton color={"secondary"}
                                                       onClick={handleNext}><ArrowForwardIcon/></IconButton> : ""}
                        </Grid>
                    </Grid>


                </DialogContent>
            </form>
        </Dialog>
    )
}