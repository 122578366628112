import React from 'react';
import {Route, Switch} from "react-router-dom";
import ProjectsScreen from "./ProjectsScreen";


export default function ProjectsRouter() {

    return (
        <Switch>
            <Route exact path="/supervisor/project" component={ProjectsScreen}/>

        </Switch>
    );
}
