import React from 'react';
import {Route, Switch} from "react-router-dom";
import LoginScreen from "./login/LoginScreen";
import {AppMainContent} from "../layouts/AppMainContent";


export default function ScreenRouter() {
    return (
        <Switch>
            <Route path="/login" component={LoginScreen}/>
            <Route path="/supervisor" component={AppMainContent}/>
            <Route path="/settings" component={AppMainContent}/>
            <Route path="/:projectId" component={AppMainContent}/>
            <Route path="/" component={AppMainContent}/>
        </Switch>


    );
}
