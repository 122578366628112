import React from 'react';
import {Route, Switch} from "react-router-dom";
import BodychartScreen from "./BodychartScreen";
import CropScreen from "./crop/CropScreen";
import BodychartDetailScreen from "./detail/BodychartDetailScreen";


export default function BodychartRouter() {

    return (
        <Switch>
            <Route exact path="/supervisor/chart" component={BodychartScreen}/>
            <Route path="/supervisor/chart/:bcId/crop" component={CropScreen}/>
            <Route path="/supervisor/chart/:bcId" component={BodychartDetailScreen}/>
        </Switch>
    );
}
