import React, {useCallback, useContext, useEffect, useState} from "react";
import {fetchAllAssignedProjectsPage, fetchUserDetail, postUserUpdate} from "../../../utils/apiCaller";
import Divider from "@material-ui/core/Divider";
import {useHistory, useParams} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import debounce from 'lodash/debounce';
import {getSimplePassword} from "../../../utils/simplePasswordGenerator";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/Refresh";
import DialogActions from "@material-ui/core/DialogActions";
import {AppContext} from "../../../App";
import TextEntry from "../../../components/entries/TextEntry";
import SwitchEntry from "../../../components/entries/SwitchEntry";
import {EasyBreadcrumbs} from "../../../components/Breadcumbs";
import PaginatedTable from "../../../components/PaginatedTable";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const tableColumns = [
    {key: "name", name: "Name"},
    {key: "description", name: "Description"},
]

function UserDetailScreen() {
    const [state] = useContext(AppContext);
    const jwt = state.user.jwt
    const classes = useStyles();

    const [user, setUser] = useState({});
    const [userEdit, setUserEdit] = useState(false);
    const [open, setOpen] = useState(false);
    // stato usato solo per la generazione di una nuova password
    const [password, setPassword] = useState(getSimplePassword());

    const {userId} = useParams();
    const history = useHistory();

    const [pageArgs, setPageArgs] = useState()

    useEffect(() => {
        if (state)
            setPageArgs({
                jwt: state.user.jwt.token,
                userId: userId,
                projectId:""
            })
    }, [state,userId])

    useEffect(() => {
        // chiamo l'effect solo quando non ho un utente, se no si esegue due volte per niente...

            // carico i dettagli dell'utente
            fetchUserDetail(jwt.token, userId)
                .then(result => setUser(result))
                .catch(result => alert(result))


    }, [jwt, userId])

    const handleUserEdit = (payload) => {
        // qui il debouncing è gia stato fatto
        postUserUpdate(jwt.token, payload)
            .then(result => setUser(result))
            .catch(result => alert(result))

    }

    const handleChangePassword = () => {

        let data = {
            id: userId,
            password: password
        }

        postUserUpdate(jwt.token, data)
            .then(() => alert("Password changed"))
            .catch(result => alert(result))

        setOpen(false)

    }

    return (

        <Grid container>
            <Grid item xs={6} style={{height: 60}} className={classes.banner}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                         style={{
                             height: 55,
                             paddingTop: 16,
                             verticalAlign: "middle",
                             display: "table-cell",
                             paddingLeft: 16
                         }}>
                        <EasyBreadcrumbs breadcrumbs={[{destination: "/supervisor/user", label: "Users"}]}
                                         current={"User"}/>
                    </Box>
                </Typography>

            </Grid>

            <Grid item xs={6} className={classes.banner} style={{ textAlign:"right"}}>
                {!userEdit ?
                    <Button variant="outlined" color="primary" style={{marginRight: 16, marginTop:16}}
                            startIcon={<EditIcon/>} onClick={() =>
                        setUserEdit(!userEdit)
                    }>
                        Edit mode
                    </Button> :
                    <Button variant="outlined" color="primary" style={{marginRight: 16, marginTop:16}}
                            startIcon={<VisibilityIcon/>} onClick={() => setUserEdit(!userEdit)}>
                        View mode
                    </Button>
                }
                <Button style={{marginTop:16, marginRight: 16}} onClick={() => setOpen(true)} variant="outlined" color="secondary"
                        startIcon={<VpnKeyIcon/>}>
                    Reset password
                </Button>
            </Grid>

            <Grid item xs={12} style={{marginBottom: 16}}><Divider/></Grid>

            <Grid item xs={4} style={{padding:16}}>
                <BasicInfos user={user} edit={userEdit} onChange={handleUserEdit}/>
            </Grid>
            <Grid item xs={8} style={{padding:16}}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                        Member of projects
                    </Box>
                </Typography>
                {pageArgs && <PaginatedTable
                    fields={tableColumns}
                    requestArgs={pageArgs}
                    paramsPrefix={"us_"}
                    fetchingPageFunction={fetchAllAssignedProjectsPage}
                    onSelected={(e) => history.push("/" + e.id)}
                />}

                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle id="form-dialog-title">Reset password</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}
                              style={{
                                  margin: 0,
                                  width: '100%',
                              }}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="New password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton edge="end"
                                                            onClick={() => setPassword(getSimplePassword())}>
                                                    <RefreshIcon/>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleChangePassword} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

            </Grid>

        </Grid>
    )
}

function BasicInfos({user, edit, onChange}) {
    const [state] = useContext(AppContext);

    // in modo da disabilitare la possibilità di cambiare la voce supervisor a se stesso
    const authUser = state.user

    const handlePropChange = (prop, newValue) => {
        user[prop] = newValue
        debounceHandleChange(user)
    }

    // debounce della notifica verso il parend
    const debounceHandleChange = useCallback(debounce((p) => onChange(p), 800), []);

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} style={{marginBottom: 24}}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                        User details
                    </Box>
                </Typography>
            </Grid>
            <TextEntry
                editable={edit}
                onEdit={(value) => {
                    handlePropChange("name", value)
                }}
                label={"Name"}
                value={user.name}
                divider={false}
            />
            <TextEntry // 12 di larghezza
                editable={edit}
                onEdit={(value) => {
                    handlePropChange("surname", value)
                }}
                label={"Surname"}
                value={user.surname}
                divider={false}
            />
            <TextEntry // 12 di larghezza
                editable={edit}
                onEdit={(value) => {
                    handlePropChange("email", value)
                }}
                label={"Email address"}
                value={user.email}
                divider={false}
            />
            <SwitchEntry
                editable={edit}
                onEdit={(value) => {
                    handlePropChange("isSupervisor", value)
                }}
                label={"Supervisor access"}
                disabled={authUser.id === user.id}
                value={user.isSupervisor}
                divider={false}
            />
        </Grid>
    )
}


export default UserDetailScreen;