import React, {useContext, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import {fetchProjectsPage, putNewProject} from "../utils/apiCaller";
import {AppContext} from "../App";
import AdvancedTable from "../components/AdvancedTable";
import {ProjectCreation} from "./ProjectDialog";


const tableColumns = [
    {key: "name", name: "Name"},
    {key: "description", name: "Description"}
]


export default function ProjectSelectionDialog({open, onClose, onSelected, creationOnly = false}) {
    const [state, dispatch] = useContext(AppContext);
    const authUser = state.user
    const jwt = state.user.jwt

    const [newProject, setNewProject] = useState(creationOnly)
    const [newProjectData, setNewProjectData] = useState({name: "", description: ""})


    const handleNewProject = () => {
        // controllo che tutti i campi siano popolati
        if (newProjectData.name === "" || newProjectData.description === "")
            alert("Fill all the fields please")
        else
            // altrimenti procedo alla creazione
            putNewProject(jwt.token, newProjectData)
                .then(result => {
                    dispatch({type: "SHOW_ALERT", payload: {message: "New project created successfully"}})
                    onSelected(result.id)
                })
                .catch(result => alert(result.message))
    }

    return (
        <Dialog maxWidth={"md"} open={open} onClose={onClose}>
            <DialogTitle>
                {newProject ?
                    "Create a new project"
                    :
                    "Select a project"
                }
                {authUser.isSupervisor && !creationOnly &&
                    <Button style={{position: "absolute", top: 16, right: 16}}
                            color="default"
                            onClick={() => setNewProject(!newProject)}>
                        {newProject ? "Project list" : "New project"}
                    </Button>}
            </DialogTitle>


            {newProject ?
                <ProjectCreation
                    name={newProjectData.name}
                    desc={newProjectData.description}
                    onNameChange={(n) => {
                        setNewProjectData({name: n, description: newProjectData.description})
                    }}
                    onDescChange={(n) => {
                        setNewProjectData({name: newProjectData.name, description: n})
                    }}
                />
                :
                <ProjectSelectionList onSelected={onSelected}/>
            }


            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary">
                    Close
                </Button>

                {newProject ?
                    <Button
                        onClick={handleNewProject}
                        color="primary">
                        Save project
                    </Button>
                    :
                    ""
                }
            </DialogActions>
        </Dialog>
    )
}

function ProjectSelectionList({onSelected}) {
    const [state] = useContext(AppContext);

// stato che contiene i ruoli assegnabili
    const [filterName, setFilterName] = useState("");

    const handleProjectSelected = (id) => {
        onSelected(id)
    }


    return (
        <DialogContent style={{paddingRight: 0, paddingLeft: 0}}>
            <Grid container>
                <Grid item xs={12}
                      style={{paddingRight: 20, paddingLeft: 20}}>
                    <TextField
                        onChange={(e) => setFilterName(e.target.value)}
                        size={"small"}
                        label="Search project"
                        variant={"outlined"}
                        fullWidth
                        InputProps={{
                            startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
                        }}
                    />
                </Grid>
                <Grid item xs={12} style={{marginTop: 20}}>
                    <AdvancedTable
                        tableProps={{size: "small"}}
                        jwt={state.user.jwt.token}
                        fields={tableColumns}
                        filterName={filterName}
                        fetchingPageFunction={fetchProjectsPage}
                        onSelected={handleProjectSelected}
                    />
                </Grid>
            </Grid>
        </DialogContent>
    )
}