import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {fetchProjectDetails, postProjectUpdate} from "../../../utils/apiCaller";
import {AppContext} from "../../../App";
import {Button, Card, CardContent, CardHeader} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";


function ProjectDetails({projectId}) {
    const [state, dispatch] = useContext(AppContext)
    const [project, setProject] = useState();

    const [saving, setSaving] = useState(false);

    const [supervisorNotes, setSupervisorNotes] = useState("");
    const [projectNotes, setProjectNotes] = useState("");

    useEffect(() => {
        let interrupt = false

        fetchProjectDetails(state.user.jwt.token, projectId)
            .then((result) => {
                if (!interrupt)
                    setProject(result)
            })
            .catch((error) => {
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            })


        return () => {
            interrupt = true
        }
    }, [state.user, projectId, dispatch])

    useEffect(() => {
        if (!project)
            return

        setSupervisorNotes(project.supervisorNotes)
        setProjectNotes(project.projectNotes)

    }, [project])

    const handleNotesSave = () => {
        setSaving(true)
        let payload = {
            id: projectId,
            projectNotes: projectNotes
        }

        if (state.user.isSupervisor)
            payload["supervisorNotes"] = supervisorNotes

        postProjectUpdate(state.user.jwt.token, payload)
            .then(() =>
                dispatch({type: "SHOW_ALERT", payload: {message: "Updated successfully"}})
            )
            .catch((error) =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )
            .finally(() => setSaving(false))
    }

    return (
        <Card>
            <CardHeader
                title={"General information"}/>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item container xs={12} lg={6}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Name</Typography>
                        </Grid>
                        <Grid item xs={12} style={{minHeight:20}}>{project && project.name}</Grid>
                    </Grid>
                    <Grid item container xs={12} lg={6}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Description</Typography>
                        </Grid>
                        <Grid item xs={12}>{project && project.description}</Grid>
                    </Grid>
                    <Grid item container xs={12} lg={6}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Created by</Typography>
                        </Grid>
                        <Grid item xs={12}  style={{minHeight:20}}>{project && (project.creationUser || "unknown")}</Grid>
                    </Grid>
                    <Grid item container xs={12} lg={6}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Creation date</Typography>
                        </Grid>
                        <Grid item xs={12}>{project && (project.creationDateTimeFormatted || "unknown")}</Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={12}>
                            <Typography variant="h6" gutterBottom>Project notes</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField variant="outlined" value={projectNotes}
                                       multiline fullWidth rows={5}
                                       onChange={(e) => setProjectNotes(e.target.value)}/>
                        </Grid>
                    </Grid>
                    {state.user.isSupervisor &&
                        <Grid item container xs={12}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom>Supervisor notes</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField variant="outlined" value={supervisorNotes}
                                           multiline fullWidth rows={5}
                                           onChange={(e) => setSupervisorNotes(e.target.value)}/>
                            </Grid>
                        </Grid>}
                    <Grid item xs={12} container justify={"space-between"} alignItems={"flex-end"}>
                        <Grid item>
                            <Button variant={"contained"} onClick={handleNotesSave}>
                                Save all
                            </Button>
                        </Grid>
                        <Grid item>
                            <Fade in={saving} style={{transitionDelay: '400ms'}} unmountOnExit>
                                <CircularProgress color={"secondary"} size={30}/>
                            </Fade>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}


export default ProjectDetails;