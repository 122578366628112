import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";


export default function DescriptorStep3({onChange, kind}) {


    const handleChange = (payload) => {
        onChange(payload, validatePayload(payload))
    };

    // metodo per verificare se il payload ha tutti i suoi campi obbligatori
    const validatePayload = (payload) => {
        const p = payload

        // https://stackoverflow.com/questions/5515310/is-there-a-standard-function-to-check-for-null-undefined-or-blank-variables-in

        if (kind === 'OptionBasedDescriptor') {
            if (p.name && p.options)
                return true;
        } else if (kind === 'RangeBasedDescriptor') {
            if (p.name &&
                (p.max || p.max === 0) &&
                (p.min || p.min === 0) &&
                (p.step || p.step === 0))
                return true;
        } else if (kind === 'PointBasedDescriptor') {
            if (p.name)
                return true
        }else if (kind === 'SpotBasedDescriptor') {
            if (p.name)
                return true
        }
        return false
    }

    switch (kind) {
        case 'OptionBasedDescriptor':
            return <OptionBased onChange={handleChange}/>;
        case 'RangeBasedDescriptor':
            return <RangeBased onChange={handleChange}/>;
        case 'PointBasedDescriptor':
            return <PointBased onChange={handleChange}/>;
        case 'SpotBasedDescriptor':
            return <SpotBased onChange={handleChange}/>;
        default:
            return <></>
    }

}


function OptionBased({onChange}) {
    const [payload, setPayload] = useState({});

    const handleUpdate = (prop, value)=>{
        payload[prop] = value
        setPayload(payload)
        onChange(payload)
    }

    const handleOptionsUpdate = (value)=>{
        payload.options = value.split("\n")
        setPayload(payload)
        onChange(payload)
    }

    return (
        <Grid container spacing={2}
              style={{
                  margin: 0,
                  width: '100%',
              }}
        >
            <Grid item xs={12}>
                <TextField onChange={(event => handleUpdate("name",event.target.value))} fullWidth label={"Name"}/>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    onChange={(event => handleOptionsUpdate(event.target.value))}
                    fullWidth
                    multiline
                    placeholder={"Option1\nOption2\nOption3\n..."}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    label={"Options"}/>
            </Grid>
            <Grid item xs={12}>
                <TextField onChange={(event => handleUpdate("description",event.target.value))} fullWidth multiline label={"Description"}/>
            </Grid>
        </Grid>
    )
}

function RangeBased({onChange}) {
    const [payload, setPayload] = useState({});

    const handleUpdate = (prop, value)=>{
        payload[prop] = value
        setPayload(payload)
        onChange(payload)
    }

    return (
        <Grid container spacing={2}
              style={{
                  margin: 0,
                  width: '100%',
              }}
        >
            <Grid item xs={12}>
                <TextField onChange={(event => handleUpdate("name",event.target.value))} fullWidth label={"Name"}/>
            </Grid>
            <Grid item xs={6}>
                <TextField onChange={(event => handleUpdate("min", parseFloat(event.target.value)))} type="number" fullWidth label={"Min"}/>
            </Grid>
            <Grid item xs={6}>
                <TextField onChange={(event => handleUpdate("max",parseFloat(event.target.value)))} type="number" fullWidth label={"Max"}/>
            </Grid>
            <Grid item xs={6}>
                <TextField onChange={(event => handleUpdate("step",parseFloat(event.target.value)))} type="number" fullWidth label={"Increase step"}/>
            </Grid>
            <Grid item xs={6}>
                <TextField onChange={(event => handleUpdate("unitOfMeasure",event.target.value))} fullWidth label={"Unit of measure"}/>
            </Grid>
            <Grid item xs={12}>
                <TextField onChange={(event => handleUpdate("description",event.target.value))} type="number" fullWidth multiline label={"Description"}/>
            </Grid>
        </Grid>
    )
}

function PointBased({onChange}) {

    const [payload, setPayload] = useState({});

    const handleUpdate = (prop, value)=>{
        payload[prop] = value
        setPayload(payload)
        onChange(payload)
    }

    return (
        <Grid container spacing={2}
              style={{
                  margin: 0,
                  width: '100%',
              }}
        >
            <Grid item xs={12}>
                <TextField onChange={(event => handleUpdate("name",event.target.value))} fullWidth multiline label={"Name"}/>
            </Grid>
            <Grid item xs={12}>
                <TextField onChange={(event => handleUpdate("description",event.target.value))}  fullWidth multiline label={"Description"}/>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked={false}
                            onChange={(event => handleUpdate("inSpot",event.target.value))}
                            color="primary"
                        />
                    }
                    label="Must be inside a spot"
                />
             </Grid>

        </Grid>
    )
}

function SpotBased({onChange}) {

    const [payload, setPayload] = useState({});

    const handleUpdate = (prop, value)=>{
        payload[prop] = value
        setPayload(payload)
        onChange(payload)
    }

    return (
        <Grid container spacing={2}
              style={{
                  margin: 0,
                  width: '100%',
              }}
        >
            <Grid item xs={12}>
                <TextField onChange={(event => handleUpdate("name",event.target.value))} fullWidth multiline label={"Name"}/>
            </Grid>
            <Grid item xs={12}>
                <TextField onChange={(event => handleUpdate("description",event.target.value))}  fullWidth multiline label={"Description"}/>
            </Grid>

        </Grid>
    )
}
