import React from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import TextEntry from "./entries/TextEntry";
import DateEntry from "./entries/DateEntry";

export default function BasicInfos({patient, edit, onChange}) {

    const handlePropChange = (prop, newValue) => {
        onChange(prop, newValue)
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                        Info
                    </Box>
                </Typography>
            </Grid>
            <DateEntry
                editable={edit}
                disableFuture
                onEdit={(value) => {
                    handlePropChange("birthTimestamp", value)
                }}
                label={"Birth"}
                value={patient.birthTimestamp}
                divider={false}
            />
            <TextEntry
                editable={false}
                onEdit={(value) => {
                    handlePropChange("gender", value)
                }}
                label={"Gender"}
                value={patient.gender}
                divider={false}
            />
            <TextEntry
                editable={edit}
                onEdit={(value) => {
                    handlePropChange("height", value)
                }}
                label={"Height"}
                inputMode={"number"}
                value={patient.height}
                divider={false}
            />
            <TextEntry
                editable={edit}
                onEdit={(value) => {
                    handlePropChange("weight", value)
                }}
                label={"Weight"}
                inputMode={"number"}
                value={patient.weight}
                divider={false}
            />

        </Grid>
    )
}