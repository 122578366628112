import React, {useContext} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {AppContext} from "../App";
import {putNewFeedback} from "../utils/apiCaller";

export default function FeedBackDialog({onClose, open, src, location}) {
    const [state] = useContext(AppContext);
    const jwt = state.user.jwt

    const [attachment, setAttachment] = React.useState(true);
    const [comment, setComment] = React.useState("");

    const handleClose = () => {
        onClose()
        setComment("")
    }

    const handleSubmit = () => {
        const payload = {
            comments:comment,
            url: location.pathname
        }

        if(attachment){
            payload.imageData = src
        }

        putNewFeedback(jwt.token,payload)
            .then(onClose)
            .catch(error => alert(error))
    }

    return (
        <Dialog maxWidth={"sm"} open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title">Send feedback</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField label="Comment" fullWidth value={comment} multiline onChange={(event => setComment(event.target.value))}/>
                        </Grid>
                        <Grid item xs={12}>
                            <img src={src} alt={"screenshot"} style={{width: "100%", border: "1px solid #bdbdbd"}}/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={attachment}
                                        onChange={() => setAttachment(prevState => !prevState)}
                                        color="primary"
                                    />
                                }
                                label="Attach screenshot"
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Send
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}