import React, {useContext, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {putMassiveImport} from "../utils/apiCaller";
import CircularProgress from "@material-ui/core/CircularProgress";
import {AppContext} from "../App";
import FileInput from "../components/FileInput";
import Grid from "@material-ui/core/Grid";
import {useParams} from "react-router-dom";

export default function MassiveImportDialog({open, onClose, onImported}) {
    const [state] = useContext(AppContext)
    const jwt = state.user.jwt

    const [file, setFile] = useState();
    const {projectId} = useParams();

    const [importLoading, setImportLoading] = useState(false);

    const handleMassiveImport = () => {
        setImportLoading(true)
        const payload = {
            data: file
        }

        putMassiveImport(jwt.token, payload, projectId)
            .then(onImported)
            .catch(e => alert(e.message))
            .then(() => setImportLoading(false))
    }


    return (
        <Dialog maxWidth={"sm"} fullWidth open={open} onClose={onClose} onExited={() => {
        }}>
            <DialogTitle>
                Import new manual session
            </DialogTitle>
            <DialogContent>

                    <Grid container spacing={2} justify={"space-between"}>
                        <Grid item xs={12} style={{marginBottom: 2}}>
                            <FileInput onSelected={setFile} label={"File"}/>
                        </Grid>
                        <Grid item>
                            <Button variant={"outlined"} onClick={handleMassiveImport}>
                                {importLoading && <CircularProgress size={25}/>}
                                Import
                            </Button>
                        </Grid>
                    </Grid>

            </DialogContent>
        </Dialog>
    )
}