import React, {useState} from "react";
import {Card} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";


function OptionBased({descriptor, onUpdate, value}) {

    const [option, setOption] = useState(value ? value.value : null)

    return (
        <Card>
            <CardHeader
                title={descriptor.name || descriptor.key}
                subheader={descriptor.isMandatory ? <div style={{color: "red"}}>Mandatory</div> : null}
            />
            <CardContent>
                <Autocomplete
                    options={descriptor.options}
                    getOptionLabel={option => option}
                    value={option}
                    onChange={(event, value) => {
                        setOption(value)
                        onUpdate(descriptor.id, value)
                    }}
                    renderInput={params => <TextField fullWidth{...params} label="Value"/>}
                />

            </CardContent>
        </Card>
    )
}

export default OptionBased