import React from "react";
import Typography from "@material-ui/core/Typography";
import './Style.css'
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

export function EasyBreadcrumbs({breadcrumbs=[], current = ""}) {

    return(
        <Breadcrumbs aria-label="breadcrumb">
            {breadcrumbs.map(br =>
                <Link color="inherit" component={RouterLink} to={br.destination} key={br.destination}>
                    {br.label}
                </Link>
            )}
            <Typography color="textPrimary">{current}</Typography>
        </Breadcrumbs>
    )
}