import {Route, Switch} from "react-router-dom";

import React from "react";
import ServiceScreen from "./ServiceScreen";
import ServiceRequestJobs from "./ServiceRequestJobs";
import ServiceResultScreen from "./result/ServiceResultScreen";

export default function ServicesRouter() {

    return (
        <Switch>
            <Route exact path="/:projectId/service" component={ServiceScreen}/>
            <Route exact path="/:projectId/service/jobs" component={ServiceRequestJobs}/>
            <Route exact path="/:projectId/service/result/:requestId" component={ServiceResultScreen}/>
        </Switch>
    );
}
