import React from "react";
import Typography from "@material-ui/core/Typography";
import './Style.css'
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

function PainValues({values = [], valuesLabel = "Values"}) {

    return (
        <Typography component="div">
            <Grid container spacing={0}>
                <Grid item xs={12} style={{marginTop: 16}}>
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                        {valuesLabel}
                    </Box>
                </Grid>
                {values.map(v => <ValueDisplay data={v} key={v.id}/>)}
            </Grid>


        </Typography>
    )
}

export function ValueDisplay({data}) {
    const value = data

    const highlightColor = value.color

    return (
        <>

            <Grid item xs={6}>
                <Box style={{marginLeft: 10}} fontWeight="fontWeightLight"
                     fontSize={"h6.fontSize"}>
                    {value.name}
                </Box>
            </Grid>

            <Grid item xs={6}>
                <Box style={{marginLeft: 10}} fontWeight="fontWeightLight"
                     fontSize={"h6.fontSize"}>

                    {highlightColor ?
                    <svg height="20" width="20">
                        <circle cx="10" cy="10" r="10" stroke="white"
                                style={{fill: highlightColor}}
                                strokeWidth="1"/>
                    </svg>
                        :
                        (value.value + " " + (value.unitOfMeasure || ""))
                    }
                </Box>
            </Grid>


        </>
    )
}


export default PainValues;