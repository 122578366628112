import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PatientCreation from "./PatientCreation";
import PatientSelection from "./PatientSelection";

export default function PatientDialog({
                                          open,
                                          onClose,
                                          projectId,
                                          onSelected,
                                          creationOnly = false,
                                          sexConstraint = false,
                                          female = false
                                      }) {


    const [creationMode, setCreationMode] = useState(creationOnly)

    const handleSelectionModeSwitch = () => {
        if (!creationOnly)
            setCreationMode(!creationMode)
    }

    const handleOnSelected = (patient) => {
        onSelected(patient.id)
        onClose()
    }

    return (
        <Dialog fullWidth maxWidth={"sm"} open={open} onClose={onClose}>
            <DialogTitle>

                <Grid container justify={"space-between"}>
                    {creationMode ?
                        <Grid item>Create a new patient</Grid>
                        :
                        <Grid item>Select existing patient</Grid>
                    }
                    <Grid item>
                        <Box hidden={creationOnly} mr={2}>
                            <Button
                                hidden={creationOnly}
                                onClick={handleSelectionModeSwitch}>
                                {creationMode ? "Select existing" : "Create new"}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {
                    creationMode ?
                        <PatientCreation
                            sexConstraint={sexConstraint}
                            female={female}
                            projectId={projectId}
                            onSaved={handleOnSelected}/>
                        :
                        <PatientSelection
                            onNewPatient={handleSelectionModeSwitch}
                            onSelected={handleOnSelected}
                            sexConstraint={sexConstraint}
                            female={female}
                        />
                }

            </DialogContent>
        </Dialog>
    )
}