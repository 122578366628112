import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";

export default function SwitchEntry({
                                        editable,
                                        onEdit,
                                        label,
                                        value,
                                        visible = true,
                                        disabled = false,
                                        divider = true
                                    }) {
    const textValue = value ? "Yes" : "No"

    useEffect(() => {
        if (editable && value === undefined) {
            onEdit(false)
        }
    }, [editable, value, onEdit])

    const handleValueChange = (value) => {
        if (onEdit !== null)
            onEdit(value)
    }

    if (!visible)
        return <></>

    return (
        <>
            <Grid item xs={5}>
                <Typography component="div">
                    <Box style={{margin: 8}} fontSize="h6.fontSize" fontWeight="fontWeightLight">
                        {label}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                {
                    editable ?
                        <div>
                            <Checkbox
                                checked={value}
                                disabled={disabled}
                                onChange={(e) => handleValueChange(e.target.checked)}
                                inputProps={{'aria-label': 'secondary checkbox'}}
                            />
                            {disabled ?
                                <Typography variant="body2" style={{color: "#626262"}} gutterBottom>
                                    Unable to edit this property for yourself
                                </Typography> : ""}
                        </div> :
                        <Typography component="div">
                            <Box style={{margin: 8}} fontSize="h6.fontSize" fontWeight="fontWeightLight">
                                {value === undefined ? "Unknown" : textValue}
                            </Box>
                        </Typography>

                }
            </Grid>
            <Grid item xs={12}>
                {divider ? <Divider/> : ""}
            </Grid>

        </>
    )

}