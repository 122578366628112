import React, {useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import {authenticate, fetchAuthUserDetail} from "../../utils/apiCaller";
import {AppContext} from "../../App";
import {Redirect} from "react-router-dom";
import jwt_decode from 'jwt-decode';
import {parse} from "qs";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import checkToken from "../../utils/authetnicationChecker";

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        paddingTop: 50,
        paddingBottom: 50,
    },
    cover: {
        width: "100%"
    }
}));


export default function LoginScreen({location}) {

    const classes = useStyles();

    const userMail = parse(location.search, {ignoreQueryPrefix: true}).new
    const expired = !!parse(location.search, {ignoreQueryPrefix: true}).expired

    const [state, dispatch] = useContext(AppContext);

    const [email, setEmail] = useState(userMail ? userMail : window._env_.DEV === "true" ? "admin@supsi.ch" : "")
    const [password, setPassword] = useState(userMail ? "" : window._env_.DEV === "true" ? "supsi" : "")
    const [loggingIn, setLoggingIn] = useState(false)
    const [loginError, setLoginError] = useState("")

    const [tokenExpired, setTokenExpired] = useState(false)

    useEffect(() => {
        if (expired)
            setTokenExpired(true)
    }, [expired])

    const handleLogin = () => {
        setLoggingIn(true)
        setLoginError("")

        authenticate(email, password)
            .then(jwt => {
                // ho in mano il token valido
                // carico le info dell'utente
                fetchAuthUserDetail(jwt.token)
                    .then(userDetails => {

                        let decodedJwt = jwt_decode(jwt.token)

                        userDetails.jwt = {
                            token: jwt.token,
                            expire: decodedJwt.exp
                        }

                        // aggiorno lo stato con l'utente e il suo token
                        dispatch({
                            type: "LOGIN",
                            payload: userDetails
                        })


                    })
                    .catch(error => {
                        setLoginError("Server error: " + error)
                        setLoggingIn(false)
                    })
            })
            .catch(error => {
                setLoginError(error.message)
                setLoggingIn(false)
            })


    }


    // se autenticato allora rimando alla home
    if (state.user && checkToken(state.user.jwt))
        return <Redirect to={"/"}/>
    return (
        <Grid
            style={{
                position: "absolute",
                top: "0",
                bottom: "0"
            }}
            container
            justify="center"
            alignItems="center"
            spacing={0}
        >
            <Grid item xs={6}>
                <Paper>
                    <Grid container
                          justify="center"
                          alignItems="center">
                        <Grid item xs={6}>
                            <img className={classes.cover} src={"/logo.png"} alt={"logo"}/>
                        </Grid>
                        <Grid item xs={6}>

                            <Grid container
                                  spacing={3}
                                  style={{width: "100%", paddingTop: 50, paddingBottom: 50, paddingRight: 25}}
                                  justify="flex-end"
                            >
                                <Grid item xs={10}>
                                    <TextField
                                        fullWidth
                                        type={"email"}
                                        label="Email"
                                        variant="outlined"
                                        value={email}
                                        onChange={(event => setEmail(event.target.value))}
                                        disabled={loggingIn}
                                    />
                                </Grid>

                                <Grid item xs={10}>
                                    <TextField
                                        fullWidth
                                        type={"password"}
                                        label="Password"
                                        variant="outlined"
                                        value={password}
                                        disabled={loggingIn}
                                        onChange={(event => setPassword(event.target.value))}
                                    />
                                </Grid>

                                <Grid item xs={10} style={{textAlign: "right"}}>
                                    <Button
                                        variant={"contained"}
                                        size={"large"}
                                        color="primary"
                                        onClick={handleLogin}
                                    >
                                        {loggingIn ? <CircularProgress size={24}/> : "Login"}
                                    </Button>
                                </Grid>

                                <Grid hidden={!loginError} item xs={8} style={{textAlign: "right"}}>
                                    <Typography variant="body1" color={"error"}>
                                        {loginError}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <Grid container justify={"space-between"}>
                    <Grid item>
                        <Typography variant="body1" color={"primary"}>
                            SUPSI - Sketch Your Pain
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" color={"primary"}>
                            version {window._env_.version}
                        </Typography></Grid>
                </Grid>


                <Snackbar open={tokenExpired} autoHideDuration={6000}
                          anchorOrigin={{horizontal: "center", vertical: "top"}}
                          onClose={() => setTokenExpired(false)}>
                    <Alert onClose={() => setTokenExpired(false)} severity="warning">
                        Token expired, please login again
                    </Alert>
                </Snackbar>

            </Grid>
        </Grid>

    );
}
