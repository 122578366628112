import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import TabletAndroidIcon from '@material-ui/icons/TabletAndroid';
import {useParams} from "react-router-dom";
import Box from "@material-ui/core/Box";
import BodyChartMultipleSelectionDialog from "../../../dialogs/bodychart/selection/BodyChartMultipleSelectionDialog";
import DrawingCard from "../../../components/DrawingCard";
import {AppContext} from "../../../App";
import {fetchAllProjectBodyCharts, postProjectBcMove} from "../../../utils/apiCaller";
import {Menu, MenuItem} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));


function BodyCharts() {
    const classes = useStyles();

    const [state, dispatch] = useContext(AppContext)
    const jwt = state.user.jwt
    const {projectId} = useParams()

    // stato che contiene la lista di bc
    const [listData, setListData] = useState([]);
    const [chartsEdit, setChartsEdit] = useState(false);
    const [chartsPaper, setChartsPaper] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState({mouseY: null, mouseX: null});

    useEffect(() => {
        fetchAllProjectBodyCharts(jwt.token, projectId)
            .then(result => setListData(result))
            .catch(result => alert(result))
    }, [jwt, projectId])

    const handleContext = (event, id) => {
        event.preventDefault()
        setAnchorEl({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            id: id
        })
    }

    const handleBcMove = (right) => {

        postProjectBcMove(jwt.token, projectId, anchorEl.id, right ? "right" : "left")
            .then(result => {
                dispatch({type: "SHOW_ALERT", payload: {message: "Order updated"}})
                setListData(result)
            })
            .catch(result => alert(result))

        setAnchorEl({mouseY: null, mouseX: null})
    }

    const handleBcChanged = () => {

        setChartsEdit(false)

        fetchAllProjectBodyCharts(jwt.token, projectId)
            .then(result => setListData(result))
            .catch(result => alert(result.message))
    }

    const handleToggleClick = (e, value) => {
        if(value === null)
            return

        if (chartsPaper !== value)
            setChartsPaper(value)
    }

    return (
        <Grid container>
            <Grid item xs={6} className={classes.banner}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 60,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16
                     }}>
                    Body Charts
                </Box>
            </Grid>
            <Grid item xs={6} className={classes.banner} style={{textAlign: "right"}}>

                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{height: 68, paddingLeft: 16, paddingRight: 16, paddingTop: 5}}
                    spacing={1}
                >
                    <Grid item>
                        <ToggleButtonGroup
                            style={{marginRight: 10, marginTop: 3}}
                            value={chartsPaper}
                            size={"small"}
                            exclusive
                            onChange={handleToggleClick}
                        >
                            <ToggleButton value={false} aria-label="approx">
                                <TabletAndroidIcon/>
                            </ToggleButton>
                            <ToggleButton value={true} aria-label="raw">
                                <InsertDriveFileIcon/>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>

                    <Grid item>
                        <Button variant="outlined" color="primary"
                                startIcon={<EditIcon/>} onClick={() =>
                            setChartsEdit(prevState => !prevState)
                        }>
                            Edit
                        </Button>

                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12} style={{padding: 16}}>
                <Grid container spacing={2}>
                    {listData.map((bodyChart) => (
                        <Grid item xs={2} key={bodyChart.id}
                              onContextMenu={event => handleContext(event, bodyChart.id)}>
                            <DrawingCard title={bodyChart.name}
                                         url={chartsPaper ? bodyChart.paperThumbnailUrl : bodyChart.digitalThumbnailUrl}/>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            <Menu
                keepMounted
                open={!!anchorEl.mouseY}
                onClose={() => setAnchorEl({mouseY: null, mouseX: null})}
                anchorReference="anchorPosition"
                anchorPosition={
                    anchorEl.mouseY !== null && anchorEl.mouseX !== null
                        ? {top: anchorEl.mouseY, left: anchorEl.mouseX}
                        : undefined
                }
            >
                <MenuItem onClick={() => handleBcMove(true)}>Move right</MenuItem>
                <MenuItem onClick={() => handleBcMove(false)}>Move left</MenuItem>
            </Menu>

            <BodyChartMultipleSelectionDialog
                open={chartsEdit}
                onClose={() => setChartsEdit(false)}
                onSaved={handleBcChanged}
            />
        </Grid>
    )
}

export default BodyCharts;