import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {useParams} from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {AppContext} from "../../../App";
import {fetchDescriptorDetail, postDescriptorUpdate} from "../../../utils/apiCaller";
import {EasyBreadcrumbs} from "../../../components/Breadcumbs";
import GenericPreview from "../../../components/descriptor/preview/GenericPreview";
import GenericEdit from "../../../components/descriptor/edit/GenericEdit";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));
function DescriptorDetailScreen() {
    const classes = useStyles();
    const [state] = useContext(AppContext);

    const jwt = state.user.jwt

    // stato che contiene la lista di descriptors globali
    const [descriptor, setDescriptor] = useState({});
    const [snackOpen, setSnackOpen] = useState(false);

    const {descriptorId} = useParams();

    useEffect(() => {
        fetchDescriptorDetail(jwt.token, descriptorId)
            .then(result => setDescriptor(result))
            .catch(result => alert(result))

    }, [descriptorId, jwt])

    const handleDescriptorUpdate = (payload) => {
        postDescriptorUpdate(jwt.token, payload)
            .then(result => {
                setDescriptor(result)
                setSnackOpen(true)
            })
            .catch(result => alert(result))
    }


    let defaultValue = "";

    switch (descriptor.kind) {
        case "RangeBasedDescriptor":
            defaultValue = descriptor.min
            break
        default:
            break
    }


    return (

        <Grid container>
            <Grid item xs={12} style={{height: 60}} className={classes.banner}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                         style={{
                             height: 55,
                             paddingTop: 16,
                             verticalAlign: "middle",
                             display: "table-cell",
                             paddingLeft: 16
                         }}>
                        <EasyBreadcrumbs breadcrumbs={[{destination: "/settings/descriptor", label: "Descriptors"}]}
                                         current={"Descriptor"}/>
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={12} style={{marginBottom: 16}}><Divider/></Grid>
            <Grid item xs={8} style={{padding: 16}}>

                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                        Settings
                    </Box>
                </Typography>
                <GenericEdit descriptor={descriptor} onSave={handleDescriptorUpdate}/>
            </Grid>
            <Grid item xs={4} style={{padding: 16}}>

                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                        Preview
                    </Box>
                </Typography>
                <GenericPreview backgroundColor={"white"} descriptor={descriptor} defaultValue={defaultValue}/>
            </Grid>

            <Grid item xs={12}>
                <Snackbar open={snackOpen} autoHideDuration={1500} onClose={() => setSnackOpen(false)}
                          anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                          }}>
                    <Alert severity="success"> Saved successfully </Alert>
                </Snackbar>
            </Grid>

        </Grid>


    )
}

export default DescriptorDetailScreen;