import React from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";


export default function BodyChartStep6({onChange, onFemaleChange}) {


    return (
        <Grid container spacing={3}
              style={{
                  margin: 0,
                  width: '100%',
              }}
            alignItems={"center"}
              justify={"center"}
        >
            <Grid item xs={12}>
                <Typography variant="body2" style={{color: "#626262"}} gutterBottom>
                    Now it's time to give a name to the Body Chart. Then press the save icon at the bottom.
                </Typography>
            </Grid>
            <Grid item xs={2} style={{textAlign:"right"}}>
                <Typography variant="body1" gutterBottom>
                    Name
                </Typography>
            </Grid>
            <Grid item xs={5}>
                <TextField size={"small"} onChange={(event) => onChange(event.target.value)} fullWidth variant={"outlined"}/>
            </Grid>
            <Grid item xs={5} style={{textAlign:"center"}}>
                <Typography variant="body1" gutterBottom>
                    <FormControlLabel
                        control={<Switch onChange={(event, checked) => onFemaleChange(checked)} defaultValue={false} color="primary" />}
                        label="Female"
                        labelPlacement="start"
                    />
                </Typography>
            </Grid>
        </Grid>
    )
}
