import React from 'react';
import {Route, Switch} from "react-router-dom";
import DescriptorsList from "./DescriptorsList";


export default function DescriptorRouter() {

    return (
        <Switch>
            <Route exact path="/:projectId/descriptor" component={DescriptorsList}/>
            <Route path="/:projectId/descriptor/:page" component={DescriptorsList}/>
        </Switch>
    );
}
