import React, {useContext, useEffect, useState} from "react";

import {AppContext} from "../../../App";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {useHistory, useParams} from "react-router-dom";
import {fetchAllRequestsPage} from "../../../utils/apiCaller";
import PaginatedTable from "../../../components/PaginatedTable";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlurCircularIcon from '@material-ui/icons/BlurCircular';
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));



const getRowIcon = (row) => {
    let icon
    let message
    if (row.error) {
        icon = <Tooltip title={row.errorMessage}>
            <HighlightOffIcon/>
        </Tooltip>
        message = "ERROR"
    } else if (row.fulfilled) {
        icon = <CheckCircleOutlineIcon/>
        message = "DONE"
    } else if (row.elapsedSeconds > 120) {
        icon = <ErrorOutlineIcon/>
        message = "TIMEOUT"
    } else {
        icon = <BlurCircularIcon/>
        message = "RUNNING"
    }

    return (
        <Grid container spacing={2} alignItems={"center"}>
            <Grid item>{icon}</Grid>
            <Grid item>{message}</Grid>
        </Grid>
    )
}

const renderDuration = (row) =>{
    if(row.duration)
        return `${row.duration}s`
}

const fields = [
    {key: "icon", name: "", renderingFunc: getRowIcon, sorting:false},
    {key: "serviceName", name: "Service", sorting:false},
    {key: "requester", name: "Requester", sorting:false},
    {key: "timeFormatted", name: "Date", sort:"requestTime"},
    {key: "duration", name: "Duration [s]", sort:"durationSeconds", renderingFunc: renderDuration},
]

export default function ServiceRequestJobs() {
    const classes = useStyles();

    const [state] = useContext(AppContext);
    const history = useHistory();

    const {projectId} = useParams()

    const [pageArgs, setPageArgs] = useState()

    useEffect(() =>{
        if(projectId && state)
        setPageArgs({
            projectId: projectId,
            jwt: state.user.jwt.token
        })
    },[projectId, state])

    const handleJobSelected = (row) => {
        history.push(`/${projectId}/service/result/${row.id}`)
    }

    return (
        <Grid container>
            <Grid item xs={12} className={classes.banner}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 60,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16
                     }}>
                    Services requests
                </Box>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>

            <Grid item xs={12}>
                {pageArgs && <PaginatedTable
                    autoRefreshMs={2000}
                    defaultSort={"requestTime"}
                    idField={"id"}
                    fetchingPageFunction={fetchAllRequestsPage}
                    requestArgs={pageArgs}
                    fields={fields}
                    onSelected={handleJobSelected}
                    paramsPrefix={"r_"}
                />}
            </Grid>
        </Grid>
    )
}
