import React, {useCallback, useContext, useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import debounce from "lodash/debounce";
import {fetchAllSelectableDescriptors} from "../../../utils/apiCaller";
import {AppContext} from "../../../App";


export default function SelectionStep1({onSelected, projectId, disablePointSpot}) {
    const [state] = useContext(AppContext);
    const jwt = state.user.jwt

    // stato che contiene i descriptors assegnabili
    const [listData, setListData] = useState({content: [], size: 5, totalElements: 0, totalPages: 0, number: 0});
    const [filterName, setFilterName] = useState("");


    useEffect(() => {
        fetchAllSelectableDescriptors(jwt.token, projectId, filterName, 0, 10)
            .then(result => setListData(result))
            .catch(result => alert(result))

    }, [filterName, jwt, projectId])

// debouncing sull update dell'utente
    const debounceHandleChange =
        useCallback(debounce((name) => {
            setFilterName(name)
        }, 500), []);

// cambiamento pagina
    const handleChangePage = (event, newPage) => {
        fetchAllSelectableDescriptors(jwt.token, projectId, filterName, newPage, listData.size)
            .then(result => setListData(result))
            .catch(result => alert(result))

    };

// cambiamento righe per pagina
    const handleChangeRowsPerPage = event => {
        fetchAllSelectableDescriptors(jwt.token, projectId, filterName, listData.number, parseInt(event.target.value))
            .then(result => setListData(result))
            .catch(result => alert(result))
    };


    const getKindNameSimple = (kind) => {
        switch (kind) {
            case "SpotBasedDescriptor":
                return "Spot based"
            case "PointBasedDescriptor":
                return "Point based"
            case "OptionBasedDescriptor":
                return "Option based"
            case "RangeBasedDescriptor":
                return "Range based"
            default:
                return ""
        }
    }

    return (
        <Grid container>
            <Grid item xs={12}
                  style={{paddingRight: 20, paddingLeft: 20, marginTop: 10}}>
                <TextField
                    onChange={(e) => debounceHandleChange(e.target.value)}
                    size={"small"}
                    label="Search descriptor"
                    variant={"outlined"}
                    fullWidth
                    InputProps={{
                        startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
                    }}
                />
            </Grid>
            <Grid item xs={12} style={{marginTop: 20}}>
                <TableContainer>
                    <Table size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Kind</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listData.content.map((row) => {

                                const disabled =
                                    (row.descriptor.kind === "SpotBasedDescriptor" || row.descriptor.kind === "PointBasedDescriptor") && disablePointSpot

                                return (
                                    <TableRow hover={!disabled}
                                              style={{
                                                  cursor: !disabled ? "pointer" : "",
                                                  color: disabled ? "lightgray" : ""
                                              }}
                                              key={row.descriptor.id}
                                              onClick={() => {
                                                  if (!row.selected && !disabled) onSelected(row.descriptor)
                                              }}>
                                        <TableCell style={{color: "inherit"}}>{row.descriptor.name}</TableCell>
                                        <TableCell style={{color: "inherit"}}>{row.descriptor.description}</TableCell>
                                        <TableCell style={{color: "inherit"}}>{getKindNameSimple(row.descriptor.kind)}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={listData.totalElements}
                    rowsPerPage={listData.size}
                    page={listData.number}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

            </Grid>
        </Grid>
    )
}