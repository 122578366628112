import React from "react";
import {v4 as uuidv4} from 'uuid';
import './Style.css'

export default function ImageMapper(
    {
        onClick = () => {
        },
        src,
        areas = [],
        infoPoints = [],
        alt = "image",
        cursor = "default",
        referenceSize = {x: 1536, y: 2048}  // dimensione di riferimento dell'immagine
        // usato per convertire le coordinate
    }
) {
    // carico il jwt dallo store redux

    const imageId = uuidv4();

    const [imageBrect, setImageBrect] = React.useState(null);

    const onImageLoad = (event) => {
        setImageBrect(event.target.getBoundingClientRect())
    }

    // calcoli i scaling factor per avere le coordinate relazionate alla dimensione del canvas
    const xScaling = (imageBrect ? imageBrect.width : 0) / referenceSize.x
    const yScaling = (imageBrect ? imageBrect.height : 0) / referenceSize.y

    const handleAreaClick = (event, area) => {
        onClick(getClickCoordinates(event), area)
    }

    const handleNoAreaClick = (event) => {
        onClick(getClickCoordinates(event))
    }

    // ottengo le coordinate del click in base all'immagine
    const getClickCoordinates = (event) => {
        // le coordinate sono da ottenere in base all'immagine non al target dell'evento!
        const image = document.getElementById(imageId)
        const dom = image.getBoundingClientRect()
        let x = (event.pageX - dom.x);
        let y = ((event.pageY-window.scrollY) - dom.y);


        let resX = Math.round(x / xScaling)
        let resY = Math.round(y / yScaling)

        return {x: resX, y: resY}
    }

    const scaleCoords = (coords) => {
        const scaledCoords = []
        for (let i = 0; i < (coords.length - 1); i += 2) {
            if (i % 1 === 0) {
                let x = Math.floor(coords[i] * xScaling)
                let y = Math.floor(coords[i + 1] * xScaling)

                scaledCoords.push(x)
                scaledCoords.push(y)
            }
        }
        return scaledCoords
    }

    return (
        <div className="mapperWrapper">
            <img onLoad={onImageLoad} alt={alt} src={src} id={imageId} className="coveredImage"/>
            <svg
                width={imageBrect ? imageBrect.width : 0}
                height={imageBrect ? imageBrect.height : 0}
                className="coveringCanvas"
                id={"canvas"}>

                {/*Questo poligono è semplicemente lo sfondo*/}
                <rect
                    width={imageBrect ? imageBrect.width : 0}
                    height={imageBrect ? imageBrect.height : 0}
                    onClick={handleNoAreaClick}
                    style={{fill: "rgba(0, 0, 0, 0)"}}
                />

                {areas.map(area => {
                    const coords = area.coords
                    const holes = area.holes
                    // faccio lo scaling delle coordinate
                    const scaledCoords = scaleCoords(coords)
                    // reverse per disegnare il buco a rovescio
                    const scaledHoles = holes.map(hole => scaleCoords(hole))

                    let d = "M" + scaledCoords.join(",") + "z"

                    scaledHoles.forEach(hole => {
                            d += " M" + hole.join(",") + "z"

                        }
                    )

                    return (
                        // <>
                        //     {circles}
                        <path
                            key={area.id}
                            d={d}
                            onClick={(event => {
                                handleAreaClick(event, area)
                            })}
                            stroke={area.strokeColor}
                            style={{fill: area.fill, cursor: cursor}}
                            strokeWidth="2"
                            strokeLinejoin={"round"}
                        />
                        // </>
                    )
                })}


                {infoPoints.map(point => {
                    const x = point.x * xScaling
                    const y = point.y * yScaling
                    const radius = 20 * xScaling

                    return (<circle
                        key={uuidv4()}
                        cx={x} cy={y} r={radius}
                        style={{fill: point.fill ? point.fill : "green"}}
                    />)
                })}

            </svg>
        </div>
    )

}
