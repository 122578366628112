import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {
    deleteAcquisition,
    fetchAcquisitionDetail,
    fetchAllAcquisitionDrawings,
    fetchProjectDescriptors
} from "../../../utils/apiCaller";
import {AppContext} from "../../../App";
import DrawingCard from "../../../components/DrawingCard";
import PainValues from "../../../components/PainValues";
import {EasyBreadcrumbs} from "../../../components/Breadcumbs";
import {useQueryParams} from "../../../hooks/useQueryParams";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {Menu, MenuItem} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

function AcquisitionDetail() {
    const classes = useStyles();

    const [state, dispatch] = useContext(AppContext)
    const [menuAnchor, setMenuAnchor] = useState(null);
    const jwt = state.user.jwt

    const {acquisitionId, patientId, projectId} = useParams();

    // stato che contiene la lista di acquisitions
    const [acquisition, setAcquisition] = useState({});
    const [descriptors, setDescriptors] = useState();
    const [drawings, setDrawings] = useState([]);
    const [values, setValues] = useState([]);

    const history = useHistory();

    const handleAcquisitionDelete = () => {
        if (window.confirm("You will loose all the drawings, spots and information related to the acquisition, continue?")) {
            deleteAcquisition(jwt.token, acquisitionId)
                .then(() => {
                    history.push(`/${projectId}/acquisition`)
                    dispatch({type: "SHOW_ALERT", payload: {message: "Acquisition removed", severity: "success"}})
                })
                .catch(result => {
                    dispatch({type: "SHOW_ALERT", payload: {message: result.message, severity: "error"}})
                })
        }
    }

    useEffect(() => {
        dispatch({type: "START_LOADING", payload: {key: "fetchAcquisitionDetail"}})
        fetchAcquisitionDetail(jwt.token, acquisitionId)
            .then(result => {
                dispatch({type: "LOADED", payload: {key: "fetchAcquisitionDetail"}})
                setAcquisition(result)
            })
            .catch(result => alert(result))
        dispatch({type: "START_LOADING", payload: {key: "fetchProjectDescriptors"}})
        fetchProjectDescriptors(jwt.token, projectId)
            .then(result => {
                setDescriptors(result.acquisitionDescriptors)
                dispatch({type: "LOADED", payload: {key: "fetchProjectDescriptors"}})
            })
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )
        dispatch({type: "START_LOADING", payload: {key: "fetchAllAcquisitionDrawings"}})
        fetchAllAcquisitionDrawings(jwt.token, acquisitionId)
            .then(result => {
                dispatch({type: "LOADED", payload: {key: "fetchAllAcquisitionDrawings"}})
                setDrawings(result)
            })
            .catch(result => alert(result))
    }, [acquisitionId, jwt, dispatch, projectId])

    useEffect(() => {
        if (acquisition.id === undefined || !descriptors)
            return

        // mappo i descriptors con i valori

        const result = descriptors.map(
            d => {
                let value = acquisition.values[d.id]
                if (value === undefined)
                    value = "unknown"
                else value = value.value

                return {
                    unitOfMeasure: d.unitOfMeasure,
                    key: d.key,
                    name: d.name,
                    id: d.id,
                    value: value,
                    description: d.description
                }
            }
        )

        if (acquisition.manualSessionId)
            result.push({name: "Session id", value: acquisition.manualSessionId})
        if (acquisition.manualSessionSet)
            result.push({name: "Session Set", value: acquisition.manualSessionSet})

        setValues(result)
    }, [acquisition, descriptors])

    const queryParams = useQueryParams()

    let destinationParams = `?page=${queryParams.qp.page || ""}&size=${queryParams.qp.size || ""}&sort=${queryParams.qp.sort || ""}&dir=${queryParams.qp.dir || ""}`


    const handleDrawingClick = (id) => {
        // se ho l'info sul id paziente allora il link deve essere differente
        const url = patientId ?
            `/supervisor/patient/${patientId}/acquisition/${acquisitionId}/${id}`
            :
            `/${projectId}/acquisition/${acquisitionId}/${id}`
        history.push(url + destinationParams)
    }

    return (
        <Grid container>
            <Grid item xs={6} className={classes.banner} style={{height: 60}}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                         style={{
                             height: 55,
                             paddingTop: 16,
                             verticalAlign: "middle",
                             display: "table-cell",
                             paddingLeft: 16
                         }}>
                        <EasyBreadcrumbs
                            breadcrumbs={[{
                                destination: "/" + projectId + "/acquisition" + destinationParams,
                                label: "Acquisitions"
                            }]}
                            current={"Acquisition"}/>
                    </Box>
                </Typography>

            </Grid>

            <Grid item xs={6} className={classes.banner} style={{textAlign: "right"}}>
                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{height: 68, paddingLeft: 16, paddingRight: 16, paddingTop: 5}}
                    spacing={1}
                >
                    <Grid item>
                        <IconButton
                            onClick={(e) => setMenuAnchor(e.currentTarget)}>
                            <MoreVertIcon/>
                        </IconButton>

                        <Menu
                            anchorEl={menuAnchor}
                            keepMounted
                            open={Boolean(menuAnchor)}
                            onClose={() => setMenuAnchor(undefined)}
                        >
                            <MenuItem onClick={handleAcquisitionDelete}>Delete acquisition</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={12} style={{marginBottom: 16}}><Divider/></Grid>

            <Grid item xs={8} style={{paddingLeft: 16}}>
                <Grid container spacing={3}>
                    {drawings.map(drawing =>
                        <Grid item xs={3} key={drawing.chartId}>
                            <DrawingCard onClick={handleDrawingClick}
                                         id={drawing.id}
                                         url={drawing.drawnBodyChartUrl}
                                         selectable
                                         name={drawing.id}/>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            <Grid item xs={4}>
                <PainValues
                    valuesLabel={"Others"}
                    values={values}/>
            </Grid>

        </Grid>
    )
}


export default AcquisitionDetail;