import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";

export default function TwoWayDialog({
                                         text,
                                         left,
                                         right,
                                         open,
                                         onClose,
                                         onLeft,
                                         onRight,
                                         title,
                                         disableBackdropClick = false
                                     }) {

    return (
        <Dialog
            open={open}
            disableBackdropClick={disableBackdropClick}
            onClose={onClose}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onLeft} color="primary">
                    {left}
                </Button>
                <Button onClick={onRight} color="primary">
                    {right}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
