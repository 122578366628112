import React, {useState} from "react";
import {CardHeader} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Slider from "@material-ui/core/Slider";


function RangeBasedPreview({descriptor, defaultValue, variant }){


    const [value, setValue] = useState(defaultValue);

    // mi assicuro che ci sia l'unita di misura
    if( descriptor.unitOfMeasure === undefined)
        descriptor.unitOfMeasure = ""

    const marks = [
        {
            value: descriptor.min,
            label: descriptor.min + descriptor.unitOfMeasure,
        },
        {
            value: descriptor.max,
            label: descriptor.max + descriptor.unitOfMeasure,
        },
    ];



    return(
        <Card
            variant={variant}>
            <CardHeader
            title={descriptor.name || descriptor.key}
            />
            <CardContent style={{padding:30}}>
                <Slider
                    onChange={(event, newValue) => {setValue(newValue)}}
                    value={value}
                    step={descriptor.step}
                    marks = {marks}
                    min={descriptor.min}
                    max={descriptor.max}
                    valueLabelDisplay="on"
                />

            </CardContent>
        </Card>

    )
}

export default RangeBasedPreview