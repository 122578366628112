import React, {useCallback, useContext, useEffect, useState} from "react";
import {fetchGlobalBodyChartsPage, postBodychartDisabledFlag, putNewChart} from "../../../utils/apiCaller";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import debounce from "lodash/debounce";
import {AppContext} from "../../../App";
import DrawingCard from "../../../components/DrawingCard";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import NewBodyChartDialog from "../../../dialogs/bodychart/creation/NewBodyChartDialog";
import CropIcon from '@material-ui/icons/Crop';
import EditIcon from '@material-ui/icons/Edit';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {useHistory} from "react-router-dom";
import {FormControl, MenuItem, Select, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));


function BodychartScreen() {
    const classes = useStyles();
    const [state] = useContext(AppContext)
    const jwt = state.user.jwt

    const history = useHistory();

    const [nameFilter, setNameFilter] = useState("");
    const [mustRefresh, setMustRefresh] = useState(false);
    const [open, setOpen] = useState(false);
    const [sexFilter, setSexFilter] = useState("ND");

    const handleOnSave = payload => {
        putNewChart(jwt.token, payload, true, false)
            .then(result => {
                // carico di nuovo la pagina
                // handleTableChange(jwt.token, nameFilter, listData.number, listData.size)
                setOpen(false)
                setMustRefresh(true)
            })
            .catch(result => alert(result))
    }

    const debounceHandleChange =
        useCallback(debounce((name) => {
            setNameFilter(name)
        }, 500), []);

    const onDisable = (id) => {
        postBodychartDisabledFlag(state.user.jwt.token, id, true)
            .then(() => {
                setMustRefresh(true)
            })
            .catch(error => alert(error.message))
    }

    const onCrop = (id) => {
        history.push("/supervisor/chart/" + id + "/crop")
    }

    return (

        <Grid container>
            <Grid item xs={4} className={classes.banner}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 60,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16
                     }}>
                    Body charts
                </Box>
            </Grid>
            <Grid item xs={8} className={classes.banner}>

                <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{height: 68, paddingLeft: 16, paddingRight: 16, paddingTop: 5}}
                    spacing={1}
                >
                    <Grid item xs={2}>
                        <FormControl variant="outlined" size="small"
                                     fullWidth>
                            <Select
                                size={"small"}
                                value={sexFilter}
                                onChange={e => setSexFilter(e.target.value)}
                            >
                                <MenuItem value={"ND"}>All genders</MenuItem>
                                <MenuItem value={"M"}>Male</MenuItem>
                                <MenuItem value={"F"}>Female</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item>
                        <TextField
                            onChange={(e) => debounceHandleChange(e.target.value)}
                            size={"small"}
                            variant={"outlined"}
                            placeholder={"Filter by name"}

                            InputProps={{
                                startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => setOpen(true)} color={"secondary"}>
                            <AddIcon/>
                        </IconButton>
                    </Grid>

                </Grid>


            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                <BodychartPagination
                    mustRefresh={mustRefresh}
                    onCrop={(id) => onCrop(id)}
                    onDisable={(id) => onDisable(id)}
                    onSelected={(id) => history.push("/supervisor/chart/" + id)}
                    onRefreshed={() => setMustRefresh(false)}
                    sexFilter={sexFilter}
                    nameFilter={nameFilter}/>

                <NewBodyChartDialog open={open} onSave={handleOnSave} onClose={() => setOpen(false)}/>
            </Grid>
        </Grid>
    )
}

function BodychartPagination({
                                 nameFilter,
                                 sexFilter,
                                 mustRefresh,
                                 onRefreshed,
                                 onSelected,
                                 onCrop,
                                 onDisable
                             }) {

    const [state] = useContext(AppContext)

    // stato che contiene la lista di bc
    const [listData, setListData] = useState({content: [], size: 4, totalElements: 0, totalPages: 0, number: 0});

    // cambiamento pagina
    const handleTableChange = useCallback(debounce((jwt,
                                                    page,
                                                    size,
                                                    sex,
                                                    name = ""
    ) => {
        fetchGlobalBodyChartsPage({jwt, name, page, size, sex})
            .then(result => setListData(result))
            .catch(result => alert(result))

    }, 250), [])


    useEffect(() => {

        handleTableChange(state.user.jwt.token, 0, 4, sexFilter, nameFilter)

    }, [state.user.jwt.token, nameFilter, handleTableChange, sexFilter])

    // caricamento su richiesta
    useEffect(() => {
        if (mustRefresh) {
            handleTableChange(state.user.jwt.token, 0, 4, sexFilter)
            onRefreshed();
        }

    }, [state.user.jwt.token, handleTableChange, mustRefresh, onRefreshed, sexFilter])

    return (
        <div style={{padding: 16}}>
            <Grid container spacing={2}>
                {listData.content.map((bodyChart) => (
                    <Grid item xs={12} key={bodyChart.id}>
                        <Grid container spacing={2} justify={"space-between"} style={{height: "100%"}}>
                            <Grid item xs={10}>
                                <Grid container spacing={2} >
                                    <Grid item xs={4} md={2} lg={1}>
                                        <DrawingCard url={bodyChart.digitalThumbnailUrl}/>
                                    </Grid>
                                    <Grid item xs={4} md={2} lg={1}>
                                        <DrawingCard url={bodyChart.paperThumbnailUrl}/>
                                    </Grid>
                                    <Grid item xs={4} md={6} lg={9}>
                                        <Grid container
                                              spacing={2}
                                              style={{height: "100%"}}
                                              alignItems="center">

                                            <Grid item xs={12}>
                                                <Typography variant={"h6"}>{bodyChart.name}</Typography>
                                                <Typography
                                                    variant={"subtitle1"}>{bodyChart.isFemale ? "Female" : "Male"}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button variant={"outlined"} onClick={() => onSelected(bodyChart.id)}>
                                                    <EditIcon/> Edit
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant={"outlined"} onClick={() => onCrop(bodyChart.id)}>
                                                    <CropIcon/> Crop
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant={"outlined"} onClick={() => onDisable(bodyChart.id)}>
                                                    <RemoveCircleIcon/> Disable
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={1}>
                                {bodyChart.femaleId &&<Grid container>
                                        <Grid item xs={12}>Female</Grid>
                                        <Grid item xs={8}><DrawingCard url={bodyChart.femaleDigitalUrl}/></Grid>
                                    </Grid>}
                            </Grid>
                            <Grid item xs={12}><Divider/></Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>

            <TablePagination
                rowsPerPageOptions={[4, 8, 12]}
                component="div"
                count={listData.totalElements}
                rowsPerPage={listData.size}
                page={listData.number}
                onChangePage={(event, page) => handleTableChange(state.user.jwt.token, page, listData.size, sexFilter, nameFilter)}
                onChangeRowsPerPage={(event) => handleTableChange(state.user.jwt.token, listData.number, parseInt(event.target.value), sexFilter, nameFilter)}
            />
        </div>
    )
}

export default BodychartScreen;