import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FileInput from "../../../components/FileInput";
import {checkImageDimensions, toBase64} from "../../../utils/generics";


export default function BodyChartStep4({onImageSelected, onCsvSelected}) {

    const [imageError, setImageError] = useState({error: false, message: ""});
    const [csvError, setCsvError] = useState({error: false, message: ""});

    const handleImageSelected = (event) => {
        // estraggo la lista di file
        const fi = event.target

        // controllo se c'è almeno qualcosa
        if (fi.files.length > 0) {
            // se ce qualcosa dovrebbe essere 1 solo file!
            const file = fi.files.item(0)

            checkImageDimensions(file)
                .then(() =>{
                    // la converto in base 64 e lo passo all'onchange
                    toBase64(file)
                        .then(result => {
                            onImageSelected(result) // lo mando al padre
                        })
                        .catch(error => setImageError({error: true, message: error}))

                    // svuoto gli errori
                    setImageError({error: false, message: ""})
                })
                .catch(() =>{
                    setImageError({error: true, message: "The image dimensions are not correct"})
                })

        }
    }

    const handleSCVSelected = (event) => {
        // estraggo la lista di file
        const fi = event.target

        // controllo se c'è almeno qualcosa
        if (fi.files.length > 0) {
            // se ce qualcosa dovrebbe essere 1 solo file!
            const file = fi.files.item(0)

            toBase64(file)
                .then(result => {
                    onCsvSelected(result) // lo mando al padre
                })
                .catch(error => setCsvError({error: true, message: error}))

        }
    }


    return <Grid container spacing={2} alignItems={"center"}
                 style={{
                     margin: 0,
                     width: '100%',
                 }}>
        <Grid item xs={12}>
            <Typography variant="body2" style={{color: "#626262"}} gutterBottom>
                The regions are necessary to get localized information about the pain drawings. But they are not
                mandatory, the drawback is a less accurate analysis.
            </Typography>
        </Grid>
        <Grid item xs={5}>
            <Typography variant="body2" gutterBottom>
                Region map file
            </Typography>
        </Grid>
        <Grid item xs={7}>
            <FileInput label={"select file"} onChange={handleImageSelected} accept={"image/*"}/>
            {imageError.error &&
            <Grid item xs={12}>
                <Typography variant="body2" color={"error"} gutterBottom>
                    {imageError.message}
                </Typography>
            </Grid>
            }
        </Grid>
        <Grid item xs={5}>
            <Typography variant="body2" gutterBottom>
                Region description file
            </Typography>
        </Grid>
        <Grid item xs={7}>
            <FileInput label={"select file"} onChange={handleSCVSelected} accept={".csv"}/>
            {csvError.error &&
            <Grid item xs={12}>
                <Typography variant="body2" color={"error"} gutterBottom>
                    {csvError.message}
                </Typography>
            </Grid>
            }
        </Grid>

    </Grid>
}

