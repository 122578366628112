import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Switch from "@material-ui/core/Switch";


function SpotBasedEdit({descriptor, onSave}) {

    const [payload, setPayload] = useState({});

    useEffect(() => {
        let payload = {
            id: descriptor.id,
            key: descriptor.key,
            kind: descriptor.kind,
            name: descriptor.name,
            description: descriptor.description
        }

        setPayload(payload)
    }, [descriptor])


    const onValueChange = (key, value) => {
        payload[key] = value
        setPayload(payload)
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={3}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" style={{textAlign: "end", marginTop: 4}}>
                        Key
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField disabled multiline defaultValue={descriptor.key} fullWidth placeholder={"Key"}
                           onChange={(event => onValueChange("key", event.target.value))}/>
            </Grid>

            <Grid item xs={12}/>
            <Grid item xs={3}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" style={{textAlign: "end", marginTop: 4}}>
                        Name
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField multiline defaultValue={descriptor.name} fullWidth placeholder={"Name"}
                           onChange={(event => onValueChange("name", event.target.value))}/>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={3}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" style={{textAlign: "end", marginTop: 4}}>
                        Description
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField defaultValue={descriptor.description} fullWidth multiline placeholder={"Description"}
                           onChange={(event => onValueChange("description", event.target.value))}/>
            </Grid>
            <Grid item xs={12}/>
            <Grid item xs={3}>
                <Typography component="div">
                    <Box fontWeight="fontWeightLight" style={{textAlign: "end", marginTop: 4}}>
                        Disabled
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                <Switch
                    defaultChecked={descriptor.disabled}
                    onChange={((event, checked) => onValueChange("disabled", checked))}
                    color="primary"
                />
            </Grid>

            <Grid item xs={10} style={{textAlign: "right"}}>
                <Button variant={"outlined"} color={"primary"} onClick={() => onSave(payload)}>Save changes</Button>
            </Grid>
        </Grid>
    )
}

export default SpotBasedEdit