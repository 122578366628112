import React, {useState} from "react";
import {CardHeader} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Slider from "@material-ui/core/Slider";
import './Style.css'


function RangeBased({descriptor, onUpdate, value}) {

    const [sliderValue, setSliderValue] = useState(value ? value.value : null)

    const unitOfMeasure = descriptor.unitOfMeasure ? descriptor.unitOfMeasure : ""

    const marks = [
        {
            value: descriptor.min,
            label: descriptor.min + unitOfMeasure
        },
        {
            value: descriptor.max,
            label: descriptor.max + unitOfMeasure
        },
    ];

    const handleValueChange = (evt, val) => {
        onUpdate(descriptor.id, val)
        setSliderValue(val) // serve solo per sapere se ho specificato un valore o meno
    }

    return (
        <Card>
            <CardHeader
                title={descriptor.name || descriptor.key}
                subheader={descriptor.isMandatory ? <div style={{color: "red"}}>Mandatory</div> : null}
            />
            <CardContent style={{padding: 30}}>
                <Slider
                    className={sliderValue === null ? "hideThumb" : ""}
                    onChange={handleValueChange}
                    value={sliderValue}
                    valueLabelDisplay="on"
                    step={descriptor.step}
                    marks={marks}
                    min={descriptor.min}
                    max={descriptor.max}
                />

            </CardContent>
        </Card>

    )
}

export default RangeBased