import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "../../../../App";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {useHistory, useParams} from "react-router-dom";
import {fetchAcquisitionsNotCompletedPage} from "../../../../utils/apiCaller";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import MassiveImportDialog from "../../../../dialogs/MassiveImportDialog";
import PaginatedTable from "../../../../components/PaginatedTable";
import {useQueryParams} from "../../../../hooks/useQueryParams";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));
const tableColumns = [
    {name: "Date", key: "date", default:"Not set"},
    {name: "Operator", key: "operatorFullName", default:"Not set"},
    {name: "Digital", key: "isDigital", boolean:true},
    {name: "Drawings", key: "drawingsCount"},
    {name: "Session", key: "manualSessionId"},
    {name: "Set", key: "manualSessionSet"}
]

function ImportListScreen() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext)
    const {projectId} = useParams()
    const history = useHistory()
    const [mustRefresh, setMustRefresh] = useState(false)
    const [massiveOpen, setMassiveOpen] = useState(false)

    const queryParams = useQueryParams()

    const sessions = queryParams.qp.sessions || ""

    const [pageArgs, setPageArgs] = useState()

    useEffect(() =>{
        if(projectId && state)
            setPageArgs({
                projectId: projectId,
                jwt: state.user.jwt.token,
                sessions:sessions
            })
    },[projectId, state, sessions])

    const handleImportDone = (result) => {
        if (result.errors.length > 0)
            dispatch({
                type: "SHOW_ALERT",
                payload: {
                    message: result.errors,
                    severity: "error",
                    autoHideDuration: null
                }
            })
        setMassiveOpen(false)
        history.push(`/${projectId}/acquisition/import?sessions=${result.importedSessions.join(",")}`)
    }

    return (
        <Grid
            container
        >
            <Grid item xs={6} className={classes.banner}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 60,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16
                     }}>
                    Uncompleted imports
                </Box>
            </Grid>
            <Grid item xs={6} className={classes.banner} style={{textAlign: "right"}}>
                <Button variant="outlined" color="primary" style={{marginRight: 16, marginTop: 16}}
                        onClick={() => setMassiveOpen(true)}>
                    Import new pdf session
                </Button>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>

                <MassiveImportDialog
                    onImported={handleImportDone}
                    projectId={projectId}
                    open={massiveOpen}
                    onClose={() => setMassiveOpen(false)}/>

                {pageArgs && <PaginatedTable
                    mustRefresh={mustRefresh}
                    onRefreshed={() => setMustRefresh(false)}
                    fields={tableColumns}
                    sortProperty={"manualSessionId"}
                    requestArgs={pageArgs}
                    fetchingPageFunction={fetchAcquisitionsNotCompletedPage}
                    onSelected={(acquisition) => history.push("/" + projectId + "/acquisition/import/" + acquisition.id)}
                />}

            </Grid>
        </Grid>

    )
}

export default ImportListScreen;