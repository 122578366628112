import React, {useContext, useEffect, useState} from "react";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './Style.css'
import {fetchBlob} from "../utils/apiCaller";
import {AppContext} from "../App";
import {CircularProgress} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Fade from "@material-ui/core/Fade";

function DrawingCard({
                         url,
                         disabled = false,
                         selected = false,
                         selectable = false,
                         object,
                         onClick = () => {
                         },
                         id,
                         name = "",
                         title = "",
                         secondary = ""
                     }) {
    const [state] = useContext(AppContext)
    // stato che contiene la pain acquisition
    const [image, setImage] = useState("")
    const [imageError, setImageError] = useState(false)
    const [loading, setLoading] = useState(true)

    // scarico l'immagine
    useEffect(() => {

        if (url === undefined || state.user.jwt === undefined)
            return

        fetchBlob(state.user.jwt.token, url)
            // metto l'immagine nello stato
            .then(image => {
                setLoading(false)
                setImage(image)
            })
            .catch(() => setImageError(true))

    }, [state.user.jwt, url])

    let selectedDiv = "";

    // se selected è true allora metto l'icona di selezione
    if (selected)
        selectedDiv = (

            <div style={{color: "green", position: "absolute", right: 5, bottom: 5}}>
                <CheckCircleIcon/>
            </div>
        )

    return (
        <div id={disabled ? "elementDisabled" : selectable ? "element" : ""} onClick={(event) => {
            if (!disabled)
                if (object)
                    onClick(object, event)
                else
                    onClick(id, event)
        }}
             style={{
                 backgroundColor: "white",
                 padding: 10,
                 borderRadius: 10,
                 border: "1px solid #bdbdbd",
                 position: "relative",
                 // paddingTop: !image && !imageError ? "110%" : 0
             }}>

            {selectedDiv}

            {loading ? (
                    <Fade
                        in={loading}
                        style={{
                            transitionDelay: loading ? '2000ms' : '0ms',
                        }}
                        unmountOnExit
                    >
                        <Grid container>
                            <Grid item xs={12} style={{textAlign: "center"}}>
                                <CircularProgress/>
                            </Grid>
                            <Grid item xs={12} style={{textAlign: "center"}}>
                                Loading image
                            </Grid>
                        </Grid>
                    </Fade>
                ) :

                imageError ?
                    <img style={{width: "100%", paddingTop: 16}}
                         alt={name}
                         src={"/error_image.png"}
                    />
                    :
                    <img style={{width: "100%"}}
                         alt={name}
                         src={image}
                    />
            }
            {!title && !imageError ? "" :
                <Typography style={{fontSize: 14}} color="textSecondary" gutterBottom>
                    {imageError ? "Image not found" : null}
                    {title}
                </Typography>
            }
            {!secondary ? "" :
                <Typography style={{fontSize: 11}} color="textSecondary" gutterBottom>
                    {secondary}
                </Typography>
            }
        </div>
    )
}

export default DrawingCard;