import TableContainer from "@material-ui/core/TableContainer";
import {Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TableSortLabel} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import React, {useCallback, useContext, useEffect, useState} from "react";
import {AppContext} from "../App";
import {useQueryParams} from "../hooks/useQueryParams";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';


const skeletons = ['20%', '10%', '18%', '30%', '5%']

const renderCell = (field, row) => {
    let value = row[field.key]

    // if the type is boolean or if the type is implicitly boolean
    if (field.boolean || (typeof value === "boolean"))
        return value ? "Yes" : "No"
    else if (field.renderingFunc) {
        const result = field.renderingFunc(row)
        if (typeof result === "boolean")
            return result ? "Yes" : "No"
        return result
    } else
        return value

}

export default function PaginatedTable({
                                           mustRefresh,
                                           onRefreshed,
                                           hideEnabled = false,
                                           defaultSort = "id",
                                           idField = "id",
                                           fields,
                                           fetchingPageFunction,
                                           requestArgs,
                                           autoRefreshMs,
                                           onMenuSelected,
                                           onSelected = () => {
                                           },
                                           paramsPrefix = ""
                                       }) {

    const queryParams = useQueryParams();

    const [, dispatch] = useContext(AppContext)

    const pageNr = queryParams.qp[paramsPrefix + "page"] || 0
    const size = queryParams.qp[paramsPrefix + "size"] || 10
    const dir = queryParams.qp[paramsPrefix + "dir"] || "DESC"
    const sort = queryParams.qp[paramsPrefix + "sort"] || defaultSort
    const hide = (queryParams.qp[paramsPrefix + "hide"] || "").split(",")

    const [page, setPage] = useState({
        content: [],
        size: 10,
        totalElements: 0,
        totalPages: 0,
        number: 0,
        sort: {
            direction: "DESC",
            property: "id"
        },
        loaded: false
    });

    // const deps = [dispatch, fetchingPageFunction, requestArgs, pageNr, size, dir, sort]
    //
    // useWhatChanged(deps, 'dispatch, fetchingPageFunction, args, page, size, dir, sort');

    useEffect(() => {

        const args = {
            page: pageNr,
            size: size,
            sortDir: dir,
            sortField: sort,
            ...requestArgs
        }

        const fetch = () => fetchingPageFunction(args).then(r =>
            setPage(r)
        )
            .catch((e) => dispatch({type: "SHOW_ALERT", payload: {message: e.message, severity: "error"}}))


        fetch()


        const interval = autoRefreshMs ? setInterval(() => {
            fetch()
        }, 5000) : null

        return () => autoRefreshMs && clearInterval(interval)
    }, [dispatch, fetchingPageFunction, requestArgs, autoRefreshMs, pageNr, size, dir, sort])

    const handleOnHide = (key) => {
        let param = queryParams.qp[paramsPrefix + "hide"]

        if (!param)
            param = key
        else
            param += "," + key

        const params = {}
        params[paramsPrefix + "hide"] = param

        queryParams.setParams(params)
    }

    const handleTableUpdate = useCallback((p, s, d, so) => {
        const params = {}

        params[paramsPrefix + "page"] = p
        params[paramsPrefix + "size"] = s
        params[paramsPrefix + "sort"] = so
        params[paramsPrefix + "dir"] = d

        queryParams.setParams(params)
    }, [queryParams, paramsPrefix])

    useEffect(() => {
        if (mustRefresh) {
            handleTableUpdate(pageNr, size, dir, sort)
            onRefreshed();
        }

    }, [pageNr, size, dir, sort, handleTableUpdate, mustRefresh, onRefreshed, paramsPrefix])

    const handleOnSelected = (event, row) => {
        if (event.target.tagName === "TD")
            onSelected(row)
    }

    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {fields
                                .filter(field => !hide.includes(field.key))
                                .map(field =>
                                    <TableCell key={field.key}>
                                        {hideEnabled && <IconButton color="primary" size={"small"}
                                                                    onClick={() => handleOnHide(field.key)}
                                                                    component="span">
                                            <VisibilityOffIcon/>
                                        </IconButton>}
                                        {(field.sorting !== false) ? <TableSortLabel
                                                active={page.sort.property === field.key || page.sort.property === field.sort}
                                                direction={page.sort.ascending ? "asc" : "desc"}
                                                onClick={() => handleTableUpdate(pageNr, size, dir === "ASC" ? "DESC" : "ASC", field.sort ? field.sort : field.key)}
                                            >
                                                {field.name}
                                            </TableSortLabel>
                                            :
                                            field.name
                                        }
                                    </TableCell>
                                )}

                            {onMenuSelected && <TableCell style={{textAlign: "right"}}>
                                Menu
                            </TableCell>}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {page.loaded === false ?
                            // se la propietà loaded è false allora mostro gli skeleton
                            skeletons.map(width => (
                                <TableRow key={width}>
                                    {fields
                                        .filter(field => !hide.includes(field.key))
                                        .map(field =>
                                            <TableCell key={"fi_" + field.key}>
                                                <Skeleton style={{width: Math.floor(Math.random() * 90) + 10}}
                                                          variant="text"/>
                                            </TableCell>
                                        )}
                                </TableRow>
                            ))

                            :

                            // renderizzo le cose normali ricevute dal server
                            page.content
                                .map(row => (
                                    <TableRow hover key={row[idField]} style={{cursor: "pointer"}}
                                              onClick={(evt) => handleOnSelected(evt, row)}>
                                        {fields
                                            .filter(field => !hide.includes(field.key))
                                            .map(field =>
                                                <TableCell
                                                    style={{
                                                        maxWidth: field.maxWidth,
                                                        // textAlign: typeof row[field.key] == 'number'? "right" : "left"
                                                    }}
                                                    key={"fi_" + field.key}>
                                                    {renderCell(field, row)}
                                                </TableCell>
                                            )}

                                        {onMenuSelected &&
                                            <TableCell style={{padding: 0, paddingRight: 10, textAlign: "right"}}>
                                                <IconButton
                                                    onClick={(e) => onMenuSelected(e, row)}>
                                                    <MoreVertIcon/>
                                                </IconButton>
                                            </TableCell>}

                                    </TableRow>
                                ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={page.totalElements}
                rowsPerPage={page.size}
                page={page.number}
                onChangePage={(event, newPageNr) => handleTableUpdate(newPageNr, size, dir, sort)}
                onChangeRowsPerPage={(event) => handleTableUpdate(pageNr, parseInt(event.target.value), dir, sort)}
            />
        </div>)
}