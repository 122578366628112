import React, {useContext, useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import ImageMapper from "./image-mapper/ImageMapper";
import {fetchBlob} from "../utils/apiCaller";
import {AppContext} from "../App";


// per avere l'effetto che si apre da sotto
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DrawingPreview({drawing, open, onClose}) {

    const [imageBlob, setImageBlob] = React.useState("");
    const [state] = useContext(AppContext)

    const spots = drawing ? drawing.spotDtos : []

    useEffect(() => {
        if (drawing)
            // scarico l'immagine
            fetchBlob(state.user.jwt.token, drawing.chartLowUrl)
                .then(r => setImageBlob(r))

    }, [drawing, state.user.jwt])

    const areas = spots.map(spot => {
        const coords = spot.spotContours.map(point => point.x + "," + point.y).join(",").split(",")

        const holes = spot.spotHolesContours.map(
            hole => hole.map(point => point.x + "," + point.y).reverse().join(",").split(",")
        )
        // se completo metto il colore verde
        const fill = spot.complete ? "rgba(0, 255, 0, 0.5)" : "rgba(255, 0, 0, 0.5)"
        return {id: spot.id, coords: coords, fill: fill, holes: holes}
    })

    return (

        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}>

            <ImageMapper
                src={imageBlob}
                areas={areas}
                referenceSize={{
                    x: 1536,
                    y: 2048
                }}
            />

        </Dialog>
    )
}
