import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

import {useHistory, useParams} from "react-router-dom";
import {AppContext} from "../../../../App";
import {fetchBlob, putCropBc, putPreviewCropBc} from "../../../../utils/apiCaller";
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import Button from "@material-ui/core/Button";
import CropIcon from "@material-ui/icons/Crop";
import SaveIcon from "@material-ui/icons/Save";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));


const marks = [
    {value: 80, label: "Default"},
    {value: 10, label: "10"},
    {value: 20},
    {value: 30},
    {value: 40},
    {value: 50},
    {value: 60},
    {value: 70},
    {value: 90},
    {value: 100, label: "100"},
]

export default function BodychartScreen() {
    const classes = useStyles();

    const [state] = useContext(AppContext)

    const [blob, setBlob] = useState();
    const [previewBase, setPreviewBase] = useState();
    const [crop, setCrop] = useState({});
    const [cropRatio, setCropRatio] = useState(80);
    const [previewLoading, setPreviewLoading] = useState(false);
    const [name, setName] = useState("");
    const {bcId} = useParams();

    const history = useHistory();

    const handleCrop = () => {
        const imageContainer = document.querySelector(".ReactCrop__image")
        const width = imageContainer.clientWidth
        const height = imageContainer.clientHeight

        const payload = {
            x: crop.x,
            y: crop.y,
            width: crop.width,
            height: crop.height,
            referenceWidth: width,
            referenceHeight: height,
            zoomRatio: cropRatio,
            name: name,
        }

        putCropBc(state.user.jwt.token, bcId, payload)
            .then(() => history.push("/supervisor/chart"))

    }

    const handleCropChange = ({x = crop.x, y = crop.y, width = crop.width, height = crop.height, unit = 'px'}) => {

        setCrop({x: Math.round(x), y: Math.round(y), width: width, height: height, unit: unit})
    }


    const handlePreviewCrop = () => {
        const imageContainer = document.querySelector(".ReactCrop__image")
        const width = imageContainer.clientWidth
        const height = imageContainer.clientHeight

        const payload = {
            x: crop.x,
            y: crop.y,
            width: crop.width,
            height: crop.height,
            referenceWidth: width,
            referenceHeight: height,
            zoomRatio: cropRatio
        }

        setPreviewLoading(true)
        putPreviewCropBc(state.user.jwt.token, bcId, payload)
            .then((result) => {
                setPreviewLoading(false)
                setPreviewBase(result.result)
            })

    }

    useEffect(() => {
        setPreviewBase(null)
    }, [crop, cropRatio])


    useEffect(() => {
        fetchBlob(state.user.jwt.token, "/data/charts/" + bcId + "/digital.png")
            .then(r => setBlob(r))
    }, [bcId, state.user.jwt.token])

    return (

        <Grid container justify={"center"} alignItems={"center"}>
            <Grid item xs={12} className={classes.banner}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 60,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16
                     }}>
                    Crop new bodychart
                </Box>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                <Grid container spacing={3} justify={"center"} alignItems={"center"}>
                    <Grid item xs={4} style={{marginTop: 16}}>
                        {blob && <ReactCrop
                            src={blob}
                            crop={crop}
                            onChange={newCrop => newCrop.width && handleCropChange(newCrop)}/>}
                    </Grid>
                    <Grid item xs={2} style={{marginTop: 16}}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField fullWidth variant={"outlined"}
                                           label={"X"} type="number"
                                           value={crop.x || ''}
                                           onChange={e => handleCropChange({x: e.target.value})}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth variant={"outlined"}
                                           label={"Y"} type="number"
                                           value={crop.y || ''}
                                           onChange={e => handleCropChange({y: e.target.value})}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth variant={"outlined"}
                                           label={"Height"} type="number"
                                           value={crop.height || ''}
                                           onChange={e => handleCropChange({height: e.target.value})}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField fullWidth variant={"outlined"}
                                           label={"Width"} type="number"
                                           value={crop.width || ''}
                                           onChange={e => handleCropChange({width: e.target.value})}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography gutterBottom>
                                    Zoom
                                </Typography>
                                <Slider
                                    value={cropRatio}
                                    onChange={(e, v) => setCropRatio(v)}
                                    valueLabelDisplay="auto"
                                    step={10}
                                    marks={marks}
                                    min={10}
                                    max={100}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Tooltip title={!crop.width ? "Select an area first" : ""}>
                                    <span>
                                    <Button disabled={previewLoading || !crop.width} fullWidth variant={"outlined"}
                                            color={"primary"}
                                            onClick={handlePreviewCrop}>
                                    {previewLoading ? <CircularProgress size={25}/> : <CropIcon/>} Preview
                                </Button>
                                        </span>
                                </Tooltip>

                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    value={name}
                                    onChange={(event => setName(event.target.value))}
                                    label="Name" variant="outlined"/>
                            </Grid>
                            <Grid item xs={12}>
                                <Tooltip title={!previewBase ? "Before saving ask for a preview" : ""}>
                                    <span>
                                    <Button
                                        fullWidth variant={"contained"} color={"secondary"}
                                        disabled={!previewBase} onClick={handleCrop}><SaveIcon/>Save</Button>
                                        </span>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12}>
                                <Alert severity="error" style={{visibility: cropRatio <= 80 ? "hidden" : "visible"}}>
                                    <AlertTitle>Zoom</AlertTitle>
                                    Warning: a zoom value over 80% can lead to problem with margins during print.
                                </Alert>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={4} style={{marginTop: 16}}>
                        {previewBase && <img
                            src={previewBase}
                            alt={"preview"} style={{width: "100%", border: "1px solid black"}}/>}
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}
