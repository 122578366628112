import React, {useCallback, useEffect, useState} from 'react';
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
import {debounce} from "lodash";
import Skeleton from "@material-ui/lab/Skeleton";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";


export default function AdvancedTable({
                                          jwt,
                                          filterName,
                                          fetchingPageFunction,
                                          onSelected = () => {
                                          },
                                          fields,
                                          requestArgs,
                                          mustRefresh,
                                          onRefreshed,
                                          highlightedIds = [],
                                          sortProperty = "id",
                                          debug = false,
                                          sortingEnabled = true,
                                          enableMenu = false,
                                          onMenuSelected = () => {
                                          },
                                          tableProps = {},
                                          ...props
                                      }) {

    // stato che contiene la pagina
    const [listData, setListData] = useState({
        content: [],
        size: 8,
        totalElements: 0,
        totalPages: 0,
        number: 0,
        sort: {
            direction: "DESC",
            property: sortProperty
        },
        loaded: false
    });


    const skeletons = ['20%', '10%', '18%', '30%', '5%']

    // se canbia una propietà nel sorting
    const handleTableSortingChange = (field) => {
        // allora, controllo se il campo è quello che attualmente sta ordinando la lista
        if (field === listData.sort.property) {
            // in questo caso devo invertire l'ordine di ordinamento
            let direction = listData.sort.ascending ? "DESC" : "ASC"
            handleTableChange(jwt, listData.number, listData.size, requestArgs, filterName, direction, field)
        }

        // altrimenti cambio il field
        else {
            handleTableChange(jwt, listData.number, listData.size, requestArgs, filterName, "ASC", field)
        }
    }


    // cambiamento pagina
    const handleTableChange = useCallback(debounce((token,
                                                    page,
                                                    size,
                                                    add,
                                                    filter = "",
                                                    sortDir = listData.sort.direction,
                                                    sortField = listData.sort.property
    ) => {

        const args = {
            jwt: token,
            page: page,
            size: size,
            sortDir: sortDir,
            sortField: sortField,
            search: filter,
            ...add
        }

        fetchingPageFunction(args)
            .then(result => {
                setListData(result)
            })
            .catch(result => alert(result.message))
    }, 250), [])

    // // primo caricamento
    // useEffect(() => {
    //     handleTableChange(jwt, 0, 5)
    //
    // }, [jwt, handleTableChange])
    //
    useEffect(() => {
        handleTableChange(jwt, 0, 8, requestArgs, filterName)
    }, [jwt, filterName, handleTableChange, requestArgs])

    // caricamento su richiesta
    useEffect(() => {
        if (mustRefresh) {
            handleTableChange(jwt, 0, 8, requestArgs)
            onRefreshed();
        }

    }, [jwt, handleTableChange, mustRefresh, onRefreshed, requestArgs])

    if (debug)
        console.log(listData)


    const handleRowSelected = (event, row) =>{
        // ignoro l'evento se ho il menù abilitato e clicco sul bottone

        if(event.target.tagName !== "TD" && enableMenu)
            return

        onSelected(row)
    }


    return (

        <div {...props}>
            <TableContainer>
                <Table {...tableProps}>
                    <TableHead>
                        <TableRow>
                            {fields.map(field =>
                                <TableCell style={{maxWidth: field.maxWidth}} key={field.key}>
                                    {sortingEnabled && field.sortingEnabled !== false ?
                                        <TableSortLabel
                                            active={listData.sort.property === field.key || listData.sort.property === field.sort}
                                            direction={listData.sort.ascending ? "asc" : "desc"}
                                            onClick={() => handleTableSortingChange(field.sort ? field.sort : field.key)}
                                        >
                                            {field.name}
                                        </TableSortLabel>
                                        :
                                        field.name
                                    }

                                </TableCell>
                            )}
                            {enableMenu ? <TableCell> </TableCell> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {listData.loaded === false ?
                            // se la propietà loaded è false allora mostro gli skeleton
                            skeletons.map(width => (
                                <TableRow key={width}>
                                    {fields.map(field =>
                                        <TableCell key={"fi_" + field.key}>
                                            <Skeleton style={{width: Math.floor(Math.random() * 90) + 10}}
                                                      variant="text"/>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))

                            :

                            // renderizzo le cose normali ricevute dal server
                            listData.content.map(row => (
                                <TableRow hover key={row.id} style={{cursor: "pointer"}}
                                          selected={highlightedIds.includes(row.id)}
                                          onClick={(event) =>handleRowSelected(event,row)}>
                                    {fields.map(field =>
                                        <TableCell style={{maxWidth: field.maxWidth}}
                                                   key={"fi_" + field.key}>
                                            {field.boolean ? row[field.key] ? "Yes" : "No" : (row[field.key] || field.default)}
                                        </TableCell>
                                    )}
                                    {enableMenu ? <TableCell style={{padding: 0, paddingRight: 10, textAlign:"right"}}>
                                        <IconButton
                                            onClick={(e) => onMenuSelected(e, row)}>
                                            <MoreVertIcon/>
                                        </IconButton>
                                    </TableCell> : null}

                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[8, 16, 24]}
                component="div"
                count={listData.totalElements}
                rowsPerPage={listData.size}
                page={listData.number}
                onChangePage={(event, page) => handleTableChange(jwt, page, listData.size, requestArgs, filterName)}
                onChangeRowsPerPage={(event) => handleTableChange(jwt, listData.number, parseInt(event.target.value), requestArgs, filterName)}
            />
        </div>

    );
}
