const _URL = window.URL || window.webkitURL;

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const checkImageDimensions = (imageFile) =>{

    return new Promise((resolve, reject) =>{
        // creo un oggetto immagine per verificarne le dimensioni
        let img = new Image();
        // al caricamente dell'immagine eseguo questa funzione
        img.onload = () => {

            // ottengo le fumensioni
            let height = img.height;
            let width = img.width;

            // controllo che le dimensioni siano giuste
            if (width/height === 0.75) {
                // bene, l'immagine è corretta
                resolve()
            } else
               reject()
        }
        // chiedo di caricare il file come immagine
        img.src = _URL.createObjectURL(imageFile);
    });
}

const colors = ["#b578cf", '#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#555555', '#dce775', '#ff8a65', '#ba68c8']

export const getColor = index => {
    // ottengo l'ultima cifra del numero
    const colorIndex = Number(String(index).charAt(String(index).length-1))

    return colors[colorIndex]

}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min; //Il max è escluso e il min è incluso
}

export const getRandomColor = () => {
    return colors[getRandomInt(0,10)]
}