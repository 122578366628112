import React, {useContext, useEffect, useState} from "react";
import {
    fetchAllProjectDescriptors,
    putPdToProject,
    removePdFromProject,
    toggleMandatoryPdFromProject
} from "../../../utils/apiCaller";

import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {useHistory, useParams} from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import RemoveIcon from "@material-ui/icons/Remove";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";
import {AppContext} from "../../../App";
import DescriptorSelectionDialog from "../../../dialogs/descriptor/DescriptorSelectionDialog";
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import PaginatedTable from "../../../components/PaginatedTable";
import AdditionalInfoContent from "./AdditionalInfoContent";
import AdditionalInfoDialog from "../../../dialogs/AdditionalInfoDialog";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));

const tableColumns = [
    {key: "name", name: "Name"},
    {key: "simpleKind", name: "Kind"},
    {key: "description", name: "Description"},
    {key: "isMandatory", name: "Mandatory", boolean: true}
]

function Category({categoryKey, requestArgs, mustRefresh, onRefresh}) {
    const {projectId} = useParams()
    const [state, dispatch] = useContext(AppContext)

    // questi due stati sono per il piccolo menu che si apre al premere dei 3 pallini
    const [anchorEl, setAnchorEl] = React.useState(null);
    const uMenuOpen = Boolean(anchorEl);
    const [refreshTable, setRefreshTable] = React.useState(null);

    const handleMenu = (event, elem) => {
        setAnchorEl({el: event.currentTarget, id: elem});
    };

    const handleOnRefresh = () => {
        if (mustRefresh)
            onRefresh()
        if (refreshTable)
            setRefreshTable(false)
    };

    const handleOnRemove = (descriptor) => {


        if (window.confirm("Sure?"))
            removePdFromProject(state.user.jwt.token, projectId, descriptor.id, categoryKey)
                .then(() => {
                    setRefreshTable(true)
                    dispatch({type: "SHOW_ALERT", payload: {message: "Descriptor removed successfully"}})
                })
                .catch((error) =>
                    dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
                )
    }

    const toggleMandatory = (descriptor) => {
        if (window.confirm("Sure?"))
            toggleMandatoryPdFromProject(state.user.jwt.token, projectId, descriptor.id, categoryKey)
                .then(() => {
                    setRefreshTable(true)
                    dispatch({type: "SHOW_ALERT", payload: {message: "Saved successfully"}})
                })
                .catch((error) => {
                    dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
                })
    }


    return (
        <Grid container>
            <Grid item xs={12}>
                {requestArgs && <PaginatedTable
                    jwt={state.user.jwt.token}
                    mustRefresh={mustRefresh || refreshTable}
                    onRefreshed={handleOnRefresh}
                    onMenuSelected={handleMenu}
                    fields={tableColumns}
                    requestArgs={requestArgs}
                    fetchingPageFunction={fetchAllProjectDescriptors}
                />}

                <Popover
                    open={uMenuOpen}
                    anchorEl={anchorEl ? anchorEl.el : null}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List style={{marginTop: "auto", cursor: "pointer"}}>
                        <ListItem button onClick={() => {
                            setAnchorEl(null)
                            handleOnRemove(anchorEl.id)
                        }}>
                            <ListItemIcon>
                                <RemoveIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Remove"/>
                        </ListItem>

                        <ListItem button onClick={() => {
                            setAnchorEl(null)
                            toggleMandatory(anchorEl.id)
                        }}>
                            <ListItemIcon>
                                <ToggleOffIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Toggle mandatory"/>
                        </ListItem>

                    </List>
                </Popover>

            </Grid>
        </Grid>
    )
}

function DescriptorsList() {
    const classes = useStyles();
    // hook hystory per poter redirectare l'utente
    const history = useHistory();
    //const [tabIndex, setTabIndex] = useState(0);

    const {projectId, page} = useParams()
    const [state, dispatch] = useContext(AppContext)
    const jwt = state.user.jwt


    const [open, setOpen] = React.useState(false);
    const [mustRefresh, setMustRefresh] = React.useState(false);

    // scelgo il tab appropiato in base alla location
    let tabIndex
    if (page === "acquisition" || !page)
        tabIndex = 0
    else if (page === "drawing")
        tabIndex = 1
    else if (page === "markers")
        tabIndex = 2
    else
        tabIndex = 3

    const handleOnAdd = (descriptor) => {

        const descriptorId = descriptor.id

        let kind = tabIndex === 0 ? "ACQ" : tabIndex === 1 ? "DRA" : "MAR"

        putPdToProject(jwt.token, projectId, descriptorId, kind)
            .then(() => setMustRefresh(true))
            .catch((error) => {
                alert(error)
            })

        setOpen(false)
    }

    const [args, setArgs] = useState()
    const [category, setCategory] = useState()


    useEffect(() => {
        switch (tabIndex) {
            case 3:
                break
            case 1:
                setCategory("DRA")
                setArgs({projectId: projectId, cat: "DRA", jwt: state.user.jwt.token})
                break
            case 2:
                setCategory("MAR")
                setArgs({projectId: projectId, cat: "MAR", jwt: state.user.jwt.token})
                break
            default:
            case 0:
                setCategory("ACQ")
                setArgs({projectId: projectId, cat: "ACQ", jwt: state.user.jwt.token})
                break
        }
    }, [tabIndex, state.user.jwt, projectId])


    const handleTabChange = (index) => {
        switch (index) {
            case 3:
                history.push("/" + projectId + "/descriptor/patients")
                break
            case 2:
                history.push("/" + projectId + "/descriptor/markers")
                break
            case 1:
                history.push("/" + projectId + "/descriptor/drawing")
                break
            default:
            case 0:
                history.push("/" + projectId + "/descriptor/acquisition")
                break
        }
    }

    const handleNewAdditionalInfo = () => {
        setOpen(p => !p)
        dispatch({type: "SHOW_ALERT", payload: {message: "Additional info created successfully"}})
        setMustRefresh(true)
    }

    return (

        <Grid container>
            <Grid item xs={10} className={classes.banner} style={{height: 60}}>
                <Tabs
                    value={tabIndex}
                    onChange={((event, value) => handleTabChange(value))}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{height: "100%", paddingTop: 10}}
                >
                    <Tab label="Acquisition"/>
                    <Tab label="Drawing"/>
                    <Tab label="Markers"/>
                    <Tab label="Patients Info"/>
                </Tabs>
            </Grid>
            <Grid item xs={2} className={classes.banner} style={{textAlign: "right", paddingTop: 9}}>
                <IconButton onClick={() => setOpen(true)} color={"secondary"}>
                    <AddIcon/>
                </IconButton>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                <DescriptorSelectionDialog
                    onSelected={handleOnAdd}
                    disablePointSpot={tabIndex === 0}
                    open={open && tabIndex !== 3}
                    onClose={() => setOpen(p => !p)}/>

                <AdditionalInfoDialog
                    projectId={projectId}
                    open={open && tabIndex === 3}
                    onClose={() => setOpen(p => !p)}
                    onSave={handleNewAdditionalInfo}
                />


                {tabIndex === 3 ? <AdditionalInfoContent
                        mustRefresh={mustRefresh}
                        onMustRefresh={() => setMustRefresh(true)}
                        onRefresh={() =>
                            setMustRefresh(false)}
                        projectId={projectId}/> :
                    <Category mustRefresh={mustRefresh}
                              requestArgs={args}
                              onRefresh={() =>
                                  setMustRefresh(false)}
                              onAdd={handleOnAdd}
                              categoryKey={category}
                    />}
            </Grid>
        </Grid>

    )
}

export default DescriptorsList;