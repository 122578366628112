import React from 'react';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

export default function TextEntry({
                                      editable,
                                      onEdit,
                                      label,
                                      value,
                                      visible = true,
                                      divider = true,
                                      inputMode = "text"
                                  }) {


    const handleValueChange = (value) => {
        if (onEdit !== null)
            onEdit(value)
    }

    if (!visible)
        return <></>

    return (
        <>
            <Grid item xs={5}>
                <Typography component="div">
                    <Box style={{margin: 8}} fontSize="h6.fontSize" fontWeight="fontWeightLight">
                        {label}
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={7}>
                {
                    editable ?
                        <TextField
                            onChange={(event) => handleValueChange(event.target.value)}
                            autoFocus
                            InputProps={{ inputProps: { min: 0 } }}
                            type={inputMode}
                            value={value}
                            fullWidth
                        /> :
                        <Typography component="div">
                            <Box style={{margin: 8}} fontSize="h6.fontSize" fontWeight="fontWeightLight">
                                {value}
                            </Box>
                        </Typography>

                }
            </Grid>
            {divider ?
                <Grid item xs={12}>
                    <Divider/>
                </Grid>
                : ""}

        </>
    )

}