import React, {useContext, useState} from "react";
import Card from "@material-ui/core/Card";
import {CardActions, CardHeader} from "@material-ui/core";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import {fetchBlob} from "../../../../../utils/apiCaller";
import {AppContext} from "../../../../../App";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function GenericFile({output}) {

    const [state, dispatch] = useContext(AppContext);
    const [loading, setLoading] = useState(false);

    const handleDownload = () => {
        setLoading(true)
        fetchBlob(state.user.jwt.token, "/data/attachment/" + output.attachmentFile)
            .then(blob => {
                // const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = blob;
                // the filename you want
                a.download = output.title.toLowerCase() + '.' + output.attachmentExtension;
                document.body.appendChild(a);
                a.click();
            })
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: "No file available", severity: "error"}})
            )
            .finally(() => setLoading(false))
    }

    return (
        <Card style={{height:"100%"}}>
            <CardHeader title={output.title}/>
            <CardContent>

            </CardContent>
            <CardActions>
                <Button onClick={handleDownload} color={"secondary"} variant={"text"}>
                    {loading && <CircularProgress size={25} style={{marginRight:12, color:"white"}}/>} Download
                </Button>
            </CardActions>
        </Card>

    )
}
