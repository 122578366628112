import React from 'react';
import {Route, Switch} from "react-router-dom";
import ListScreen from "./ListScreen";
import PatientDetail from "./PatientDetail";


export default function PatientRouter() {

    return (
        <Switch>
            <Route exact path="/supervisor/patient" component={ListScreen}/>
            <Route path="/supervisor/patient/:patientId" component={PatientDetail}/>
        </Switch>
    );
}
