import React, {useContext, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from "@material-ui/core/InputAdornment";
import {AppContext} from "../../../App";
import AdvancedTable from "../../../components/AdvancedTable";
import {fetchGlobalBodyChartsPage} from "../../../utils/apiCaller";
import AuthImg from "../../../components/AuthImg";

const tableColumns = [
    {key: "name", name: "Name"},
    {key: "isFemale", name: "Female", boolean: true}
]


export default function BodyChartSelectionDialog({open, onClose, onSelected, sex, selectedId}) {
    const [state] = useContext(AppContext);

    const [filterName, setFilterName] = useState("");
    const [selectedChart, setSelectedChart] = useState(null);

    return (
        <Dialog maxWidth={"md"} fullWidth open={open} onClose={onClose}>
            <DialogTitle>
                Select a female body chart
            </DialogTitle>
            <DialogContent style={{paddingRight: 0, paddingLeft: 0}}>
                <Grid container alignItems={"center"}>
                    <Grid item xs={12}
                          style={{paddingRight: 20, paddingLeft: 20}}>
                        <TextField
                            onChange={(e) => setFilterName(e.target.value)}
                            size={"small"}
                            label="Search by name"
                            variant={"outlined"}
                            fullWidth
                            InputProps={{
                                startAdornment: (<InputAdornment position="start"><SearchIcon/></InputAdornment>),
                            }}
                        />
                    </Grid>
                    <Grid item xs={9} style={{marginTop: 20}}>
                        <AdvancedTable
                            tableProps={{size: "small"}}
                            jwt={state.user.jwt.token}
                            fields={tableColumns}
                            filterName={filterName}
                            highlightedIds={selectedChart? [selectedChart.id] : [selectedId]}
                            fetchingPageFunction={fetchGlobalBodyChartsPage}
                            requestArgs={sex}

                            onSelected={(chart) => {
                                setSelectedChart(chart)
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} style={{marginTop: 20, textAlign: "center"}}>
                        <AuthImg
                            style={{width: "100%"}}
                            src={selectedChart? selectedChart.digitalThumbnailUrl : undefined}/>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={onClose}
                    color="primary">
                    Close
                </Button>
                <Button
                    onClick={() => onSelected(selectedChart)}
                    variant={"outlined"}
                    color="primary">
                    Confirm selection
                </Button>
            </DialogActions>
        </Dialog>
    )
}
