import React from 'react';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";


export default function DescriptorStep1() {
    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="body2" gutterBottom>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis vel sem id eros malesuada molestie. Nunc vel
                    sapien mauris. Donec egestas enim eu turpis auctor euismod. Nunc blandit cursus enim, eu viverra lectus
                    vestibulum pellentesque. Etiam lectus augue, consectetur tristique tellus ut, porttitor fermentum lectus.
                    Donec ac urna est. Proin vel iaculis velit. Duis ac hendrerit urna. Quisque aliquam id massa non consequat.
                    Vestibulum accumsan elit id euismod venenatis. Ut nec tortor commodo, tincidunt sapien at, volutpat tellus.
                    Curabitur luctus et justo quis commodo. Donec non enim metus. Donec eu arcu tortor. Mauris luctus risus eget
                    dictum pulvinar.
                </Typography>
            </Grid>
        </Grid>
    )
}