import React from 'react';
import {Route, Switch} from "react-router-dom";
import Charts from "./Charts";


export default function BodychartRouter() {

    return (
        <Switch>
            <Route path="/:projectId/chart" component={Charts}/>
        </Switch>
    );
}
