import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {useParams} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {AppContext} from "../../../App";
import {fetchBlob, fetchFeedbackDetail} from "../../../utils/apiCaller";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import TextEntry from "../../../components/entries/TextEntry";

function FeedbackDetailScreen() {

    const [state] = useContext(AppContext)
    const jwt = state.user.jwt

    const [feedback, setFeedback] = useState({user: {}});
    const [edit, setEdit] = useState(false);

    const {feedbackId} = useParams();

    // stato che contiene lo screenshow
    const [image, setImage] = useState("")

    useEffect(() => {
        // carico i dettagli dell'utente
        fetchFeedbackDetail(jwt.token, feedbackId)
            .then(result => setFeedback(result))
            .catch(result => alert(result))

    }, [jwt, feedbackId])

    // scarico l'immagine
    useEffect(() => {

        if (!feedback || !feedback.imageUrl)
            return

        fetchBlob(jwt.token, feedback.imageUrl)
            // metto l'immagine nello stato
            .then(result => setImage(result))
            .catch(e => alert(e))

    }, [jwt, feedback])

    return (
        <>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                style={{height: 68, paddingLeft: 16, paddingRight: 16, paddingTop: 5}}
                spacing={1}
            >
                <Grid item>
                    <Typography component="div">
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                             style={{
                                 verticalAlign: "middle",
                                 display: "table-cell"
                             }}>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link color="inherit" href={"/supervisor/feedback"}>
                                    Feedback list
                                </Link>
                                <Typography color="textPrimary">Detail</Typography>
                            </Breadcrumbs>
                        </Box>
                    </Typography>
                </Grid>
                <Grid item>
                    {!edit ?
                        <Button variant="outlined" color="primary" style={{marginRight: 16}}
                                startIcon={<EditIcon/>} onClick={() =>
                            setEdit(prevState => !prevState)
                        }>
                            Edit mode
                        </Button> :
                        <Button variant="outlined" color="primary" style={{marginRight: 16}}
                                startIcon={<VisibilityIcon/>} onClick={() => setEdit(prevState => !prevState)}>
                            View mode
                        </Button>
                    }
                </Grid>
            </Grid>

            <Divider/>
            <div style={{padding: 16}}>
                <Grid container spacing={6}>
                    <Grid item lg={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography component="div">
                                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                                        Details
                                    </Box>
                                </Typography>
                            </Grid>
                            <TextEntry
                                editable={false}
                                label={"User"}
                                value={feedback.fullUserName}
                                divider={false}
                            />
                            <TextEntry
                                editable={false}
                                label={"Comments"}
                                value={feedback.comments}
                                divider={false}
                            />
                            <TextEntry
                                editable={false}
                                label={"Url"}
                                value={feedback.url}
                                divider={false}
                            />
                        </Grid>
                    </Grid>
                    <Grid item lg={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography component="div">
                                    <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                                        Screenshot
                                    </Box>
                                </Typography>
                            </Grid>
                            <Grid item>
                                {image?
                                    <a rel="noopener noreferrer" target={"_blank"} href={image}>
                                        <img style={{width: "100%", border: "1px solid #bdbdbd"}} alt={"screenshot"} src={image}/>
                                    </a>
                                    : "No screenshot"}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}



export default FeedbackDetailScreen;