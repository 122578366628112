import React, {useContext, useEffect, useState} from "react";
import {
    fetchProjectTeam,
    postUserSwitchRoleProject,
    putUserToProject,
    removeUserFromProject
} from "../../../utils/apiCaller";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Popover from "@material-ui/core/Popover";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

import List from "@material-ui/core/List";
import UserAddDialog from "../../../dialogs/UserAddDialog";
import {useHistory, useParams} from "react-router-dom";
import {AppContext} from "../../../App";
import TeamPermissions from "./TeamPermissions";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));


function UserList({list, administrators, onRemove, onSwitch}) {
    const [state] = useContext(AppContext);
    const authUser = state.user

    const [anchorEl, setAnchorEl] = React.useState(null);
    const uMenuOpen = Boolean(anchorEl);

    const handleMenu = (event, id) => {
        setAnchorEl({el: event.currentTarget, id: id});
    };


    return (
        <Grid container>
            <Grid item xs={12}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> </TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Surname</TableCell>
                                <TableCell>Email address</TableCell>
                                <TableCell>Supervisor</TableCell>
                                <TableCell> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {list.map((row) => (
                                <TableRow hover
                                          style={{cursor: "pointer"}}
                                          key={row.id}
                                >
                                    <TableCell>
                                        <Avatar style={{width: 35, height: 35}}>
                                            {row.name.substr(0, 1).toUpperCase()}
                                        </Avatar>
                                    </TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.surname}</TableCell>
                                    <TableCell>{row.email}</TableCell>
                                    <TableCell>{row.supervisor ? "Yes" : "No"}</TableCell>
                                    <TableCell style={{padding: 0}}>
                                        <IconButton
                                            disabled={authUser.id === row.id} onClick={(e) => handleMenu(e, row.id)}>
                                            <MoreVertIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Popover
                    open={uMenuOpen}
                    anchorEl={anchorEl ? anchorEl.el : null}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <List style={{marginTop: "auto", cursor: "pointer"}}>
                        <ListItem button onClick={() => {
                            setAnchorEl(null);
                            onRemove(anchorEl.id)
                        }}>
                            <ListItemIcon>
                                <RemoveIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Remove access"/>
                        </ListItem>
                        {administrators ?
                            <ListItem button onClick={() => {
                                setAnchorEl(null);
                                onSwitch(anchorEl.id)
                            }}>
                                <ListItemIcon>
                                    <ArrowDownwardIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Demote to operator"/>
                            </ListItem>
                            :
                            <ListItem button onClick={() => {
                                setAnchorEl(null);
                                onSwitch(anchorEl.id)
                            }}>
                                <ListItemIcon>
                                    <ArrowUpwardIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Promote to administrator"/>
                            </ListItem>
                        }
                    </List>
                </Popover>

            </Grid>
        </Grid>
    )
}

function TeamScreen() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext);
    // hook hystory per poter redirectare l'utente
    const history = useHistory();
    const jwt = state.user.jwt

    // carico il progetto attualmente selezionato
    const {projectId, page} = useParams()

    const [dialogOpen, setDialogOpen] = useState(false);
    // stato che contiene l'oggetto con tutti i descriptors
    const [team, setTeam] = useState({
        leaders: [],
        operators: []
    });

    let tabIndex
    if (page === "leaders" || !page)
        tabIndex = 0
    else if (page === "operators")
        tabIndex = 1
    else {
        tabIndex = 2
    }

    useEffect(() => {
        dispatch({type: "START_LOADING", payload: {key: "prjTeam"}})
        fetchProjectTeam(jwt.token, projectId)
            .then(result => {
                dispatch({type: "LOADED", payload: {key: "prjTeam"}})
                setTeam(result)
            })
            .catch(result => alert(result))

    }, [projectId, jwt, dispatch])

    const toggleAddUserDialog = () => {
        setDialogOpen(true)
    }

    const handleRemoveAccess = (id) => {
        if (window.confirm("Sure?"))
            removeUserFromProject(jwt.token, projectId, id)
                .then(() => {
                    fetchProjectTeam(jwt.token, projectId)
                        .then(result => setTeam(result))
                        .catch(result => alert(result))
                })
                .catch(result => alert(result))
    }

    const handleAddUser = (email) => {
        // aggiungo l'utente nel grupo giusto, e in caso che ha funzionato aggiorno la lista
        putUserToProject(jwt.token, projectId, email, !tabIndex)
            .then(() => {
                fetchProjectTeam(jwt.token, projectId)
                    .then(result => setTeam(result))
                    .catch(result => alert(result))
                setDialogOpen(false)
            })
            .catch(result => alert(result))
    }

    const handleUserRoleSwitch = (id) => {
        // inverto il ruolo dell'utente, da amministratore a operatore o viceversa
        if (window.confirm("Sure?"))
            postUserSwitchRoleProject(jwt.token, projectId, id)
                .then(() => {
                    fetchProjectTeam(jwt.token, projectId)
                        .then(result => setTeam(result))
                        .catch(result => alert(result))
                    setDialogOpen(false)
                })
                .catch(result => alert(result))
    }

    const handleTabChange = (e, index) => {
        switch (index) {
            case 0:
                history.push("/" + projectId + "/team/leaders")
                break
            case 1:
                history.push("/" + projectId + "/team/operators")
                break
            default:
            case 2:
                history.push("/" + projectId + "/team/permission")
                break
        }
    }

    // in base a tabIndex scelgo il contenuto della pagina
    let content;
    switch (tabIndex) {
        case 0:
            content = <UserList
                onRemove={handleRemoveAccess}
                onSwitch={handleUserRoleSwitch}
                list={team.leaders}
                administrators/>
            break
        case 1:
            content = <UserList
                onRemove={handleRemoveAccess}
                list={team.operators}
                onSwitch={handleUserRoleSwitch}/>
            break
        default:
        case 2:
            content = <TeamPermissions projectId={projectId}/>
            break

    }


    return (
        <Grid container>
            <Grid item xs={6} className={classes.banner} style={{height: 60}}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    style={{height: "100%", paddingTop: 10}}
                >
                    <Tab label="Leaders"/>
                    <Tab label="Operators"/>
                    <Tab label="Permissions"/>
                </Tabs>
            </Grid>
            <Grid item xs={6} className={classes.banner} style={{textAlign: "right", paddingTop: 9}}>
                {(tabIndex === 1 || tabIndex === 0) && <IconButton onClick={toggleAddUserDialog} color={"secondary"}>
                    <AddIcon/>
                </IconButton>}
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                <UserAddDialog open={dialogOpen} onSave={handleAddUser} onClose={() => setDialogOpen(p => !p)}/>
                {content}
            </Grid>
        </Grid>
    )
}

export default TeamScreen;