import React, {useContext, useEffect, useState} from "react";

import {AppContext} from "../../../App";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import {useParams} from "react-router-dom";
import {Alert, Autocomplete} from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import {
    fetchAllServices,
    fetchProjectAdditionalInfoList,
    fetchProjectDescriptors,
    fetchServiceDetail,
    fetchServiceRequestFull,
    postNewAnalysisRequest
} from "../../../utils/apiCaller";
import {FormControl, InputLabel, MenuItem, Select, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import InputAdornment from "@material-ui/core/InputAdornment";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Tooltip from "@material-ui/core/Tooltip";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useQueryParams} from "../../../hooks/useQueryParams";

const useStyles = makeStyles((theme) => ({
    banner: {
        backgroundColor: theme.palette.background.paper,
    },
}));


const fieldsFilters = [
    {entity: "Patient", field: "birth", type: "date", attribute: "patient.birth"},
    {
        entity: "Patient",
        field: "gender",
        type: "select",
        entries: ["Male", "Female", "ND"],
        attribute: "patient.gender"
    },
    {entity: "Patient", field: "height", type: "number", attribute: "patient.height"},
    {entity: "Patient", field: "weight", type: "number", attribute: "patient.weight"}
]

export const describeOperator = (key) => {
    switch (key) {
        case "EQUAL":
            return "is equal to"
        case "DIFFERENT":
            return "is different from"
        case "GREATER":
            return "is greater than"
        case "GREATER_EQUAL":
            return "is greater or equals to"
        case "LOWER":
            return "is lower than"
        case "LOWER_EQUAL":
            return "is lower or equal than"
        default:
            return ""
    }
}

export default function ServiceScreen() {
    const classes = useStyles();
    const [state, dispatch] = useContext(AppContext);

    const queryParams = useQueryParams();
    const modelId = queryParams.qp["model"] || undefined

    const [allServices, setAllServices] = useState();
    const [filters, setFilters] = useState([]);
    const [selectedService, setSelectedService] = useState(null);
    const [selectedServiceDetails, setSelectedServiceDetails] = useState();
    const [params, setParams] = useState({});

    const [descriptors, setDescriptors] = useState({});
    const [patientAdditional, setPatientAdditional] = useState([]);

    const {projectId} = useParams()


    useEffect(() => {
        // if there is a modelId, we can load the service, filters and parameters from such request
        if (modelId && allServices) {
            fetchServiceRequestFull(state.user.jwt.token, modelId)
                .then(result => {
                    // set the service
                    const service = allServices.find(e => e.id === result.serviceId)

                    if (!service)
                        return

                    setSelectedService(service)

                    // set the filters
                    setFilters(result.filters)

                    // set the parameters
                    setParams(result.parameters)

                })
                .catch(error =>
                    dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
                )
        }
    }, [modelId, allServices, dispatch, state.user.jwt.token])

    useEffect(() => {
        fetchAllServices(state.user.jwt.token)
            .then(setAllServices)
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

        fetchProjectDescriptors(state.user.jwt.token, projectId)
            .then(result => {
                setDescriptors(result)
            })
            .catch(error => alert(error.message))

        fetchProjectAdditionalInfoList(state.user.jwt.token, projectId)
            .then(result => setPatientAdditional(result.filter(a => !a.disabled)))
            .catch((error) => {
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            })

    }, [projectId, state.user.jwt, dispatch])

    useEffect(() => {
        if (selectedService)
            fetchServiceDetail(state.user.jwt.token, selectedService.id)
                .then(setSelectedServiceDetails)
                .catch(error =>
                    dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
                )
        else
            setSelectedServiceDetails(undefined)

    }, [selectedService, state.user.jwt, dispatch])


    const removeCondition = (filter) => {
        let tFilters = filters.filter(f => f !== filter)
        setFilters(tFilters)
    }

    const executeAnalysis = () => {
        const payload = {
            projectId: projectId,
            serviceId: selectedService.id,
            filters: filters,
            disabled: false,
            parameters: params
        }
        postNewAnalysisRequest(state.user.jwt.token, payload)
            .then(() =>
                dispatch({
                    type: "SHOW_ALERT",
                    payload: {message: "Request submitted, see request jobs to see the status"}
                })
            )
            .catch(error =>
                dispatch({type: "SHOW_ALERT", payload: {message: error.message, severity: "error"}})
            )

    }

    return (
        <Grid container>
            <Grid item xs={12} className={classes.banner}>
                <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}
                     style={{
                         height: 60,
                         verticalAlign: "middle",
                         display: "table-cell",
                         paddingLeft: 16
                     }}>
                    Perform service analysis
                </Box>
            </Grid>
            <Grid item xs={12}><Divider/></Grid>
            <Grid item xs={12}>
                <Grid container alignItems={"center"} spacing={2} style={{padding: 16}}>
                    <Grid item xs={12}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Service
                        </Box>
                        <Divider/>
                    </Grid>
                    <Grid item xs={2}>
                        <Autocomplete
                            options={allServices || []}
                            getOptionLabel={(option) => option.name}
                            onChange={((event, value) => {
                                setSelectedService(value)
                                setParams({})
                            })
                            }
                            value={selectedService}
                            renderInput={params => <TextField placeholder={"Select a service"} variant={"outlined"}
                                                              fullWidth{...params}/>}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        {selectedService && selectedService.description}
                    </Grid>
                    <Grid item xs={12}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Conditions
                        </Box>
                        <Divider/>
                    </Grid>
                    {selectedServiceDetails && selectedServiceDetails.params.conditionsSupported &&
                        <Grid item xs={12}>
                            <Conditions
                                patientAdditional={patientAdditional}
                                projectDescriptors={descriptors}
                                projectId={projectId}
                                onNewCondition={(c) => {
                                    setFilters([...filters, c])
                                }}/>
                        </Grid>}
                    {selectedServiceDetails && !selectedServiceDetails.params.conditionsSupported &&
                        <Grid item xs={12}>
                            <Alert severity="info">The selected analysis service does not support conditions</Alert>
                        </Grid>
                    }

                    {filters.map(filter =>
                        <Grid item key={filter.field + filter.operator}>
                            <Paper>
                                <Box p={1}>
                                    <Typography variant={"button"}>
                                        {filter.fieldName && filter.fieldName.toLowerCase() + " "}
                                        {!filter.fieldName && "Unnamed field "}
                                        <u>{describeOperator(filter.operator)}</u>
                                        {" " + (filter.stringValue || filter.numericValue)}
                                    </Typography>
                                    <IconButton style={{marginLeft: 5}} color="secondary"
                                                onClick={() => removeCondition(filter)} component="span">
                                        <DeleteIcon/>
                                    </IconButton>
                                </Box>
                            </Paper>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Box fontWeight="fontWeightLight" fontSize={"h5.fontSize"}>
                            Parameters
                        </Box>
                        <Divider/>
                    </Grid>
                    {selectedServiceDetails && selectedServiceDetails.params.params &&
                        <Grid item xs={12}>
                            <Parameters
                                params={selectedServiceDetails.params.params}
                                values={params}
                                onChange={setParams}/>
                        </Grid>
                    }
                    {selectedServiceDetails && !selectedServiceDetails.params.params &&
                        <Grid item xs={12}>
                            <Alert severity="info">The selected analysis service does not have any parameters</Alert>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Button variant={"outlined"} onClick={executeAnalysis}>
                            Execute
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}


function Conditions({onNewCondition, patientAdditional, projectDescriptors, projectId}) {

    const [conditionFieldEntity, setConditionFieldEntity] = useState("");
    const [conditionFiled, setConditionField] = useState("");
    const [conditionOperator, setConditionOperator] = useState("");
    const [conditionValue, setConditionValue] = useState("");

    const [fieldsFiltersExtended, setFieldsFiltersExtended] = useState([]);

    useEffect(() => {
        let fields = [...fieldsFilters, ...patientAdditional.map(a => {
            return {
                entity: "Patient",
                field: a.name,
                entries: ['true', 'false'],
                attribute: `additionalInfoPerProject.${projectId}.${a.id}`,
                type: a.type === "Number" ? "number" : a.type === "Boolean" ? "select" : "text"
            }
        })]

        fields = [...fields, ...projectDescriptors.acquisitionDescriptors.filter(a => a.kind === "OptionBasedDescriptor" || a.kind === "RangeBasedDescriptor").map(a => {
            return {
                entity: "Acquisition",
                field: a.name,
                entries: a.options,
                attribute: `painValues.${a.id}.value`,
                type: a.kind === "OptionBasedDescriptor" ? "select" : "number"
            }
        })]

        fields = [...fields, ...projectDescriptors.drawingDescriptors.filter(a => a.kind === "OptionBasedDescriptor" || a.kind === "RangeBasedDescriptor").map(a => {
            return {
                entity: "Drawing",
                field: a.name,
                entries: a.options,
                attribute: `painDrawings.painValues.${a.id}.value`,
                type: a.kind === "OptionBasedDescriptor" ? "select" : "number"
            }
        })]


        fields = [...fields, ...projectDescriptors.spotDescriptors.filter(a => a.kind === "OptionBasedDescriptor" || a.kind === "RangeBasedDescriptor").map(a => {
            return {
                entity: "Spot",
                field: a.name,
                entries: a.options,
                attribute: `painDrawings.painSpots.painValues.${a.id}.value`,
                type: a.kind === "OptionBasedDescriptor" ? "select" : "number"
            }
        })]

        setFieldsFiltersExtended(fields)
    }, [patientAdditional, projectDescriptors, projectId])

    const showCorrectInputType = (entity) => {
        if (entity)
            switch (entity.type) {
                case 'date':
                    return (
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                                disableFuture
                                inputVariant="outlined"
                                label={"Value"}
                                ampm={false}
                                value={conditionValue ? new Date(conditionValue * 1000) : null}
                                onChange={(e) => {
                                    setConditionValue(parseInt(e.getTime() / 1000))
                                }}
                                fullWidth format="dd/MM/yyyy"
                            />
                        </MuiPickersUtilsProvider>
                    )
                case 'select':
                    return (
                        <FormControl fullWidth variant={"outlined"}>
                            <InputLabel>Value</InputLabel>
                            <Select
                                label={"Value"}
                                value={conditionValue}
                                onChange={(e) => setConditionValue(e.target.value)}>
                                {entity.entries.map(entry => <MenuItem key={entry} value={entry}>{entry}</MenuItem>)}
                            </Select>
                        </FormControl>)
                case 'number':
                case 'text':
                default:
                    return <TextField label={"Value"} type={entity.type === "number" ? "number" : "default"} fullWidth
                                      variant={"outlined"}
                                      value={conditionValue} onChange={e => setConditionValue(e.target.value)}/>
            }
        else
            return <TextField key={"disa"} variant={"outlined"} label={"Value"} disabled fullWidth/>
    }

    const handleConditionAdd = () => {
        let condition = {
            tmp: new Date().getTime(), // identificativo temporaneo usato solo per avere una referenza
            fieldName: conditionFiled.entity + "." + conditionFiled.field,
            field: conditionFiled.attribute,
            operator: conditionOperator,
        }

        //fixed: use Number instead of parseInt
        if (!isNaN(Number(`${conditionValue}`)))
            condition.numericValue = conditionValue
        else
            condition.stringValue = conditionValue

        setConditionFieldEntity("")
        setConditionField("")
        setConditionOperator("")
        setConditionValue("")

        if (!conditionValue)
            return

        onNewCondition(condition)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={2}>
                <FormControl fullWidth variant={"outlined"}>
                    <InputLabel>Entity</InputLabel>
                    <Select
                        fullWidth
                        label={"Entity"}
                        value={conditionFieldEntity}
                        onChange={(e) => setConditionFieldEntity(e.target.value)}
                    >
                        {fieldsFiltersExtended.map(entity => entity.entity)
                            .filter((v, i, a) => a.indexOf(v) === i)
                            .map(entity =>
                                <MenuItem key={entity} value={entity}>{entity}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth variant={"outlined"}>
                    <InputLabel>Field</InputLabel>
                    <Select
                        fullWidth
                        label={"Field"}
                        value={conditionFiled}
                        onChange={(e) => setConditionField(e.target.value)}
                    >
                        {fieldsFiltersExtended.filter(e => e.entity === conditionFieldEntity)
                            .map(entity =>
                                <MenuItem key={entity.field} value={entity}>{entity.field}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth variant={"outlined"}>
                    <InputLabel>Operator</InputLabel>
                    <Select
                        fullWidth
                        label={"Operator"}
                        value={conditionOperator}
                        onChange={(e) => setConditionOperator(e.target.value)}>
                        <MenuItem value={"EQUAL"}
                                  disabled={!conditionFiled || conditionFiled.type === "date"}>
                            {describeOperator("EQUAL")}
                        </MenuItem>
                        <MenuItem value={"DIFFERENT"}
                                  disabled={!conditionFiled || conditionFiled.type === "date"}>
                            {describeOperator("DIFFERENT")}
                        </MenuItem>
                        <MenuItem value={"GREATER"}
                                  disabled={!conditionFiled || conditionFiled.type === "select" || conditionFiled.type === "text"}>
                            {describeOperator("GREATER")}
                        </MenuItem>
                        <MenuItem value={"GREATER_EQUAL"}
                                  disabled={!conditionFiled || conditionFiled.type === "select" || conditionFiled.type === "text"}>
                            {describeOperator("GREATER_EQUAL")}
                        </MenuItem>
                        <MenuItem value={"LOWER"}
                                  disabled={!conditionFiled || conditionFiled.type === "select" || conditionFiled.type === "text"}>
                            {describeOperator("LOWER")}
                        </MenuItem>
                        <MenuItem value={"LOWER_EQUAL"}
                                  disabled={!conditionFiled || conditionFiled.type === "select" || conditionFiled.type === "text"}>
                            {describeOperator("LOWER_EQUAL")}
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                {showCorrectInputType(conditionFiled)}
            </Grid>
            <Grid item xs={1}/>
            <Grid item xs={2}>
                <Button onClick={handleConditionAdd} variant={"outlined"} color={"secondary"}
                        style={{height: "100%"}} fullWidth>
                    Add condition
                </Button>
            </Grid>
        </Grid>
    )
}

function Parameters({params, onChange, values}) {

    // const [values, setValues] = useState({});

    const handleParamValueChange = (key, value) => {
        let tmp = {...values}
        tmp[key] = value
        onChange(tmp)
        // setValues(tmp)
    }


    return (<Grid container spacing={2}>
        {params.map(p =>
            <Grid key={p.key} item xs={3}>
                {p.options ?
                    <FormControl
                        fullWidth variant={"outlined"}>
                        <InputLabel>{p.name || p.key}</InputLabel>
                        <Select
                            onChange={(e) => handleParamValueChange(p.key, e.target.value)}
                            value={values[p.key] || p.default}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Tooltip title={p.description} style={{cursor: "help"}}>
                                        <HelpOutlineIcon/>
                                    </Tooltip>
                                </InputAdornment>
                            }
                            placeholder={p.key}
                            label={p.name || p.key}>
                            {Object.keys(p.options).map(k =>
                                <MenuItem key={k} value={k}>{p.options[k]}</MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    :
                    <TextField
                        value={values[p.key] || p.default || ""}
                        type={p.numeric ? "number" : "text"}
                        onChange={(e) => handleParamValueChange(p.key, e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Tooltip title={p.description} style={{cursor: "help"}}>
                                        <HelpOutlineIcon/>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth variant={"outlined"} label={p.name || p.key}/>}
            </Grid>
        )}
    </Grid>)
}